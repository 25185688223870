import { useNavigate, useParams } from 'react-router';
import { TRPCErrorShape } from '@trpc/server/rpc';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { AddEditBuildingFormikValues } from './AddEditBuildingFormConfig';
import { RouteParams } from '@/types';
import { UpdateBuildingInput } from '@/types/trpc/building';
import { UseToggle } from '@/shared/hooks/UseToggle';

import { queryIds } from '@/shared/utils/constants';
import Routes from '@/containers/App/Router/Routes';
import { trpc } from '@/config/trpc';

const valuesToRequest = (values: AddEditBuildingFormikValues) => {
  const {
    id,
    administrators,
    buildingTags,
    addressLine,
    city,
    country,
    postalCode,
    name,
    ...rest
  } = values;

  return {
    address: {
      addressLine,
      city,
      country: country as UpdateBuildingInput['address']['country'],
      postalCode,
    },
    administrators: administrators.map(({ value }) => value),
    buildingTags: buildingTags.map(({ value }) => value),
    id: id || '',
    name: name || '',
    ...rest,
  };
};

type ActionKey = 'typeSuccessfullyAdded' | 'typeSuccessfullyUpdated' | 'typeSuccessfullyRemoved';

type Params<T> = {
  toggle: UseToggle<T>;
};

const useAddEditBuilding = <T>(params: Params<T>) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { buildingId } = useParams() as RouteParams;

  const utils = trpc.useUtils();

  const { mutateAsync: createBuildingAsync, isLoading: isCreatingBuilding } =
    trpc.building.create.useMutation();
  const { mutateAsync: updateBuildingAsync, isLoading: isUpdatingBuilding } =
    trpc.building.update.useMutation();
  const { mutateAsync: deleteBuildingAsync, isLoading: isDeletingBuilding } =
    trpc.building.delete.useMutation();

  const navigate = useNavigate();
  // * can be changed to trpc utils.invalidate once they're all integrated
  const queryClient = useQueryClient();

  const handleError = (error: unknown) => {
    const { message } = error as TRPCErrorShape;

    enqueueSnackbar(message, { variant: 'error' });
  };

  const onSuccess = (actionKey: ActionKey, id?: string) => {
    enqueueSnackbar(t(actionKey, { type: t('building_one') }));
    queryClient.invalidateQueries({ queryKey: [queryIds.buildings.GET_BUILDINGS] });
    utils.building.list.invalidate();

    if (actionKey === 'typeSuccessfullyRemoved') {
      navigate(Routes.BUILDINGS_OVERVIEW);
    } else {
      params.toggle.hide();
    }

    if (id) {
      queryClient.invalidateQueries({ queryKey: [queryIds.buildings.GET_BUILDING, id] });
      utils.building.byId.invalidate(id);
      utils.building.summaryById.invalidate(id);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteBuildingAsync({ id: buildingId });

      onSuccess('typeSuccessfullyRemoved');
    } catch (error) {
      handleError(error);
    }
  };

  const handleSubmit = async (values: AddEditBuildingFormikValues) => {
    try {
      const request = valuesToRequest(values);

      let actionKey: ActionKey;

      if (request.id) {
        await updateBuildingAsync(request);

        actionKey = 'typeSuccessfullyUpdated';
      } else {
        await createBuildingAsync(request);

        actionKey = 'typeSuccessfullyAdded';
      }

      onSuccess(actionKey, request.id);
    } catch (error) {
      handleError(error);
    }
  };

  return {
    handleDelete,
    handleSubmit,
    isMutating: isCreatingBuilding || isUpdatingBuilding || isDeletingBuilding,
  };
};

export default useAddEditBuilding;
