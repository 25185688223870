/* eslint-disable no-bitwise */
enum BoardMemberRoles {
  MemberOfRME = 1 << 0,
  Accountant = 1 << 1,
  President = 1 << 2,
  ViceChairman = 1 << 3,
  ContactPerson = 1 << 4,
}

export default BoardMemberRoles;
