import { useCallback, useMemo } from 'react';

import {
  AutocompleteOption,
  AutocompleteOptionsArgs,
  EnumOptions,
  UseAutocompleteOptionsResult,
} from './autocomplete.types';

import { generateOptionsFromEnum } from './autocomplete.utils';
import useAutocompleteOptions from './useAutocompleteOptions';

type Options<TValue extends string | number> = EnumOptions<TValue> &
  AutocompleteOptionsArgs<TValue>;

function useAutocompleteEnum<TValue extends string | number>(
  options: Options<TValue> & { disableClearable?: boolean; multiple: true },
): UseAutocompleteOptionsResult<TValue, true>;

function useAutocompleteEnum<TValue extends string | number>(
  options: Options<TValue> & { disableClearable: true; multiple?: false },
): UseAutocompleteOptionsResult<TValue, false | undefined>;

function useAutocompleteEnum<TValue extends string | number>(
  options: Options<TValue> & { disableClearable?: false; multiple?: false },
): UseAutocompleteOptionsResult<TValue, false | undefined>;

function useAutocompleteEnum<TValue extends string | number>({
  callback,
  disableClearable,
  includeValue,
  multiple,
  selected,
  srcEnum,
  translationKey,
  ...args
}: Options<TValue>): UseAutocompleteOptionsResult<TValue, typeof multiple> {
  const options = useMemo(
    () => generateOptionsFromEnum({ includeValue, srcEnum, translationKey }),
    [includeValue, srcEnum, translationKey],
  );

  const handleCallback = useCallback(
    (newValue: AutocompleteOption<TValue>[] | AutocompleteOption<TValue> | null) => {
      const multipleOptions = Array.isArray(newValue);

      if (multiple && multipleOptions) {
        callback(newValue);
      } else if (!multiple && !multipleOptions) {
        if (disableClearable && newValue) {
          callback(newValue);
        } else if (!disableClearable) {
          callback(newValue);
        }
      }
    },
    [callback, disableClearable, multiple],
  );

  const autocompleteProps = useAutocompleteOptions({
    callback: handleCallback,
    data: options,
    disableClearable,
    multiple,
    selected,
    ...args,
  });

  return autocompleteProps;
}

export default useAutocompleteEnum;
