import { useTranslation } from 'react-i18next';

import FormikContractorSkillDefinitionSelector from '@/shared/formik/FormikContractorSkillDefinitionSelector';
import HeaderStep from './HeaderStep';

const SkillsStep = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderStep
        title={t('repairCaseForm.skills.title')}
        description={t('repairCaseForm.skills.description')}
        tooltipMessage={t('repairCaseForm.skills.tooltip')}
      />

      <FormikContractorSkillDefinitionSelector name="skill" label={t('skill')} required />
    </>
  );
};

export default SkillsStep;
