import { Autocomplete } from '@mui/joy';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContentType,
  FileManagerFile,
  isFileManagerFile,
  isUploadedFile,
  File as UploadedFile,
} from '@/types/documents';
import { Attachment } from '@/shared/components/2.0/messageTemplates/types';

import downloadFile from '@/shared/utils/fileHelpers';
import { formatBytes } from '../../../shared/utils/helpers';

import Attachments from '@/shared/components/2.0/messageTemplates/attachments/Attachments';

export type CaseAttachment = {
  contentType: string;
  fileName: string;
  id: string;
  size?: number;
  uri?: string;
  url?: string;
  uploadDate: string;
};

type AttachmentValue = {
  file: CaseAttachment | UploadedFile | FileManagerFile | File;
  id: string;
  isEntityFile: boolean;
  isForwardAttachment: boolean;
};

type Props = {
  caseFiles: Attachment[];
  name: string;
};

const TAGS_LIMIT = 2;

const AttachmentsUploadView: React.FC<Props> = ({ name, caseFiles }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<Record<string, AttachmentValue[]>>();

  const caseFileOptions = useMemo(
    () =>
      caseFiles.map(({ id, fileName, fileSize }) => ({
        label: fileName ? `${fileName} (${formatBytes(fileSize)})` : '-',
        value: id,
      })),
    [caseFiles],
  );

  const selectedCaseOptions = useMemo(
    () =>
      values[name]
        .filter((attachment) => !(attachment.file instanceof File))
        .map((attachment) => ({
          label: (attachment.file as CaseAttachment).fileName,
          value: attachment.id,
        })) as { label: string; value: string }[],
    [values, name],
  );

  const attachments = useMemo(
    () =>
      values[name].map(({ file }) => {
        if (file instanceof File) {
          return file;
        }
        if (isUploadedFile(file)) {
          return {
            ...file,
            fileName: file.fileName || file.id,
          };
        }
        if (isFileManagerFile(file)) {
          return file;
        }

        const { size, url, uri, ...rest } = file;

        return {
          fileSize: size,
          fileUri: uri || url,
          ...rest,
        };
      }),
    [values, name],
  );
  return (
    <div className="my-4 flex flex-col gap-3">
      {caseFiles.length > 0 && (
        <div>
          <Autocomplete
            placeholder={t('addDocumentsFromCase')}
            multiple
            value={selectedCaseOptions}
            options={caseFileOptions}
            onChange={(_, selectedOptions) => {
              const selectedIds = selectedOptions.map((option) => option.value);

              setFieldValue(name, [
                ...values[name].filter((file) => !file.isEntityFile),
                ...caseFiles
                  .filter((caseFile) => selectedIds.includes(caseFile.id))
                  .map((caseFile) => ({
                    file: {
                      contentType: caseFile.contentType,
                      fileName: caseFile.fileName,
                      fileUri: caseFile.fileUri,
                      id: caseFile.id,
                      size: caseFile.fileSize,
                    },
                    id: caseFile.id,
                    isEntityFile: true,
                    isForwardAttachment: false,
                  })),
              ]);
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            limitTags={TAGS_LIMIT}
            size="sm"
          />
        </div>
      )}
      <Attachments
        attachments={attachments}
        onRemove={(id) => {
          setFieldValue(
            name,
            values[name].filter(({ file }) => {
              if (file instanceof File) {
                return file.name !== id;
              }
              if (isFileManagerFile(file)) {
                return file.fileId !== id;
              }

              return file.id !== id;
            }),
          );
        }}
        readOnly
        allowRemoveOnExisting
        onDownload={(file) =>
          downloadFile({
            ...file,
            contentType: file.contentType || ContentType.TXT,
            fileUri: file.fileUri || '',
          })
        }
      />
    </div>
  );
};

export default AttachmentsUploadView;
