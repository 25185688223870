import { FormControl, FormLabel } from '@mui/joy';
import { FC } from 'react';
import { useFormikContext } from 'formik';

import FormHelperText from '../components/2.0/forms/FormHelperText';
import SelectContractorSkillDefinition from '../components/select/SelectContractorSkillDefinition';

type Props = {
  required?: boolean;
  name: string;
  label?: string;
};

const FormikContractorSkillDefinitionSelector: FC<Props> = ({
  required = false,
  name,
  label = null,
  ...rest
}) => {
  const { values, errors, touched, setTouched, setFieldValue } = useFormikContext<{
    [key: string]: string | { value: string };
  }>();

  return (
    <FormControl className="flex-1">
      {label && <FormLabel required={required}>{label}</FormLabel>}
      <SelectContractorSkillDefinition
        callback={(skill) => {
          setFieldValue(name, skill);
          setFieldValue('contractorSkillDefinitionId', skill?.value);
        }}
        selected={typeof values[name] === 'string' ? values[name] : values[name]?.value}
        onBlur={() => setTouched({ ...touched, [name]: true })}
        {...rest}
      />

      <FormHelperText error={errors[name]} touched={touched[name]} />
    </FormControl>
  );
};

export default FormikContractorSkillDefinitionSelector;
