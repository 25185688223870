import { Document, Page, pdfjs } from 'react-pdf';
import { useMemo, useState } from 'react';
import classNames from 'classnames';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.mjs`;

// for testing purposes: if you want to view the PDF in localhost
// you have to enable the CORS on the blob storage to allow * (didn't get it to work with localhost)
// or we have to implement a pdfjs worker ourselves which doesn't use CORS

type Props = {
  className?: string;
  uri: string;
  onError?: () => void;
  onLoad?: () => void;
};

// will be replaced with syncfusion viewer in the future, this one sometimes renders blurry
// not sure to the cause, since it's only used in one modal component anymore
const PDFRenderer: React.FC<Props> = ({ uri, onLoad, onError, className }) => {
  const [nrPages, setNrPages] = useState<number | null>(null);

  const file = useMemo(
    () => ({
      url: uri,
    }),
    [uri],
  );

  return (
    <div className={classNames('pdf-renderer', className)}>
      <Document
        file={file}
        onError={onError}
        onLoadSuccess={({ numPages }: { numPages: number }) => {
          setNrPages(numPages);
          onLoad?.();
        }}
      >
        {Array.from(new Array(nrPages), (_, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </div>
  );
};

export default PDFRenderer;
