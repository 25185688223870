import { Divider, Dropdown, IconButton, Menu, MenuButton, MenuItem } from '@mui/joy';
import { useContext, useMemo } from 'react';
import { lowerFirst } from 'lodash';
import { useTranslation } from 'react-i18next';

import { actions } from '@/reducers/ui';
import { AppContext } from '@/shared/context/context';
import useToggle from '../../../shared/hooks/UseToggle';
import { useUserRole } from '@/queries';

import {
  AddIcon,
  BuildingIcon,
  CaseIcon,
  CashRegisterIcon,
  CompanyIcon,
  ContactIcon,
  InsurancePolicyIcon,
  InsuredCaseIcon,
  RepairCaseIcon,
  TeamIcon,
} from '../../../shared/icons/Icons';
import AddCase from '@/containers/Cases/AddEditCase/AddCase';
import AddCompanyModal from '../../Companies/AddEditCompany/AddEditCompanyModal';
import AddEditBuildingModal from '../../Buildings/AddEditBuilding/AddEditBuildingModal';
import AddEditContactModal from '../../Contacts/AddEditContact/AddEditContactModal';
import AddEditPerformanceModal from '@/containers/HomeownerAssociation/Performances/AddEditPerformanceModal';
import AddInsuranceCaseModal from '../../Cases/AddInsuranceCase/AddInsuranceCaseModal';
import AddPolicyModal from '../../Policies/AddEditPolicy/AddPolicyModal';
import AddRepairCaseStepper from '@/containers/Cases/AddEditCase/repairCaseStepper/AddRepairCaseStepper';
import AddTeamModal from '@/containers/Teams/AddEditTeam/AddTeamModal';
import KpcTooltip from '@/shared/components/Tooltips/KpcTooltip';
import { trpc } from '@/config/trpc';

const TopbarQuickAction = () => {
  const { ui, setUi, currentTeam } = useContext(AppContext);
  const { t } = useTranslation();
  const { isSupervisor, isAdmin } = useUserRole();

  const addBuildingToggle = useToggle();
  const addCaseToggle = useToggle();
  const addCompanyToggle = useToggle();
  const addContactToggle = useToggle();
  const addInsuranceToggle = useToggle();
  const addTeamToggle = useToggle();
  const createPolicyToggle = useToggle();
  const chargePerformanceToggle = useToggle();

  const { data: repairCompanies } = trpc.company.repairCompanies.useQuery();
  const requestRepairCasePossible = useMemo(
    () => !!repairCompanies?.filter((team) => team.repairInKindPossible).length,
    [repairCompanies],
  );
  const noRepairPossibleReason = useMemo(
    () =>
      repairCompanies
        ?.filter((team) => !!team.noRepairInKindReason)
        .map((team) => `${team.name}: ${lowerFirst(team.noRepairInKindReason)}`)
        .join('; '),
    [repairCompanies],
  );

  const currentTeamCannotCreateRepairCase =
    !currentTeam.allowRepairManagement && !requestRepairCasePossible;
  return (
    <>
      <Dropdown>
        <MenuButton
          className="rounded-full"
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain' } }}
        >
          <AddIcon />
        </MenuButton>

        <Menu placement="bottom-end">
          {currentTeam && currentTeam.allowCaseManagement && (
            <>
              <MenuItem className="px-4 py-2" onClick={addCaseToggle.show}>
                <i className="w-8">
                  <CaseIcon />
                </i>
                {t('_case.title')}
              </MenuItem>

              <KpcTooltip
                title={currentTeamCannotCreateRepairCase ? noRepairPossibleReason : ''}
                placement="right"
              >
                <MenuItem
                  className="px-4 py-2"
                  disabled={currentTeamCannotCreateRepairCase ?? false}
                  onClick={() => setUi({ type: actions.CASE_TOGGLE_CREATE_REPAIR_CASE })}
                >
                  <i className="w-8">
                    <RepairCaseIcon />
                  </i>
                  {t('repairCase')}
                </MenuItem>
              </KpcTooltip>
              <MenuItem className="px-4 py-2" onClick={addInsuranceToggle.show}>
                <i className="w-8">
                  <InsuredCaseIcon />
                </i>
                {t('insuranceCase')}
              </MenuItem>
            </>
          )}
          <Divider />
          <MenuItem className="px-4 py-2" onClick={addContactToggle.show}>
            <i className="w-8">
              <ContactIcon />
            </i>
            {t('_contact.title')}
          </MenuItem>
          <MenuItem className="px-4 py-2" onClick={addCompanyToggle.show}>
            <i className="w-8">
              <CompanyIcon />
            </i>
            {t('_company.title')}
          </MenuItem>
          <MenuItem className="px-4 py-2" onClick={addBuildingToggle.show}>
            <i className="w-8">
              <BuildingIcon />
            </i>
            {t('building_one')}
          </MenuItem>
          <MenuItem className="px-4 py-2" onClick={createPolicyToggle.show}>
            <i className="w-8">
              <InsurancePolicyIcon />
            </i>
            {t('policy')}
          </MenuItem>
          {currentTeam && currentTeam.allowSyndicManagement && (
            <>
              <Divider />
              <MenuItem className="px-4 py-2" onClick={chargePerformanceToggle.show}>
                <i className="w-8">
                  <CashRegisterIcon />
                </i>
                {t('chargeAPerformance')}
              </MenuItem>
            </>
          )}
          {isSupervisor && <Divider />}
          {isAdmin && (
            <MenuItem className="px-4 py-2" onClick={addTeamToggle.show}>
              <i className="w-8">
                <TeamIcon />
              </i>
              {t('addType', { type: t('team') })}
            </MenuItem>
          )}
        </Menu>
      </Dropdown>
      {addCaseToggle.value && <AddCase toggle={addCaseToggle} />}
      {ui.case.toggles.createRepairCase && <AddRepairCaseStepper />}
      <AddEditContactModal toggle={addContactToggle} />
      <AddCompanyModal toggle={addCompanyToggle} />
      <AddPolicyModal toggle={createPolicyToggle} />
      {addInsuranceToggle.value && <AddInsuranceCaseModal toggle={addInsuranceToggle} />}
      {addBuildingToggle.value && <AddEditBuildingModal toggle={addBuildingToggle} />}
      <AddEditPerformanceModal toggle={chargePerformanceToggle} />
      {isSupervisor && <AddTeamModal toggle={addTeamToggle} />}
    </>
  );
};

export default TopbarQuickAction;
