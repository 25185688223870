import * as yup from 'yup';
import { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, IconButton, Stack, Tooltip } from '@mui/joy';
import { Form, Formik } from 'formik';
import { FormGroup, Skeleton } from '@mui/material';

import { decapitalize, getCurrentTranslation, getToday } from '@/shared/utils/helpers';
import API from '@/shared/api/ApiService';
import { AppContext } from '@/shared/context/context';
import { BuildingRoleSelectorOption } from '@/shared/enums/BuildingRoleSelectorOption';
import { CaseContext } from '@/containers/Cases/CaseContext';
import CaseType from '@/shared/enums/CaseType';
import { trpc } from '@/config/trpc';
import useGetEnums from '@/queries/enums/useGetEnums';
import useGetSelectorCases from '@/queries/cases/useGetSelectorCases';
import useNotification from '@/shared/hooks/UseNotification';
import useToggle from '@/shared/hooks/UseToggle';

import { CancelIcon, EditIcon, SaveIcon, WarningIcon } from '@/shared/icons/Icons';
import ConfirmationModal from '@/shared/components/ConfirmationModal';
import DamageTypeSelector from '@/shared/formik/FormikDamageSelector';
import EditCaseBuildingPortalVisibility from './EditCaseBuildingPortalVisibility';
import FormikAutocomplete from '@/shared/formik/FormikAutocomplete';
import FormikCheckbox from '@/shared/components/2.0/formik/FormikCheckbox';
import FormikContractorSkillDefinitionSelector from '@/shared/formik/FormikContractorSkillDefinitionSelector';
import FormikDatePicker from '@/shared/components/2.0/formik/FormikDatePicker';
import FormikDateTimePicker from '@/shared/formik/FormikDateTimePicker';
import FormikEnumSelector from '@/shared/formik/FormikEnumSelector';
import FormikSelectContract from '@/shared/formik/FormikSelectContract';
import FormikTextField from '@/shared/formik/FormikTextField';
import FormikYesNo from '@/shared/formik/FormikYesNo';

const propTypes = {
  caseClassifications: PropTypes.arrayOf(PropTypes.shape({})),
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

const CaseNotificationCardEditForm = ({ onCancel, onSuccess, caseClassifications }) => {
  const [formData, setFormData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { currentTeam } = useContext(AppContext);
  const { currentCase } = useContext(CaseContext);
  const {
    t,
    i18n: { language },
  } = useTranslation(['common', 'errors']);
  const { sendDefaultError } = useNotification();

  const toggles = {
    confirmation: useToggle(),
    editAccessibility: useToggle(),
  };

  const { caseType } = currentCase;

  const {
    enums: { caseChannels, priorities },
  } = useGetEnums();
  const { selectorCases, selectorCasesIsLoading } = useGetSelectorCases({
    inactive: caseType !== CaseType.Complaint,
  });

  const translatedCaseChannels = useMemo(
    () =>
      caseChannels.map((cc) => ({
        name: cc.name,
        translation: t(decapitalize(cc.name)),
        value: cc.value,
      })),
    [caseChannels, t],
  );

  const caseOptions = useMemo(() => {
    if (selectorCasesIsLoading) {
      return [];
    }
    return selectorCases
      .filter((c) => c.id !== currentCase.id)
      .sort((a, b) => a.reference.localeCompare(b.reference));
  }, [selectorCasesIsLoading, selectorCases, currentCase.id]);

  const onSubmit = async (data) => {
    if (isSubmitting) {
      return;
    }

    if (currentCase.contractId !== data.contractId && !formData) {
      setFormData(data);
      toggles.confirmation.show();
      return;
    }

    setIsSubmitting(true);
    const response = await API.putUpdateCase({
      ...data,
      caseId: currentCase.id,
      contractorSkillDefinitionId: data.skill?.value,
      reference: currentCase.currentTeamReference,
      rolesWithAccess: data.rolesWithAccess?.includes(
        BuildingRoleSelectorOption.AllContactsAndCompanies,
      )
        ? []
        : data.rolesWithAccess,
    });
    setIsSubmitting(false);

    setFormData(null);

    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    onSuccess();
  };

  const handleConfirmContractChange = async () => {
    await onSubmit(formData);
    toggles.confirmation.hide();
  };

  const handleCancelContractChange = () => {
    setFormData(null);
    toggles.confirmation.hide();
  };

  const { data: doesCaseHaveSalesInvoice, isLoading: isDoesCaseHaveSalesInvoiceLoading } =
    trpc.case.repair.isSalesInvoiceCreatedFromCase.useQuery(currentCase.id);

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          channel: currentCase.channel,
          classification: currentCase.classification?.id,
          consentOnCancellationFee: currentCase.consentOnCancellationFee ?? false,
          contractId: currentCase.contractId,
          damageToCommonUnits: currentCase.damageToCommonUnits ?? false,
          damageToPrivateUnits: currentCase.damageToPrivateUnits ?? false,
          damageType: currentCase.damageType,
          description: currentCase.description,
          incidentDate: currentCase.incidentDate,
          isJustified: currentCase.isJustified ?? false,
          linkedCaseId: currentCase.linkedComplaintCaseId,
          notificationDate: currentCase.notificationDate,
          policeReportNumber: currentCase.policeReportNumber,
          priority: currentCase.priority,
          remark: currentCase.remark,
          rolesWithAccess: currentCase.rolesWithAccess.includes(BuildingRoleSelectorOption.None)
            ? [BuildingRoleSelectorOption.AllContactsAndCompanies]
            : currentCase.rolesWithAccess,
          showOnBuildingPortal: currentCase.showOnBuildingPortal ?? false,
          skill: currentCase.contractorSkillDefinition?.id,
          thirdPartyInvolved: currentCase.thirdPartyInvolved ?? false,
          verbalProcess: currentCase.verbalProcess ?? false,
        }}
        validationSchema={yup.object().shape({
          channel: yup.string().nullable(),
          classification: yup.string().nullable(),
          consentOnCancellationFee: yup.bool().nullable(),
          contractId: yup
            .string()
            .nullable()
            .test('contractRequired', t('errors:fieldIsRequired'), (value) => {
              if (caseType !== CaseType.Repair) {
                return true;
              }

              return value != null;
            }),
          damageToCommonUnits: yup.bool().nullable(),
          damageToPrivateUnits: yup.bool().nullable(),
          damageType: yup
            .string()
            .nullable()
            .test(
              'damageTypeRequired',
              t('errors:fieldIsRequired'),
              (value) =>
                caseType === CaseType.Case ||
                caseType === CaseType.Complaint ||
                (value != null && value !== ''),
            ),
          description: yup
            .string()
            .max(250, t('errors:fieldMaxLengthIsX', { max: 250 }))
            .nullable()
            .required(t('errors:fieldIsRequired')),
          incidentDate: yup
            .date()
            .nullable()
            .max(getToday().endOf('day'), t('errors:dateCannotBeInTheFuture'))
            .test(
              'incidentDateRequired',
              t('errors:fieldIsRequired'),
              (value) => caseType !== CaseType.Repair || value != null,
            ),
          isJustified: yup.bool().nullable(),
          linkedCaseId: yup.string().nullable(),
          notificationDate: yup
            .date()
            .nullable()
            .max(getToday().endOf('day'), t('errors:dateCannotBeInTheFuture'))
            .test(
              'notificationDateRequired',
              t('errors:fieldIsRequired'),
              (value) => caseType !== CaseType.Repair || value != null,
            ),
          policeReportNumber: yup.string().nullable(),
          priority: yup.string().nullable().required(t('errors:fieldIsRequired')),
          remark: yup.string().nullable(),
          skill: yup.object().nullable(),
          thirdPartyInvolved: yup.bool().nullable(),
          verbalProcess: yup.bool().nullable(),
        })}
      >
        {({ setFieldValue }) => (
          <Form>
            {caseType === CaseType.Repair && (
              <Tooltip title={doesCaseHaveSalesInvoice ? t('salesInvoiceHasBeenLinkedToCase') : ''}>
                <FormGroup className="mb-3">
                  <FormikSelectContract
                    disabled={doesCaseHaveSalesInvoice || isDoesCaseHaveSalesInvoiceLoading}
                    name="contractId"
                    isRequired
                  />
                </FormGroup>
              </Tooltip>
            )}

            <FormGroup className="mb-3">
              <FormikTextField
                id="description"
                name="description"
                variant="standard"
                fullWidth
                required
                label={t('description')}
                type="text"
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <FormikTextField
                id="remark"
                name="remark"
                variant="standard"
                fullWidth
                label={t('remark')}
                type="text"
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <FormikAutocomplete
                id="channel"
                name="channel"
                isOptionEqualToValue={(option, value) => option.name === value}
                options={translatedCaseChannels.sort((a, b) => a.name.localeCompare(b.name))}
                getOptionLabel={(opt) => {
                  if (opt == null) {
                    return '';
                  }
                  return opt.translation ?? t(decapitalize(opt));
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.name} value={option.value}>
                    {option.translation}
                  </li>
                )}
                label={t('channel')}
                getValue={(val) => val?.name ?? val}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <FormikAutocomplete
                id="classification"
                name="classification"
                isOptionEqualToValue={(option, value) => option.id === value}
                options={caseClassifications}
                getOptionLabel={(opt) => {
                  if (opt === null) {
                    return '';
                  }
                  const currentTranslation = getCurrentTranslation(opt.translations, language);
                  if (currentTranslation) {
                    return currentTranslation;
                  }
                  const tryClas = caseClassifications.find((clas) => clas.id === opt);
                  return getCurrentTranslation(tryClas?.translations, language) ?? '';
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {getCurrentTranslation(option.translations, language)}
                  </li>
                )}
                label={t('classification')}
                getValue={(val) => val?.id ?? val}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <FormikDatePicker
                name="incidentDate"
                label={t('incidentDate')}
                required={caseType === CaseType.Repair}
                options={{
                  disableFuture: true,
                  format: 'YYYY-MM-DD',
                }}
              />
            </FormGroup>

            <FormGroup className="mb-3">
              <FormikEnumSelector
                id="priority"
                name="priority"
                label={t('priority')}
                enumValues={priorities}
                required
              />
            </FormGroup>

            {currentTeam.maxQrTokens > 0 && (
              <Stack direction="row" spacing={1}>
                <FormGroup className="mt-1">
                  <FormikCheckbox
                    callback={(value) => {
                      if (value && currentTeam.allowSyndicManagement) {
                        setFieldValue(
                          'rolesWithAccess',
                          Object.keys(BuildingRoleSelectorOption).filter(
                            (key) =>
                              key !== BuildingRoleSelectorOption.AllContactsAndCompanies &&
                              key !== BuildingRoleSelectorOption.None,
                          ),
                        );
                        return;
                      }

                      setFieldValue('rolesWithAccess', null);
                    }}
                    name="showOnBuildingPortal"
                    label={t('showOnBuildingPortal')}
                  />
                </FormGroup>
                {currentTeam.allowSyndicManagement && (
                  <Tooltip title={t('editVisibilityOnPortal')}>
                    <IconButton onClick={toggles.editAccessibility.inverse}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            )}

            {caseType === CaseType.Repair && (
              <FormGroup className="mb-3">
                <FormikDateTimePicker
                  name="notificationDate"
                  id="notificationDate"
                  label={t('dateAndTimeOfReceipt')}
                  inputFormat="DD-MM-YYYY HH:mm"
                  required
                  disableFuture
                />
              </FormGroup>
            )}
            {(caseType === CaseType.Repair || caseType === CaseType.InsuranceClaim) && (
              <FormGroup className="mb-3">
                <DamageTypeSelector
                  id="damageType"
                  name="damageType"
                  required
                  label={t('damageType')}
                />
              </FormGroup>
            )}
            {caseType === CaseType.Repair && (
              <>
                <FormGroup className="mb-3">
                  <FormikContractorSkillDefinitionSelector name="skill" label={t('skill')} />
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormikCheckbox
                    id="consentOnCancellationFee"
                    label={t('consentOnCancellationFee')}
                    name="consentOnCancellationFee"
                  />
                </FormGroup>
              </>
            )}
            {caseType === CaseType.InsuranceClaim && (
              <>
                <FormGroup className="mb-3">
                  <FormikYesNo
                    name="damageToCommonUnits"
                    id="damageToCommonUnits"
                    yesText={t('damageToCommonUnits')}
                    noText={t('noDamageToCommonUnits')}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormikYesNo
                    name="damageToPrivateUnits"
                    id="damageToPrivateUnits"
                    yesText={t('damageToPrivateUnits')}
                    noText={t('noDamageToPrivateUnits')}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormikYesNo
                    id="thirdPartyInvolved"
                    name="thirdPartyInvolved"
                    yesText={t('thirdPartyInvolved')}
                    noText={t('noThirdPartyInvolved')}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormikYesNo
                    id="verbalProcess"
                    name="verbalProcess"
                    yesText={t('verbalProcess')}
                    noText={t('noVerbalProcess')}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormikTextField
                    id="policeReportNumber"
                    name="policeReportNumber"
                    variant="standard"
                    fullWidth
                    label={t('policeReportNumber')}
                    type="text"
                  />
                </FormGroup>
              </>
            )}
            {caseType === CaseType.Complaint && (
              <>
                <FormGroup className="mb-3">
                  {selectorCasesIsLoading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <FormikAutocomplete
                      id="linkedCaseId"
                      name="linkedCaseId"
                      isOptionEqualToValue={(option, value) =>
                        option.id === value || option.id === value.id
                      }
                      options={caseOptions}
                      getOptionLabel={(opt) => {
                        if (opt == null) {
                          return '';
                        }
                        if (opt.id == null) {
                          const tryCase = caseOptions.find((co) => co.id === opt);
                          return tryCase.reference;
                        }
                        return opt.reference;
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id} value={option.id}>
                          {option.reference}
                        </li>
                      )}
                      label={t('linkedCase')}
                      getValue={(val) => val?.id ?? val}
                    />
                  )}
                </FormGroup>
                <FormGroup className="mb-3">
                  <FormikYesNo
                    name="isJustified"
                    id="isJustified"
                    yesText={t('isJustified')}
                    noText={t('notJustified')}
                  />
                </FormGroup>
              </>
            )}
            <div className="flex justify-center space-x-2 pt-2">
              <Button
                size="sm"
                color="neutral"
                variant="outlined"
                onClick={onCancel}
                startDecorator={<CancelIcon />}
              >
                {t('buttons.cancel')}
              </Button>
              <Button loading={isSubmitting} type="submit" startDecorator={<SaveIcon />}>
                {t('buttons.save')}
              </Button>
            </div>
            <EditCaseBuildingPortalVisibility toggle={toggles.editAccessibility} />
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        toggle={toggles.confirmation}
        icon={<WarningIcon />}
        color="warning"
        onConfirm={handleConfirmContractChange}
        onClose={handleCancelContractChange}
      >
        {t('confirmContractChange')}
      </ConfirmationModal>
    </>
  );
};

CaseNotificationCardEditForm.propTypes = propTypes;

export default CaseNotificationCardEditForm;
