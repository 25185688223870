import { Button, IconButton, Button as JoyButton, LinearProgress } from '@mui/joy';
import { Form, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ComposeEmailFormikValues, SenderOrReceiver } from '@/types/email/EmailFormikValues';
import { HandleDragAndDrop } from '@/types/email/EmailMessage';

import AttachmentsUploadView, { CaseAttachment } from '../AttachmentsUploadView';
import { tinyMCEToolbarSections } from '@/shared/utils/constants';
import { useGetBody } from './useComposeEmail';

import AttachFilesAction from '../AttachFilesAction';
import AttachmentsDropzone from '../AttachmentsDropzone';
import EmailFieldRow from '../EmailFieldRow';
import FormikEmailContactSelector from '@/shared/components/2.0/formik/FormikEmailContactSelector';
import FormikRichTextEditor from '@/shared/formik/FormikRichTextEditor';
import FormikTextField from '@/shared/components/2.0/formik/FormikTextField';
import { LoadingSpinner } from '@/containers/Layout/LoadingSpinner';
import { SendArrowIcon } from '@/shared/icons/Icons';
import Tooltip from '@/shared/components/2.0/Tooltip';

type Props = {
  bodyIsLoading: boolean;
  caseFiles?: CaseAttachment[];
  contactSuggestions?: SenderOrReceiver[];
  onDelete?: () => void;
  handleDragAndDrop?: HandleDragAndDrop;
};

const { ALIGN, FONT, LANGUAGE, LINK, LIST, STYLES, TEXT_DECORATION, UNDO_REDO } =
  tinyMCEToolbarSections;

const TOOLBAR_ITEMS = [UNDO_REDO, STYLES, TEXT_DECORATION, ALIGN, FONT, LIST, LINK, LANGUAGE];

const ComposeEmailBody: React.FC<Props> = ({
  onDelete,
  bodyIsLoading,
  caseFiles = [],
  contactSuggestions = [],
  handleDragAndDrop,
}) => {
  const { t } = useTranslation();

  const [isBccShown, setIsBccShown] = useState(false);
  const { setFieldValue, isSubmitting, values } = useFormikContext<ComposeEmailFormikValues>();
  const { body: emptyBody } = useGetBody('', null, false);

  useEffect(() => {
    if (values.bcc?.length) setIsBccShown(true);
  }, [values.bcc]);

  const handleClear = () => {
    setFieldValue('to', []);
    setFieldValue('cc', []);
    setFieldValue('bcc', []);
    setFieldValue('attachments', []);
    setFieldValue('subject', '');
    setFieldValue('body', emptyBody);
    setFieldValue('compressFiles', true);
  };

  return (
    <>
      {isSubmitting && <LinearProgress size="sm" className="absolute left-0 top-0 w-full" />}
      <AttachmentsDropzone name="attachments">
        <Form className="flex grow flex-col">
          <div className="flex">
            <Button
              disabled={isSubmitting}
              className="mb-0 mr-12 flex h-[4.5rem] shrink-0 items-center space-x-2 self-start"
              type="submit"
            >
              <div className="text-base">{t('send')}</div>
              <div>
                <SendArrowIcon className="m-0" />
              </div>
            </Button>

            <div className="mr-4 flex flex-grow flex-col space-y-1">
              <EmailFieldRow label={`${t('to')}:`}>
                <FormikEmailContactSelector
                  name="to"
                  suggestions={contactSuggestions}
                  handleDragAndDrop={handleDragAndDrop}
                  multiple
                />
              </EmailFieldRow>
              <EmailFieldRow label="Cc:">
                <FormikEmailContactSelector
                  name="cc"
                  endDecorator={
                    <JoyButton
                      size="sm"
                      variant="soft"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsBccShown((c) => !c);
                      }}
                    >
                      Bcc
                    </JoyButton>
                  }
                  suggestions={contactSuggestions}
                  handleDragAndDrop={handleDragAndDrop}
                  multiple
                />
              </EmailFieldRow>
              <Collapse in={isBccShown}>
                <EmailFieldRow label="Bcc:">
                  <FormikEmailContactSelector
                    name="bcc"
                    suggestions={contactSuggestions}
                    handleDragAndDrop={handleDragAndDrop}
                    multiple
                  />
                </EmailFieldRow>
              </Collapse>
            </div>

            <div className="flex flex-col items-center space-y-1">
              <AttachFilesAction name="attachments" />
              <Tooltip title={t('discard')}>
                <IconButton color="danger" size="sm" onClick={onDelete ?? handleClear}>
                  <FontAwesomeIcon icon={['fas', 'trash']} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="mt-4 flex">
            <FormikTextField name="subject" placeholder={t('addSubject')} size="sm" />

            <div className="ml-4 flex items-center space-y-1">
              <Tooltip title={t('compressAttachments')}>
                <IconButton
                  color={values.compressFiles ? 'success' : 'danger'}
                  size="sm"
                  onClick={() => setFieldValue('compressFiles', !values.compressFiles)}
                >
                  <FontAwesomeIcon icon={['fas', 'file-zipper']} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <AttachmentsUploadView
            name="attachments"
            caseFiles={orderBy(caseFiles, (file) => file.uploadDate, ['desc'])}
          />
          {bodyIsLoading ? (
            <LoadingSpinner />
          ) : (
            <FormikRichTextEditor
              name="body"
              height="100%"
              toolbarItems={TOOLBAR_ITEMS}
              statusbar={false}
            />
          )}
        </Form>
      </AttachmentsDropzone>
    </>
  );
};

export default ComposeEmailBody;
