import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  label: PropTypes.string.isRequired,
};

const EmailFieldRow = ({ label, children }) => (
  <div className="flex items-center space-x-2">
    <span className="w-10 text-base font-medium leading-none">{label}</span>
    <div className="flex-1">{children}</div>
  </div>
);

EmailFieldRow.propTypes = propTypes;

export default EmailFieldRow;
