import {
  Autocomplete,
  AutocompleteOption,
  FormControl,
  FormHelperText,
  ListItemContent,
  ListItemDecorator,
} from '@mui/joy';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddIcon, EditIcon } from '@/shared/icons/Icons';
import {
  EmailAction,
  EmailContactSelector as EmailContactSelectorType,
  isAction,
  isContactOrCompany,
  isNotFreeSolo,
  MultipleEmailContactSelector,
} from '@/types/email/EmailMessage';
import AvatarWithColor from '../AvatarWithColor';
import { isValidEmailAddress } from '../../../utils/helpers';
import RelationType from '../../../enums/RelationType';
import { search } from '../../../utils/constants';
import { trpc } from '@/config/trpc';
import useDebounce from '../../../hooks/UseDebounce';

type Props = EmailContactSelectorType | MultipleEmailContactSelector;

const EmailContactSelector: React.FC<Props> = ({
  suggestions = [],
  value,
  onChange,
  helperText,
  error = false,
  multiple,
  ...rest
}) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);

  const { data: emailAddresses, isLoading: isSearchLoading } = trpc.mailbox.search.useQuery(
    debouncedQuery,
    {
      enabled: debouncedQuery.length >= search.START_SEARCH_QUERY_LENGTH,
    },
  );

  const filteredEmailOptions = useMemo(() => {
    if (!emailAddresses) return [];
    return emailAddresses.flatMap((contact) =>
      contact.emailAddresses.map((email) => ({
        email,
        id: contact.id,
        name: contact.name,
        type: contact.type as 'Contact' | 'Company',
      })),
    );
  }, [emailAddresses]);

  const filteredSuggestions = suggestions
    .filter((suggestion) => suggestion.name && suggestion.name.toLowerCase().includes(query))
    .map((suggestion) => ({ ...suggestion, type: 'Suggestion' as const }));

  const autocompleteOptions = useMemo(() => {
    const options = [...filteredSuggestions, ...filteredEmailOptions];

    let action: EmailAction | null = null;

    const isExisting = options.some((option) => option?.email === query);

    if (query && !isExisting && isValidEmailAddress(query)) {
      action = {
        customIcon: <AddIcon />,
        customOptionLabel: t('useThisAddress', { address: query }),
        email: query,
        name: query.split('@')[0],
        type: 'Action' as const,
      };
    }

    if (query.length < search.START_SEARCH_QUERY_LENGTH) {
      action = {
        customIcon: <EditIcon className="pl-2" />,
        customOptionLabel: t('startTypingToSearchNetwork'),
        disabled: true,
        email: '',
        type: 'Action' as const,
      };
    }

    return [...options, action];
  }, [filteredEmailOptions, filteredSuggestions, query, t]);

  const isOpened =
    query.length >= search.START_SEARCH_QUERY_LENGTH || filteredSuggestions.length > 0;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter') {
      e.stopPropagation();
      e.preventDefault();

      if (!isValidEmailAddress(query)) return;

      const newOption = { email: query, name: query.split('@')[0] };

      if (multiple) {
        const valuesWithoutSelf = value.filter((contact) => contact.email !== query);

        onChange([...valuesWithoutSelf, newOption]);
      } else {
        onChange(newOption);
      }
    }
  };

  return (
    <FormControl error={error}>
      <Autocomplete
        size="sm"
        forcePopupIcon={false}
        loading={isOpened && isSearchLoading}
        options={autocompleteOptions}
        isOptionEqualToValue={(option, val) => option?.email === val?.email}
        onInputChange={(_, val) => setQuery(val.toLowerCase())}
        onChange={(_, val) => {
          if (!Array.isArray(val) && !multiple) {
            if (typeof val === 'string') {
              onChange({ email: val, name: val.split('@')[0] });
            } else {
              onChange(val);
            }
          }
          if (Array.isArray(val) && multiple) {
            const filteredValues = val.filter(isNotFreeSolo);

            onChange(filteredValues);
          }
        }}
        groupBy={(option) => {
          if (option && 'type' in option) {
            switch (option.type) {
              case RelationType.Contact:
                return t('contacts');
              case RelationType.Company:
                return t('companies');
              case 'Suggestion' as const:
                return t('suggestions');
              default:
                return '';
            }
          } else return '';
        }}
        autoComplete={false}
        inputValue={query}
        onKeyUpCapture={handleKeyDown}
        value={value}
        disableClearable
        multiple={multiple ?? true}
        // freeSolo so we dont show No options text initially
        freeSolo={query.length < search.START_SEARCH_QUERY_LENGTH}
        clearOnBlur
        renderOption={(props, option) => {
          if (option === null) return null;
          return (
            <AutocompleteOption
              {...props}
              key={`option-${option.email}-${option?.name}${
                isContactOrCompany(option) && `-${option.contactId ?? option.companyId}`
              } `}
            >
              <ListItemDecorator>
                {isAction(option) ? (
                  option.customIcon
                ) : (
                  <AvatarWithColor name={option.name} size="sm" />
                )}
              </ListItemDecorator>
              <ListItemContent className="flex items-baseline text-sm">
                {isAction(option) ? (
                  <span>{option.customOptionLabel}</span>
                ) : (
                  <>
                    <span>{option.name}</span>
                    <span className="ml-1 text-xs">[{option.email}]</span>
                  </>
                )}
              </ListItemContent>
            </AutocompleteOption>
          );
        }}
        getOptionDisabled={(option) =>
          option !== null && isAction(option) ? option.disabled ?? false : false
        }
        getOptionLabel={(option) =>
          option === null ? '' : typeof option !== 'string' ? option.email : option
        }
        noOptionsText={t('noOptions')}
        limitTags={2}
        {...rest}
      />
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default EmailContactSelector;
