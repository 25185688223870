import { Button, IconButton } from '@mui/joy';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentType, File as FileType } from '@/types/documents';

import { CaseContext } from '@/containers/Cases/CaseContext';
import { checkFileCanBePreviewed } from '@/shared/utils/helpers';
import downloadFile from '@/shared/utils/fileHelpers';
import { trpc } from '@/config/trpc';
import useToggle from '@/shared/hooks/UseToggle';

import { CreatedByField, DescriptionField, InfoSection, TypeName } from '../ItemStyles/Item-Styles';
import { DownloadIcon, SendArrowIcon } from '@/shared/icons/Icons';
import DocumentPreviewModal from '@/shared/components/documents/Documents/DocumentPreviewModal';
import SendCaseEmail from '@/containers/Cases/Email/SendCaseEmail';
import Tooltip from '@/shared/components/2.0/Tooltip';
import WrappedText from '@/shared/components/WrappedText';

type Props = {
  file: FileType;
};

const DocumentItem: React.FC<Props> = ({ file }) => {
  const { currentCase } = useContext(CaseContext);
  const { t } = useTranslation();
  const { data: emailAccounts } = trpc.mailbox.accounts.list.useQuery();

  const emailToggle = useToggle();
  const previewToggle = useToggle();

  const type = useMemo(
    () =>
      Object.keys(ContentType).filter(
        (key) => ContentType[key as keyof typeof ContentType] === file.contentType,
      ),
    [file.contentType],
  );

  const fileToPreview = useMemo(
    () => ({
      ...file,
      contentType: file.contentType || ContentType.TXT,
      extension: file.extension || undefined,
      fileName: file.fileName || file.id,
      fileUri: file.fileUri || '',
    }),
    [file],
  );

  return (
    <>
      <TypeName>{t('document')}</TypeName>
      {file.uploadedBy && <CreatedByField>{file.uploadedBy}</CreatedByField>}

      <br />

      {file.fileName && (
        <InfoSection>
          <DescriptionField>{t('description')}: </DescriptionField>
          <WrappedText>{file.fileName}</WrappedText>
        </InfoSection>
      )}

      {type && (
        <InfoSection>
          <DescriptionField>{t('type')}: </DescriptionField>
          <span>{type}</span>
        </InfoSection>
      )}

      <br />

      <Tooltip title={t('download')}>
        <IconButton variant="plain" onClick={() => downloadFile(fileToPreview)}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      {!!emailAccounts?.length && (
        <Tooltip title={t('sendEmail')}>
          <IconButton variant="plain" onClick={emailToggle.show}>
            <SendArrowIcon />
          </IconButton>
        </Tooltip>
      )}

      {checkFileCanBePreviewed(file.contentType) && (
        <div className="float-right">
          <Button variant="plain" onClick={previewToggle.show}>
            {t('viewDetails')}
          </Button>
          {fileToPreview && <DocumentPreviewModal toggle={previewToggle} file={fileToPreview} />}
        </div>
      )}
      <SendCaseEmail
        toggle={emailToggle}
        initialFiles={[file]}
        initialSubject={currentCase.currentTeamReference}
      />
    </>
  );
};

export default DocumentItem;
