import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Button, FormControl, FormLabel, Stack } from '@mui/joy';

import { BuildingRoleSelectorOption } from '@/shared/enums/BuildingRoleSelectorOption';
import { useAutocompleteEnum } from '@/shared/hooks/autocomplete';
import { UseToggle } from '@/shared/hooks/UseToggle';

import { CancelIcon, SaveIcon } from '@/shared/icons/Icons';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

type Props = {
  toggle: UseToggle;
};

const EditCaseBuildingPortalVisibility: React.FC<Props> = ({ toggle }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<{
    rolesWithAccess: BuildingRoleSelectorOption[] | null;
  }>();

  const [rolesWithAccess, setRolesWithAccess] = useState<BuildingRoleSelectorOption[]>(values.rolesWithAccess ?? [])

  const roleSelectorAutocomplete = useAutocompleteEnum({
    callback: (options) => {
      if (
        options
          .map((option) => option.value)
          .includes(BuildingRoleSelectorOption.AllContactsAndCompanies) &&
        !rolesWithAccess?.includes(BuildingRoleSelectorOption.AllContactsAndCompanies)
      ) {
        setRolesWithAccess([BuildingRoleSelectorOption.AllContactsAndCompanies])
        return;
      }

      const newOptions = options
        .map((option) => option.value)
        .filter((opt) => opt !== BuildingRoleSelectorOption.AllContactsAndCompanies);

      setRolesWithAccess(newOptions);
    },
    includeValue: (value) => value !== BuildingRoleSelectorOption.None,
    multiple: true,
    selected: rolesWithAccess,
    srcEnum: BuildingRoleSelectorOption,
    translationKey: '_buildingRoleSelectorOption',
  });

  const handleCancel = () => {
    setRolesWithAccess(values.rolesWithAccess ?? []);
    toggle.hide();
  };


  const handleSave = () => {
    setFieldValue('rolesWithAccess', rolesWithAccess);
    toggle.hide();
  };

  return (
    <KpcModal title={t('editVisibilityOnPortal')} toggle={toggle} onClose={handleCancel}>
      <Stack spacing={2}>
        <FormControl>
          <FormLabel>{t('selectType', { type: t('role') })}</FormLabel>
          <Autocomplete {...roleSelectorAutocomplete} />
        </FormControl>
        <Stack direction="row" spacing={2} alignSelf="flex-end">
          <Button
            onClick={handleCancel}
            startDecorator={<CancelIcon />}
            variant="outlined"
            color="neutral"
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleSave}
            startDecorator={<SaveIcon />}
          >
            {t('save')}
          </Button>
        </Stack>
      </Stack>
    </KpcModal>
  );
};

export default EditCaseBuildingPortalVisibility;
