import { useCallback, useEffect, useMemo } from 'react';

import {
  AutocompleteOption,
  AutocompleteOptionsArgs,
  AutocompleteValue,
  GenericAutocompleteOptionsArgs,
  OnChangeEvent,
  UseAutocompleteOptionsResult,
} from './autocomplete.types';
import { Obj } from '~/common/types';

type BaseOptions<TValue extends string | number, TAdditionalProps extends Obj> = {
  data?: AutocompleteOption<TValue, TAdditionalProps>[];
  isLoading?: boolean;
};

type GenericOptions<TValue extends string | number, TAdditionalProps extends Obj> = BaseOptions<
  TValue,
  TAdditionalProps
> &
  GenericAutocompleteOptionsArgs<TValue, TAdditionalProps>;

type Options<TValue extends string | number, TAdditionalProps extends Obj> = BaseOptions<
  TValue,
  TAdditionalProps
> &
  AutocompleteOptionsArgs<TValue, TAdditionalProps>;

// regular functions for function overload -> dynamic output without union type based on input
function useAutocompleteOptions<TValue extends string | number, TAdditionalProps extends Obj>(
  options: Options<TValue, TAdditionalProps> & {
    disableClearable?: boolean;
    multiple: true;
  },
): UseAutocompleteOptionsResult<TValue, true, TAdditionalProps>;

function useAutocompleteOptions<TValue extends string | number, TAdditionalProps extends Obj>(
  options: Options<TValue, TAdditionalProps> & {
    disableClearable: true;
    multiple?: false;
  },
): UseAutocompleteOptionsResult<TValue, false | undefined, TAdditionalProps>;

function useAutocompleteOptions<TValue extends string | number, TAdditionalProps extends Obj>(
  options: Options<TValue, TAdditionalProps> & {
    disableClearable?: false;
    multiple?: false;
  },
): UseAutocompleteOptionsResult<TValue, false | undefined, TAdditionalProps>;

function useAutocompleteOptions<TValue extends string | number, TAdditionalProps extends Obj>(
  options: GenericOptions<TValue, TAdditionalProps>,
): UseAutocompleteOptionsResult<TValue, boolean | undefined, TAdditionalProps>;

function useAutocompleteOptions<TValue extends string | number, TAdditionalProps extends Obj>({
  callback,
  data,
  disableClearable,
  disableCloseOnSelect = false,
  disabled = false,
  enforceSingleOptionPrefill = true,
  isLoading = false,
  multiple,
  selected,
}:
  | GenericOptions<TValue, TAdditionalProps>
  | Options<TValue, TAdditionalProps>): UseAutocompleteOptionsResult<
  TValue,
  typeof multiple,
  TAdditionalProps
> {
  const handleChange = useCallback(
    (_: OnChangeEvent, newValue: AutocompleteValue<TValue, typeof multiple, TAdditionalProps>) => {
      if (disableClearable && !newValue) return;

      const multipleOptions = Array.isArray(newValue);

      if (multiple && multipleOptions) {
        callback(newValue);
      } else if (!multiple && !multipleOptions) {
        if (disableClearable && newValue) {
          callback(newValue);
        } else if (!disableClearable) {
          callback(newValue);
        }
      }
    },
    [callback, disableClearable, multiple],
  );

  const value = useMemo(() => {
    if (!selected) return multiple ? [] : null;

    if (Array.isArray(selected)) {
      return data?.filter((option) => selected?.includes(option.value)) || [];
    }

    return data?.find((option) => option.value === selected) || null;
  }, [data, multiple, selected]);
  useEffect(() => {
    const hasValue = !!value && (!Array.isArray(value) || !!value.length);

    if (!enforceSingleOptionPrefill || hasValue || data?.length !== 1) return;

    if (multiple) {
      callback(data);
    } else {
      callback(data[0]);
    }
  }, [callback, data, enforceSingleOptionPrefill, multiple, value]);

  return {
    disableClearable: !!disableClearable && value !== null,
    disableCloseOnSelect: multiple || disableCloseOnSelect,
    disabled: isLoading || (enforceSingleOptionPrefill && data?.length === 1) || disabled,
    loading: isLoading,
    multiple,
    onChange: handleChange,
    options: data ?? [],
    value,
  };
}

export default useAutocompleteOptions;
