import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Address, AutocompleteOption } from '~/common/types';
import { isTeamRelationOption, RelationOption } from '@/types/trpc/case';
import { CaseClassificationOption } from '@/types/trpc/team';
import { RelationType } from '~/common/enums';

type DefaultFormValues = {
  administratorOptions: AutocompleteOption[];
  admins: string[];
  buildingOption: AutocompleteOption;
  channel?: string;
  classification: CaseClassificationOption;
  clients: RelationOption[];
  description: string;
  priority?: string;
  requestor: RelationOption;
  task?: string;
  address?: {
    addressLine?: string;
    city?: string;
    countryCode?: string;
    postalCode?: string;
  };
};

type ClientValues = {
  [RelationType.Contact]: string[];
  [RelationType.Company]: string[];
  [RelationType.Team]: string[];
};

type RequestorValues = {
  requestorCompanyId: string | null;
  requestorContactId: string | null;
  requestorTeamId: string | null;
};

export const valuesToCaseRequest = (values: DefaultFormValues) => {
  const { address, administratorOptions, buildingOption, clients, classification ,requestor } = values;

  const addressValues = address
    ? {
        ...address,
        country: address?.countryCode,
      }
    : null;

  const admins = administratorOptions.map(({ value }) => value);

  const clientIdsByType = clients.reduce<ClientValues>(
    (acc, item) => {
      const key = item.type;

      if (key in acc) {
        acc[key].push(item.value);
      }
      if (isTeamRelationOption(item)) {
        acc.Company.push(item.companyId);
      }

      return acc;
    },
    {
      [RelationType.Contact]: [],
      [RelationType.Company]: [],
      [RelationType.Team]: [],
    },
  );

  const requestorValues = (() => {
    const val: RequestorValues = {
      requestorCompanyId: null,
      requestorContactId: null,
      requestorTeamId: null,
    };

    const { value, type } = requestor;

    if (type === RelationType.Company) val.requestorCompanyId = value;
    if (type === RelationType.Contact) val.requestorContactId = value;
    if (isTeamRelationOption(requestor)) {
      val.requestorCompanyId = requestor.companyId;
      val.requestorTeamId = value;
    }

    return val;
  })();

  return {
    ...values,
    address: addressValues,
    admins,
    buildingId: buildingOption?.value || null,
    classificationId: classification?.value || null,
    clientCompanyIds: clientIdsByType[RelationType.Company],
    clientContactIds: clientIdsByType[RelationType.Contact],
    clientTeamIds: clientIdsByType[RelationType.Team],
    ...requestorValues,
  };
};

export const useCaseFormConfig = () => {
  const { t } = useTranslation('errors');

  return {
    validationSchema: yup.object().shape(
      {
        address: yup
          .object()
          .nullable()
          .when('buildingOption', {
            is: (val: AutocompleteOption) => !val,
            then: yup.object().nullable().required(t('fieldIsRequired')),
          }),
        buildingOption: yup
          .object()
          .nullable()
          .when('address', {
            is: (val: Address) => !val,
            then: yup.object().nullable().required(t('fieldIsRequired')),
          }),
        description: yup.string().nullable().required(t('fieldIsRequired')),
        requestor: yup.object().nullable().required(t('fieldIsRequired')),
      },
      [['address', 'buildingOption']],
    ),
  };
};
