import * as yup from 'yup';
import { Button, Divider, FormHelperText } from '@mui/joy';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddressPropTypes from '../../../prop-types/AddressPropTypes';

import { AddIcon, BuildingIcon, ValidCompanyIcon } from '../../../icons/Icons';
import AddEditBuildingForm from '../../../../containers/Buildings/AddEditBuilding/AddEditBuildingForm';
import FormikAddressForm from '../../../formik/FormikAddressForm';
import ModalFooter from '../../2.0/forms/ModalFooter';
import TextCheckbox from '../../checkboxes/TextCheckbox';

const ITEM_LIMIT = 5;

const propTypes = {
  filteredBuildings: PropTypes.arrayOf(
    PropTypes.shape({
      address: AddressPropTypes,
      centralCompanyId: PropTypes.string,
      display: PropTypes.string,
      id: PropTypes.string,
      isAddress: PropTypes.bool,
      linkedToCentralCompany: PropTypes.bool,
    }),
  ).isRequired,
  handleAddAddress: PropTypes.func.isRequired,
  handleCreateBuilding: PropTypes.func.isRequired,
  handleSelectOrDeselect: PropTypes.func.isRequired,
  hideAddAddress: PropTypes.bool.isRequired,
  hideAddBuilding: PropTypes.bool.isRequired,
  isAddingNewAddress: PropTypes.bool.isRequired,
  isAddingNewBuilding: PropTypes.bool.isRequired,
  prefillAddress: AddressPropTypes,
  selectOnly: PropTypes.bool.isRequired,
  selectorMenuRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  setIsAddingNewAddress: PropTypes.func.isRequired,
  setIsAddingNewBuilding: PropTypes.func.isRequired,
  showInactiveSwitch: PropTypes.bool,
  showSuggestions: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      address: AddressPropTypes,
      centralCompanyId: PropTypes.string,
      display: PropTypes.string,
      id: PropTypes.string,
      isAddress: PropTypes.bool,
      linkedToCentralCompany: PropTypes.bool,
    }),
  ).isRequired,
};

const BuildingSelectorList = ({
  selectorMenuRef,
  isAddingNewAddress,
  filteredBuildings,
  suggestions,
  handleSelectOrDeselect,
  selectOnly,
  hideAddBuilding,
  isAddingNewBuilding,
  setIsAddingNewBuilding,
  hideAddAddress,
  setIsAddingNewAddress,
  handleCreateBuilding,
  handleAddAddress,
  showSuggestions,
  prefillAddress = null,
  showInactiveSwitch = false,
}) => {
  const { t } = useTranslation();

  const [showInActive, setShowInActive] = useState(false);

  return (
    <div className="flex flex-col space-y-2 rounded-bl-xl rounded-br-xl p-4" ref={selectorMenuRef}>
      {showInactiveSwitch && (
        <div>
          <TextCheckbox
            yesText={t('showInactive')}
            noText={t('onlyActive')}
            callback={setShowInActive}
            isChecked={showInActive}
          />
        </div>
      )}
      {!isAddingNewAddress && !isAddingNewBuilding && (
        <>
          {!!suggestions.length && showSuggestions && (
            <>
              <h5 className="text-primary">{t('suggestions')}</h5>
              <div>
                {suggestions
                  .filter((b, i) => (showInActive || b.active) && i < ITEM_LIMIT)
                  .map((building) => (
                    <Button
                      startDecorator={
                        building.linkedToCentralCompany ? (
                          <>
                            <BuildingIcon />
                            <ValidCompanyIcon />
                          </>
                        ) : (
                          <BuildingIcon />
                        )
                      }
                      className="grow justify-start text-left font-normal"
                      variant="plain"
                      color="neutral"
                      key={building.id}
                      onClick={() => handleSelectOrDeselect(building)}
                    >
                      {building.display}
                    </Button>
                  ))}
                {filteredBuildings.length > ITEM_LIMIT && (
                  <FormHelperText className="justify-center text-xs">
                    {t('moreResultsAreHiddenRefineFilter')}
                  </FormHelperText>
                )}
              </div>
            </>
          )}
          {filteredBuildings.length > 0 && (
            <>
              <h5 className="text-primary">{t('building_other')}</h5>
              <div className="flex flex-col">
                {filteredBuildings
                  .filter((b, i) => (showInActive || b.active) && i < ITEM_LIMIT)
                  .map((building) => (
                    <Button
                      startDecorator={
                        building.linkedToCentralCompany ? (
                          <>
                            <BuildingIcon />
                            <ValidCompanyIcon />
                          </>
                        ) : (
                          <BuildingIcon />
                        )
                      }
                      className="grow justify-start text-left font-normal"
                      variant="plain"
                      color="neutral"
                      key={building.id}
                      onClick={() => handleSelectOrDeselect(building)}
                    >
                      {building.display}
                    </Button>
                  ))}
                {filteredBuildings.length > ITEM_LIMIT && (
                  <FormHelperText className="justify-center text-xs">
                    {t('moreResultsAreHiddenRefineFilter')}
                  </FormHelperText>
                )}
              </div>
            </>
          )}
          {!selectOnly && (
            <div className="flex flex-col">
              <Divider />
              {!hideAddBuilding && (
                <Button
                  variant="plain"
                  color="neutral"
                  className="justify-start text-left font-normal"
                  startDecorator={<AddIcon />}
                  onClick={() => setIsAddingNewBuilding(true)}
                >
                  {t('addType', { type: t('building_one') })}
                </Button>
              )}
              {!hideAddAddress && (
                <Button
                  variant="plain"
                  color="neutral"
                  className="justify-start text-left font-normal"
                  startDecorator={<AddIcon />}
                  onClick={() => setIsAddingNewAddress(true)}
                >
                  {t('addType', { type: t('_address.label') })}
                </Button>
              )}
            </div>
          )}
        </>
      )}
      {isAddingNewBuilding && (
        <>
          <h5 className="text-primary">{t('addType', { type: t('building_one') })}</h5>
          <div className="p-4">
            <AddEditBuildingForm
              onCancel={() => setIsAddingNewBuilding(false)}
              onSubmit={handleCreateBuilding}
              initialBuilding={prefillAddress != null ? { address: prefillAddress } : undefined}
              minified
            />
          </div>
        </>
      )}
      {isAddingNewAddress && (
        <>
          <h5 className="section__title">{t('addType', { type: t('address') })}</h5>
          <div className="p-3">
            <Formik
              onSubmit={handleAddAddress}
              initialValues={{
                addressLine: '',
                city: '',
                country: 'BE',
                postalCode: '',
              }}
              validationSchema={yup.object().shape(
                {
                  addressLine: yup
                    .string()
                    .nullable()
                    .when(['city', 'postalCode'], {
                      is: (city, postalCode) => city || postalCode,
                      then: yup.string().nullable().required(t('errors:fieldIsRequired')),
                    }),
                  city: yup
                    .string()
                    .nullable()
                    .when(['addressLine', 'postalCode'], {
                      is: (al, pc) => al || pc,
                      then: yup.string().nullable().required(t('errors:fieldIsRequired')),
                    }),
                  country: yup.string().nullable(),
                  postalCode: yup
                    .string()
                    .nullable()
                    .when(['addressLine', 'city'], {
                      is: (addressLine, city) => addressLine || city,
                      then: yup.string().nullable().required(t('errors:fieldIsRequired')),
                    }),
                },
                [
                  ['addressLine', 'city'],
                  ['city', 'postalCode'],
                  ['addressLine', 'postalCode'],
                ],
              )}
            >
              <Form>
                <FormikAddressForm />
                <ModalFooter onCancel={() => setIsAddingNewAddress(false)} />
              </Form>
            </Formik>
          </div>
        </>
      )}
    </div>
  );
};

BuildingSelectorList.propTypes = propTypes;

export default BuildingSelectorList;
