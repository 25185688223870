import { Alert, IconButton } from '@mui/joy';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { OpenIcon, WarningIcon } from '@/shared/icons/Icons';
import { AppContext } from '@/shared/context/context';
import DashboardHeader from './Widgets/DashboardHeader';
import DashboardTitle from './Widgets/DashboardTitle';
import KpcFullPage from '@/shared/components/2.0/layout/KpcFullPage';
import OpenTasksByTaskTypeChart from './Widgets/OpenTasksByTaskTypeChart';
import OpenTaskTable from './Widgets/OpenTasksTable';
import { trpc } from '@/config/trpc';
import { useUserRole } from '@/queries';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const {
    ui: {
      dashboard: { toggles, columnCount },
    },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { isSupervisor } = useUserRole();
  const { t } = useTranslation();

  const { data: emailAccounts } = trpc.mailbox.accounts.list.useQuery();

  const oldAccounts = useMemo(() => {
    if (!emailAccounts) return [];

    return emailAccounts.filter((ea) => !ea.isNewAccount);
  }, [emailAccounts]);

  const handleNavigateToRelink = () => {
    if (oldAccounts.some((ea) => ea.isTeamMailbox) && isSupervisor) {
      navigate('/team/settings/communication');
      return;
    }

    if (oldAccounts.every((ea) => !ea.isTeamMailbox)) navigate('/user/settings/email-accounts');
  };

  return (
    <KpcFullPage>
      <DashboardTitle />
      {!!oldAccounts.length && (
        <Alert
          className="w-50 my-2"
          size="lg"
          startDecorator={<WarningIcon />}
          color="danger"
          endDecorator={
            isSupervisor || oldAccounts.some((ea) => !ea.isTeamMailbox) ? (
              <IconButton variant="plain" color="neutral" onClick={handleNavigateToRelink}>
                <OpenIcon />
              </IconButton>
            ) : undefined
          }
        >
          {t('followingAccountsNeedToBeRelinked', {
            accounts: oldAccounts.map((x) => x.emailAddress).join(', '),
          })}
        </Alert>
      )}
      {toggles.header && <DashboardHeader />}
      <div className={classNames(`grid grid-cols-${columnCount} gap-4`)}>
        {toggles.openTasksByTypeChart && <OpenTasksByTaskTypeChart />}
        {toggles.openTasksTable && <OpenTaskTable />}
      </div>
    </KpcFullPage>
  );
};

export default Dashboard;
