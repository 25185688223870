import { Button, Checkbox, Tooltip } from '@mui/joy';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '@/shared/context/context';

import EmailContactSelector from '~/frontend/shared/components/2.0/select/SelectEmailContact';
import { InfoIcon } from '@/shared/icons/Icons';
import useToggle from '~/frontend/shared/hooks/UseToggle';

type DataProps = {
  emailAddress: string;
};

type Options = {
  enabled: boolean;
  onSubmit: (data: DataProps) => void;
};

type UserProps = {
  email: string;
  name: string;
};

const InvolvedPartiesAddBuildingAsClient = ({ enabled, onSubmit }: Options) => {
  const { t } = useTranslation();

  const { currentTeamUser } = useContext(AppContext);

  const { value: mailVisible, inverse: invertMailVisible } = useToggle();
  const [mail, setMail] = useState<UserProps>(currentTeamUser);
  return (
    <div className="flex h-12 items-center space-x-2 border-t-2">
      <Checkbox disabled={!enabled} label={t('addBuildingAsClient')} onChange={invertMailVisible} />
      {mailVisible && (
        <>
          <EmailContactSelector onChange={setMail} value={mail} multiple={false} />
          <Tooltip title={t('fillEmailaddressUsedForCommunication')} placement="top" arrow>
            <span className="mr-2 text-gray-500">
              <InfoIcon size="md" />
            </span>
          </Tooltip>
          <Button size="sm" variant="soft" onClick={() => onSubmit({ emailAddress: mail.email })}>
            {t('add')}
          </Button>
        </>
      )}
    </div>
  );
};

export default InvolvedPartiesAddBuildingAsClient;
