import IntegrationBindingType from './enums/integration/integrationBindingType';
import IntegrationPartner from './enums/integration/integrationPartner';
import { Language } from './enums';

export const pagination = {
  queryParam: {
    FIRST_PAGE: 1,
    PAGE_SIZE: 50,
  },
};

export const languageMap: { [key: string]: Language } = {
  en: Language.English,
  'en-GB': Language.English,
  fr: Language.French,
  nl: Language.Dutch,
};

export const chartColors = [
  'rgba(255, 99, 132, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(255, 159, 64, 0.5)',
  'rgba(255, 99, 132, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(255, 159, 64, 0.5)',
  'rgba(255, 99, 132, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(255, 159, 64, 0.5)',
  'rgba(255, 99, 132, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(255, 159, 64, 0.5)',
  'rgba(255, 99, 132, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(255, 159, 64, 0.5)',
  'rgba(255, 99, 132, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(255, 159, 64, 0.5)',
  'rgba(255, 99, 132, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
];

export const integrationsConfig = [
  {
    binding: IntegrationBindingType.Password,
    logoClassName: 'companyweb-logo',
    partner: IntegrationPartner.CompanyWeb,
  },
  {
    binding: IntegrationBindingType.OAuth,
    logoClassName: 'exactonline-logo',
    partner: IntegrationPartner.ExactOnline,
  },
  {
    binding: IntegrationBindingType.Token,
    logoClassName: 'metamaze-logo',
    partner: IntegrationPartner.Metamaze,
  },
  {
    binding: IntegrationBindingType.OAuth,
    logoClassName: 'octopus-logo',
    partner: IntegrationPartner.Octopus,
  },
  {
    binding: IntegrationBindingType.OAuth,
    logoClassName: 'organimmo-logo',
    partner: IntegrationPartner.Organimmo,
  },
  {
    binding: IntegrationBindingType.OAuth,
    logoClassName: 'teamleader-logo',
    partner: IntegrationPartner.Teamleader,
  },
  {
    binding: IntegrationBindingType.OAuth,
    logoClassName: 'isabel-connect-logo',
    partner: IntegrationPartner.Isabel6,
  },
  {
    binding: IntegrationBindingType.OAuth,
    logoClassName: 'keypoint-invoicing-logo',
    partner: IntegrationPartner.KeypointInvoicing,
  },
];
