import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
  ModalProps,
} from '@mui/joy';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useToggle, { UseToggle } from '@/shared/hooks/UseToggle';

import ConfirmationModal from '../../ConfirmationModal';
import { DeleteIconAlt } from '@/shared/icons/Icons';

type BaseProps = Omit<ModalProps, 'children' | 'onClose' | 'open'>;

type Props<T> = BaseProps &
  React.PropsWithChildren<{
    actions?: React.ReactNode;
    backdropBehavior?:
      | 'closeOnSingleClick'
      | 'closeOnDoubleClick'
      | 'noCloseOnClick'
      | 'confirmOnDoubleClick';
    confirmOnEscape?: boolean;
    description?: string;
    hideClose?: boolean;
    icon?: React.ReactNode;
    noDivider?: boolean;
    onClose?: () => void;
    scrollGutter?: boolean;
    size?: 'sm' | 'md' | 'lg';
    toggle: UseToggle<T>;
  }>;

const KpcModal = <T,>({
  actions,
  backdropBehavior = 'closeOnDoubleClick',
  children,
  className = 'w-1/2',
  confirmOnEscape = false,
  description,
  hideClose = false,
  icon,
  noDivider = false,
  onClose,
  scrollGutter = false,
  size,
  title,
  toggle,
  ...rest
}: Props<T>) => {
  const showConfirmationModal = useToggle();
  const { t } = useTranslation();

  const handleClose = () => {
    onClose?.();
    toggle.hide();
  };

  const onClickBackdrop = () => {
    if (backdropBehavior === 'closeOnSingleClick') {
      handleClose();
    }
  };

  const onDoubleClickBackdrop = () => {
    if (backdropBehavior === 'closeOnDoubleClick') {
      handleClose();
    }
    if (backdropBehavior === 'confirmOnDoubleClick') {
      showConfirmationModal.show();
    }
  };

  return (
    <>
      <Modal
        open={toggle.value}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') return;
          if (reason === 'escapeKeyDown' && confirmOnEscape) {
            showConfirmationModal.show();
            return;
          }
          handleClose();
        }}
        slotProps={{
          backdrop: {
            onClick: onClickBackdrop,
            onDoubleClick: onDoubleClickBackdrop,
          },
        }}
        {...rest}
      >
        <ModalDialog className={className} size={size}>
          {!hideClose && <ModalClose className="z-10" />}
          {(!!title || !!icon) && (
            <DialogTitle className="flex items-center gap-2 text-base">
              {icon} {title}
            </DialogTitle>
          )}
          {!noDivider && <Divider className="mb-1" inset="none" />}
          {!!description && <p className="text-sm">{description}</p>}
          <DialogContent
            className={classNames('overflow-x-hidden', {
              'pb-2': !actions && scrollGutter,
              'px-2': scrollGutter,
            })}
          >
            {children}
          </DialogContent>
          {!!actions && <DialogActions>{actions}</DialogActions>}
        </ModalDialog>
      </Modal>

      {showConfirmationModal.value && (
        <ConfirmationModal
          title={t('areYouSureForm')}
          onConfirm={handleClose}
          icon={<DeleteIconAlt />}
          color="danger"
          toggle={showConfirmationModal}
          buttonProps={{ primaryFilled: true }}
        >
          <p className="pl-4 pt-4">{t('changesWillBeLost')}</p>
        </ConfirmationModal>
      )}
    </>
  );
};

export default KpcModal;
