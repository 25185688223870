import { useEffect, useMemo } from 'react';
import { Autocomplete } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { MailboxAccount } from '@/types/trpc/mailbox';

import { localStorageKeys } from '@/shared/utils/constants';
import { trpc } from '@/config/trpc';
import { useAutocompleteOptions } from '@/shared/hooks/autocomplete';
import { useMailboxContext } from '../store';

import FormField from '@/shared/components/2.0/input/FormField';
import { InlineSpinner } from '@/shared/icons/Icons';

type MailboxAccounts = {
  personal: MailboxAccount[];
  team: MailboxAccount[];
};

type Props = {
  alwaysShow?: boolean;
  allowUserAccounts?: boolean;
  className?: string;
  clearMailboxState?: boolean;
  disabled?: boolean;
  showLabel?: boolean;
};

const EmailAccountsAutocomplete: React.FC<Props> = ({
  alwaysShow = false,
  allowUserAccounts = true,
  className,
  clearMailboxState,
  disabled = false,
  showLabel = false,
}) => {
  const {
    setEmailAccount,
    state: { emailAccount },
  } = useMailboxContext();
  const { t } = useTranslation();

  const optionsRes = trpc.mailbox.accounts.autocomplete.useQuery(
    allowUserAccounts ? undefined : true,
  );

  const emailAccountAutocompleteProps = useAutocompleteOptions({
    ...optionsRes,
    callback: (option) => setEmailAccount(option.data, clearMailboxState),
    disableClearable: true,
    disabled,
    selected: emailAccount?.linkId,
  });

  const mailboxAccounts = useMemo(() => {
    if (!optionsRes.data)
      return {
        personal: [],
        team: [],
      };

    return optionsRes.data.reduce<MailboxAccounts>(
      (acc, emailAccountOption) => {
        const { data } = emailAccountOption;

        if (data.isTeamMailbox) {
          acc.team.push(data);
        } else {
          acc.personal.push(data);
        }

        return acc;
      },
      {
        personal: [],
        team: [],
      },
    );
  }, [optionsRes.data]);

  useEffect(() => {
    // length 1 is already handled by 'useAutocompleteOptions' hook
    if (!optionsRes.data || optionsRes.data.length <= 1 || emailAccount?.linkId) return;

    const savedEmailAccount = optionsRes.data.find(
      (account) =>
        account.value === localStorage.getItem(localStorageKeys.mailbox.SELECTED_EMAIL_LINK_ID),
    )?.data;

    const toPrefillEmail =
      savedEmailAccount ?? mailboxAccounts.personal[0] ?? mailboxAccounts.team[0];

    setEmailAccount(toPrefillEmail, clearMailboxState);
  }, [clearMailboxState, emailAccount?.linkId, mailboxAccounts, optionsRes.data, setEmailAccount]);

  return (
    optionsRes.data &&
    (alwaysShow || optionsRes.data.length > 1) && (
      <FormField
        className={className}
        label={showLabel ? t('selectType', { type: t('emailAccount') }) : undefined}
      >
        <Autocomplete
          {...emailAccountAutocompleteProps}
          noOptionsText={allowUserAccounts ? t('noOptions') : t('noTeamEmailsFound')}
          placeholder={t('selectMailbox')}
          startDecorator={emailAccountAutocompleteProps.loading && <InlineSpinner />}
        />
      </FormField>
    )
  );
};

export default EmailAccountsAutocomplete;
