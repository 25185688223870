import { ContentType, File } from '@/types/documents';
import {
  EmailMessageResponse,
  SendEmailFromCaseRequest,
  SendMessageRequest,
} from '~/common/types/email/emailMessage';
import { Nullable, Obj } from '~/common/types';

export type SenderOrReceiver = {
  name?: Nullable<string>;
  email: string;
  id?: string;
  type?: string;
  contactId?: Nullable<string>;
  companyId?: Nullable<string>;
  roles?: string[];
};

export type FormikEmailAttachment = {
  id: string;
  file: File;
  isForwardAttachment: boolean;
  isEntityFile: boolean;
  messageId?: string;
};

export type EmailFileResponse = {
  accountId?: Nullable<string>;
  contentDisposition: string;
  contentId: string;
  contentType: ContentType;
  errorMessage?: Nullable<string>;
  fileName: string;
  id: string;
  isAttachment: boolean;
  isImage: boolean;
  isInline: boolean;
  isSuccess: boolean;
  number: number;
  object: Record<string, unknown> | null;
  statusCode: number;
};

export type EmailData = EmailMessageResponse & {
  body: string;
  files: EmailFileResponse[];
  dateEpoch?: Nullable<number>;
  id?: Nullable<string>;
};

export type SendMessageFormikValues = Omit<
  SendMessageRequest,
  'attachments' | 'to' | 'cc' | 'bcc'
> & {
  attachments: FormikEmailAttachment[];
  to?: SenderOrReceiver[];
  cc?: SenderOrReceiver[];
  bcc?: SenderOrReceiver[];
};

export type CaseEmailFormikValues = Omit<
  SendEmailFromCaseRequest,
  'attachments' | 'to' | 'cc' | 'bcc'
> & {
  attachments: FormikEmailAttachment[];
  to?: SenderOrReceiver[];
  cc?: SenderOrReceiver[];
  bcc?: SenderOrReceiver[];
  compressFiles: boolean;
};

export type ComposeEmailFormikValues = {
  attachments: FormikEmailAttachment[];
  to?: SenderOrReceiver[];
  cc?: SenderOrReceiver[];
  bcc?: SenderOrReceiver[];
  body?: string;
  subject?: string;
  compressFiles: boolean;
};

export const isEmailContact = (item: Obj): item is SenderOrReceiver =>
  !!((item as SenderOrReceiver).contactId || (item as SenderOrReceiver).companyId);

export const isEmailContactList = (item: Obj[]): item is SenderOrReceiver[] =>
  item.length > 0 && item.every(isEmailContact);

export const isSenderOrReceiver = (item: Obj): item is SenderOrReceiver =>
  !!(item as SenderOrReceiver).email;
