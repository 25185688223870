// eslint-disable-next-line import/prefer-default-export
export enum BuildingRoleSelectorOption {
  None = 'None',
  AllContactsAndCompanies = 'AllContactsAndCompanies',
  Owner = 'Owner',
  Tenant = 'Tenant',
  BoardOfCoOwnership = 'BoardOfCoOwnership',
  Accountant = 'Accountant',
  President = 'President',
  MemberOfRME = 'MemberOfRME',
  ViceChairman = 'ViceChairman',
  ContactPerson = 'ContactPerson',
}
