import { EmailAddressRequest } from '~/common/types/meeting/meetingCommunications';
import { Obj } from '~/common/types';
import { SenderOrReceiver } from './EmailFormikValues';

export type EmailSuggestion = EmailAddressRequest & {
  id: string;
  type: 'Suggestion';
};
export type ContactOption = EmailAddressRequest & {
  companyId: null;
  contactId: string;
  type: 'Contact';
};

export type CompanyOption = EmailAddressRequest & {
  companyId: string;
  contactId: null;
  type: 'Company';
};

export type EmailAction = EmailAddressRequest & {
  customOptionLabel: string;
  customIcon: React.ReactNode;
  disabled?: boolean;
  type: 'Action';
};

export type EmailOption = EmailAddressRequest | ContactOption | CompanyOption | EmailSuggestion;

export type CommonContactSelectorProps = {
  suggestions?: SenderOrReceiver[];
  helperText?: string;
  error?: boolean;
  name: string;
  endDecorator?: React.ReactNode;
};

export type MultipleEmailContactSelector = CommonContactSelectorProps & {
  value: EmailOption[];
  onChange: (value: EmailOption[]) => void;
  multiple: true;
};

export type EmailContactSelector = CommonContactSelectorProps & {
  value: EmailOption;
  onChange: (value: EmailOption) => void;
  multiple?: false;
};

export type DraggableEmailContactSelectorChip = {
  item: EmailOption;
  index: number;
  getTagProps: (args: { index: number }) => Record<string, unknown>;
  name: string;
};

export type HandleDragAndDrop = (id: string, fromList: string, toList: string) => void;

export const isContactOrCompany = (item: Obj): item is ContactOption | CompanyOption =>
  item.contactId !== undefined && item.companyId !== undefined;

export const isAction = (item: Obj): item is EmailAction =>
  item.customIcon !== undefined && item.customOptionLabel !== undefined;

export const isNotFreeSolo = <TOption extends EmailAddressRequest>(
  item: TOption | string | null,
): item is TOption => typeof item !== 'string' && !!item?.name;
