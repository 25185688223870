import {
  Autocomplete,
  AutocompleteOption,
  Chip,
  ChipDelete,
  FormControl,
  FormHelperText,
  ListItemContent,
  ListItemDecorator,
} from '@mui/joy';
import { useDrag, useDrop } from 'react-dnd';
import { useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import {
  ComposeEmailFormikValues,
  isEmailContactList,
  SenderOrReceiver,
} from '@/types/email/EmailFormikValues';
import { EmailContact } from '@/types/trpc/hoa';
import { RelationType } from '~/common/enums';

import { isValidEmailAddress } from '../../../utils/helpers';
import { search } from '../../../utils/constants';
import { trpc } from '@/config/trpc';
import useDebounce from '../../../hooks/UseDebounce';

import { AddIcon, EditIcon } from '@/shared/icons/Icons';
import {
  DraggableEmailContactSelectorChip,
  EmailAction,
  HandleDragAndDrop,
  isAction,
  isContactOrCompany,
  isNotFreeSolo,
  MultipleEmailContactSelector,
} from '@/types/email/EmailMessage';
import AvatarWithColor from '../AvatarWithColor';

type ChipProps = DraggableEmailContactSelectorChip;
type SelectorProps = MultipleEmailContactSelector & { handleDragAndDrop: HandleDragAndDrop };

export const DraggableChip: React.FC<ChipProps> = ({ item, index, getTagProps, name }) => {
  const { setFieldValue, values } = useFormikContext<ComposeEmailFormikValues>();
  const [{ isDragging }, dragRef] = useDrag(() => {
    const draggedItem = { contact: item, source: name };
    return {
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: draggedItem,
      type: 'CONTACT',
    };
  });

  const handleDelete = () => {
    const currentField = values[name as keyof ComposeEmailFormikValues] as SenderOrReceiver[];
    if (Array.isArray(currentField) && isEmailContactList(currentField)) {
      const updatedFieldValues = currentField?.filter((field) => {
        if (isContactOrCompany(item)) {
          return field.contactId !== item.contactId || field.companyId !== item.companyId;
        }
        return field.email !== item.email;
      });
      setFieldValue(name, updatedFieldValues);
    }
    if (Array.isArray(currentField) && !isEmailContactList(currentField)) {
      const currentFieldArray = [...currentField] as { name: string; email: string }[];
      const updatedFieldValues = currentFieldArray.filter(
        (contact) => contact.email !== item.email,
      );
      setFieldValue(name, updatedFieldValues);
    }
  };

  return (
    <Chip
      onMouseDown={(e) => e.stopPropagation()}
      ref={(node) => {
        dragRef(node);
      }}
      draggable
      style={{ opacity: isDragging ? 0.5 : 1 }}
      variant="soft"
      color="neutral"
      endDecorator={
        <ChipDelete
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        >
          <CloseIcon className="h-4 opacity-80 hover:opacity-100" />
        </ChipDelete>
      }
      sx={{ minWidth: 0 }}
      {...getTagProps({ index })}
      onClick={() => {}}
    >
      {item.email}
    </Chip>
  );
};

export const SelectEmailContactDraggable: React.FC<SelectorProps> = ({
  suggestions = [],
  value,
  onChange,
  helperText,
  error = false,
  handleDragAndDrop,
  multiple = true,
  name,
  ...rest
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);
  const { data: emailAddresses, isLoading: isSearchLoading } = trpc.mailbox.search.useQuery(
    debouncedQuery,
    {
      enabled: debouncedQuery.length >= search.START_SEARCH_QUERY_LENGTH,
    },
  );

  const [, dropRef] = useDrop({
    accept: 'CONTACT',
    drop: (draggedItem: { contact: EmailContact; source: string }) => {
      const id = isContactOrCompany(draggedItem.contact)
        ? draggedItem.contact.contactId ?? draggedItem.contact.companyId
        : draggedItem.contact.email;

      if (draggedItem.source !== name) {
        handleDragAndDrop(id, draggedItem.source, name);
      }
    },
  });

  const filteredEmailOptions = useMemo(() => {
    if (!emailAddresses) return [];

    return emailAddresses.flatMap((contact) =>
      contact.emailAddresses.map((email) => ({
        email,
        id: contact.id,
        name: contact.name,
        type: contact.type as 'Contact' | 'Company',
      })),
    );
  }, [emailAddresses]);

  const filteredSuggestions = suggestions
    .filter((suggestion) => suggestion.name && suggestion.name.toLowerCase().includes(query))
    .map((suggestion) => ({ ...suggestion, type: 'Suggestion' as const }));

  const autocompleteOptions = useMemo(() => {
    const options = [...filteredSuggestions, ...filteredEmailOptions];

    let action: EmailAction | null = null;

    const isExisting = options.some((option) => option?.email === query);

    if (query && !isExisting && isValidEmailAddress(query)) {
      action = {
        customIcon: <AddIcon />,
        customOptionLabel: t('useThisAddress', { address: query }),
        email: query,
        name: query.split('@')[0],
        type: 'Action' as const,
      };
    }

    if (query.length < search.START_SEARCH_QUERY_LENGTH) {
      action = {
        customIcon: <EditIcon className="pl-2" />,
        customOptionLabel: t('startTypingToSearchNetwork'),
        disabled: true,
        email: '',
        type: 'Action' as const,
      };
    }

    return [...options, action];
  }, [filteredEmailOptions, filteredSuggestions, query, t]);

  const isOpened =
    query.length >= search.START_SEARCH_QUERY_LENGTH || filteredSuggestions.length > 0;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      if (isValidEmailAddress(query)) {
        onChange([
          ...value.filter((contact) => contact.email !== query),
          { email: query, name: query.split('@')[0] },
        ]);
      }
    }
  };

  return (
    <FormControl error={error}>
      <div
        ref={(node) => {
          dropRef(node);
        }}
      >
        <Autocomplete
          size="sm"
          forcePopupIcon={false}
          loading={isOpened && isSearchLoading}
          options={autocompleteOptions}
          isOptionEqualToValue={(option, val) => option?.email === val?.email}
          onInputChange={(_, val) => setQuery(val.toLowerCase())}
          onChange={(_, val) => {
            const filteredValues = val.filter(isNotFreeSolo);

            onChange(filteredValues);
          }}
          groupBy={(option) => {
            if (option && 'type' in option) {
              switch (option.type) {
                case RelationType.Contact:
                  return t('contacts');
                case RelationType.Company:
                  return t('companies');
                case 'Suggestion':
                  return t('suggestions');
                default:
                  return '';
              }
            } else return '';
          }}
          autoComplete={false}
          inputValue={query}
          value={value}
          disableClearable
          onKeyDownCapture={handleKeyDown}
          multiple={multiple}
          // freeSolo so we dont show No options text initially
          freeSolo={query.length < search.START_SEARCH_QUERY_LENGTH}
          clearOnBlur
          renderTags={(tags, getTagProps) =>
            tags
              .filter((item) => item !== null)
              .map((item, index) => (
                <DraggableChip
                  key={uuidv4()}
                  item={item}
                  name={name}
                  index={index}
                  getTagProps={getTagProps}
                />
              ))
          }
          renderOption={(props, option) => {
            if (option === null) return null;
            return (
              <AutocompleteOption
                {...props}
                key={`option-${option.email}-${option?.name}${
                  isContactOrCompany(option) && `-${option.contactId ?? option.companyId}`
                } `}
              >
                <ListItemDecorator>
                  {isAction(option) ? (
                    option.customIcon
                  ) : (
                    <AvatarWithColor name={option.name} size="sm" />
                  )}
                </ListItemDecorator>
                <ListItemContent className="flex items-baseline text-sm">
                  {isAction(option) ? (
                    <span>{option.customOptionLabel}</span>
                  ) : (
                    <>
                      <span>{option.name}</span>
                      <span className="ml-1 text-xs">[{option.email}]</span>
                    </>
                  )}
                </ListItemContent>
              </AutocompleteOption>
            );
          }}
          getOptionDisabled={(option) =>
            option !== null && isAction(option) ? option.disabled ?? false : false
          }
          getOptionLabel={(option) =>
            option === null ? '' : typeof option !== 'string' ? option.email : option
          }
          noOptionsText={t('noOptions')}
          {...rest}
        />
      </div>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectEmailContactDraggable;
