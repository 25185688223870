import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import FormikAutocomplete from './FormikAutocomplete';
import useGetContracts from '../../queries/contracts/useGetContracts';

const propTypes = {
  disabled: PropTypes.bool,
  initialContracts: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.string.isRequired,
      whitelabelId: PropTypes.string,
    }),
  ),
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  translatedHeaderText: PropTypes.string,
};

const FormikSelectContract = ({
  translatedHeaderText = '',
  initialContracts = null,
  name,
  isRequired = false,
  disabled = false,
}) => {
  const { t } = useTranslation('common');
  const [contracts, setContracts] = useState([]);
  const { setFieldValue } = useFormikContext();
  const { contracts: allContracts, contractsIsLoading } = useGetContracts({
    hideInactiveContracts: true,
  });

  useEffect(() => {
    if (initialContracts) {
      setContracts(initialContracts);
      if (initialContracts.length === 1) {
        setFieldValue('contractId', initialContracts[0].id);
      }
      return;
    }

    if (contractsIsLoading) {
      return;
    }

    setContracts(allContracts);
    if (allContracts.length === 1) {
      setFieldValue('contractId', allContracts[0].id);
    }
  }, [contractsIsLoading, allContracts, initialContracts, setFieldValue]);

  return (
    <div>
      {contracts.length > 1 && !translatedHeaderText && translatedHeaderText.length > 0 && (
        <h5 style={{ textAlign: 'center' }}>{translatedHeaderText}</h5>
      )}
      <FormikAutocomplete
        disabled={disabled}
        name={name}
        options={contracts}
        isOptionEqualToValue={(opt, val) => opt.id === val.id || opt.id === val}
        getOptionLabel={(opt) => {
          if (opt === null) return '';
          if (opt.description != null) return opt.description;
          const tryContract = contracts.find((c) => c.id === opt);
          return tryContract?.description ?? '';
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.description}
          </li>
        )}
        label={t('common:contract')}
        getValue={(val) => val?.id ?? val}
        required={isRequired}
      />
    </div>
  );
};

export default FormikSelectContract;

FormikSelectContract.propTypes = propTypes;
