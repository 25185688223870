import { lazy, Suspense, useContext, useEffect, useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { FileContextParentType, FileContextProvider } from '@/FileContext';
import {
  getFsmaNumbers,
  getPolicies,
  getTags,
  getTeamIntegrations,
  getUserTeamSettings,
} from '@/redux/actions';
import ROUTES from './Routes';
import useViewport from '@/shared/hooks/useViewport';

import { AppContext } from '@/shared/context/context';
import BuildingContextProvider from '@/containers/Buildings/context/BuildingContextProvider';
import CallbackFront from '../../Callback/CallbackFront';
import CaseDetail from '@/containers/Cases/CaseDetail';
import Dashboard from '@/containers/Home/Dashboard';
import HomeownerAssociationContextProvider from '@/containers/HomeownerAssociation/context/HomeownerAssociationContextProvider';
import { InlineSpinner } from '@/shared/icons/Icons';
import OverViewSkeleton from '@/shared/components/2.0/skeletons/OverviewSkeleton';
import { useUserRole } from '@/queries';

const BlogOverview = lazy(() => import('@/containers/Blogs/BlogOverview'));
const FsmaNbrOverview = lazy(() => import('@/containers/FsmaNbr/FsmaNbrOverview'));
const KboPublicSearchBalance = lazy(() =>
  import('@/containers/Administration/KboPublicSearchBalance'),
);
const SmsOverview = lazy(() => import('@/containers/Sms/SmsOverview'));
const UnusedOctopusDossiers = lazy(() =>
  import('@/containers/Administration/UnusedOctopusDossiers'),
);

const ContactDetails = lazy(() => import('@/containers/Contacts/Details/ContactDetails'));
const ContactEmailsOverview = lazy(() =>
  import('@/containers/Contacts/Details/ContactEmailsOverview'),
);

const CompanyDetails = lazy(() => import('@/containers/Companies/Details/CompanyDetails'));
const CompanyEmailsOverview = lazy(() =>
  import('@/containers/Companies/Details/CompanyEmailsOverview'),
);

const CalendarsOverview = lazy(() => import('@/containers/Calendars/CalendarsOverview'));
const CommonSettings = lazy(() => import('@/containers/UserSettings/CommonSettings'));
const CommonTeamSettings = lazy(() =>
  import('@/containers/Teams/TeamSettings/Common/CommonSettings'),
);
const BuildingPortalSettings = lazy(() =>
  import('@/containers/Teams/TeamSettings/BuildingPortal/BuildingPortalSettings'),
);
const CaseEmailsOverview = lazy(() => import('@/containers/Cases/Email/CaseEmailsOverview'));
const CaseSettings = lazy(() => import('@/containers/Teams/TeamSettings/Case/CaseSettings'));
const CommunicationOverview = lazy(() =>
  import('@/containers/Teams/TeamSettings/Communication/CommunicationOverview'),
);
const Finance = lazy(() => import('@/containers/Cases/FinancialManagement/Finance'));
const FinanceSettings = lazy(() =>
  import('@/containers/Teams/TeamSettings/Finance/FinanceSettings'),
);
const IntegrationSettings = lazy(() =>
  import('@/containers/Teams/TeamSettings/Integrations/IntegrationSettings'),
);

const Repair = lazy(() => import('@/containers/Teams/TeamSettings/Repair/Repair'));
const TagsOverview = lazy(() => import('@/containers/Teams/TeamSettings/Tags/TagsOverview'));
const TaskTypesOverview = lazy(() =>
  import('@/containers/Teams/TeamSettings/TaskTypes/TaskTypesOverview'),
);
const TeamUsers = lazy(() => import('@/containers/Teams/TeamSettings/Users/TeamUsers'));

const NotFound = lazy(() => import('../../Error/NotFound'));

const Calendar = lazy(() => import('../../Calendars/Calendar'));
const MailboxCard = lazy(() => import('../../Mailbox/MailboxCard'));
const EmailAccounts = lazy(() => import('../../EmailAccounts/EmailAccounts'));

const ContactsOverview = lazy(() => import('../../Contacts/ContactsOverview'));
const CompaniesOverview = lazy(() => import('../../Companies/CompaniesOverview'));
const BuildingsOverview = lazy(() => import('../../Buildings/BuildingsOverview'));
const TasksOverview = lazy(() => import('../../Tasks/TasksOverview'));
const PoliciesOverview = lazy(() => import('../../Policies/PoliciesOverview'));
const CaseOverview = lazy(() => import('../../Cases/CaseOverview'));
const DebtorOverviewWrapper = lazy(() =>
  import('../../Finance/Debtors/repair/DebtorOverviewWrapper'),
);
const HoaDebtorOverview = lazy(() => import('../../Finance/Debtors/hoa/HoaDebtorOverview'));
const Insights = lazy(() => import('../../Insights/Insights'));
const Policy = lazy(() => import('../../Policies/Policy'));
const BatchCommunicationOverview = lazy(() =>
  import('../../BatchCommunication/BatchCommunicationOverview'),
);
const InvoiceOverview = lazy(() => import('../../Finance/Invoices/InvoiceOverview'));
const EnvelopeOverview = lazy(() => import('../../Finance/Envelopes/EnvelopeOverview'));

const Administration = lazy(() => import('../../Administration/Administration'));
const Support = lazy(() => import('../../Support/Support'));
const TeamSettings = lazy(() => import('../../Teams/TeamSettings/TeamSettings'));
const UserSettings = lazy(() => import('../../UserSettings/UserSettings'));

const BuildingDetail = lazy(() => import('../../Buildings/Details/BuildingDetail'));
const BuildingUnitDetails = lazy(() => import('../../Buildings/Units/Details/BuildingUnitDetails'));
const Contact = lazy(() => import('../../Contacts/Details/Contact'));
const Company = lazy(() => import('../../Companies/Details/Company'));
const BatchDetail = lazy(() => import('../../BatchCommunication/Details/Batch'));
const Case = lazy(() => import('../../Cases/Case'));
const ContractDetail = lazy(() =>
  import('../../Companies/Contracts/ContractDetail/ContractDetail'),
);

const HomeownerAssociationDetail = lazy(() =>
  import('../../HomeownerAssociation/HomeownerAssociationDetail'),
);
const HomeownerAssociationSettings = lazy(() =>
  import('@/containers/HomeownerAssociation/Settings/HomeOwnerAssociationSettings'),
);
const HomeownerAssociationMeetings = lazy(() =>
  import('@/containers/HomeownerAssociation/Meetings/HomeownerAssociationMeetings'),
);
const ProvisionRequests = lazy(() =>
  import('@/containers/HomeownerAssociation/ProvisionRequests/ProvisionRequests'),
);
const MeterReadings = lazy(() =>
  import('@/containers/HomeownerAssociation/MeterReadings/MeterReadings'),
);
const HomeownerAssociationFinance = lazy(() =>
  import('@/containers/HomeownerAssociation/Finance/HomeownerAssociationFinance'),
);
const Budgets = lazy(() => import('@/containers/HomeownerAssociation/Budgets/Budgets'));
const HomeownerAssociationDocuments = lazy(() =>
  import('@/containers/HomeownerAssociation/Documents/HomeownerAssociationDocuments'),
);

const HomeownerAssociationEmailsOverview = lazy(() =>
  import('@/containers/HomeownerAssociation/Email/HomeownerAssociationEmailsOverview'),
);
const HomeownerAssociationSyndicManagement = lazy(() =>
  import('@/containers/HomeownerAssociation/SyndicManagement/HomeownerAssociationSyndicManagement'),
);

const SpreadsheetDetail = lazy(() =>
  import(
    '../../Cases/FinancialManagement/BodySections/Spreadsheets/SpreadsheetDetail/SpreadsheetDetail'
  ),
);
const MeetingDetail = lazy(() =>
  import('@/containers/HomeownerAssociation/Meetings/Details/MeetingDetail'),
);
const MeetingInProgress = lazy(() =>
  import('@/containers/HomeownerAssociation/Meetings/InProgress/MeetingInProgress'),
);
const MeetingRegistration = lazy(() =>
  import('@/containers/HomeownerAssociation/Meetings/Registration/MeetingRegistration'),
);
const MeetingDetailRerouter = lazy(() => import('@/containers/Meetings/MeetingDetailRerouter'));

const MeetingPlanning = lazy(() => import('@/containers/Meetings/MeetingPlanning'));

const MeetingSettings = lazy(() =>
  import('@/containers/Teams/TeamSettings/Meetings/MeetingSettings'),
);
const MeetingTypeDetail = lazy(() =>
  import('@/containers/Teams/TeamSettings/Meetings/MeetingTypes/MeetingTypeDetail'),
);
const InvoiceValidate = lazy(() =>
  import('@/containers/Finance/Invoices/ToValidate/InvoiceValidate'),
);
const PerformanceSettings = lazy(() =>
  import('@/containers/Teams/TeamSettings/Performances/PerformanceSettings'),
);

const AssetTemplates = lazy(() =>
  import('@/containers/Teams/TeamSettings/AssetTemplates/AssetTemplates'),
);

const StatementsOverview = lazy(() => import('@/containers/Finance/Statements/StatementsOverview'));

const HomeScores = lazy(() =>
  import('@/containers/Administration/HomeScores'),
);

const Router = () => {
  const viewport = useViewport();
  const { currentTeam } = useContext(AppContext);
  const { pathname } = useLocation();
  const { isAdmin, isSupervisor } = useUserRole();

  const isRouteCallBack = useMemo(() => pathname === ROUTES.CALLBACK, [pathname]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isRouteCallBack || !currentTeam) {
      return;
    }
    // TODO: 8983: Performance: Refactor remaining redux store initial data fetching to AppContext
    dispatch(getUserTeamSettings());
    dispatch(getTags());
    dispatch(getPolicies());
    dispatch(getFsmaNumbers());
    dispatch(getTeamIntegrations());
  }, [dispatch, currentTeam, isRouteCallBack]);

  return (
    <Suspense
      fallback={
        <div
          style={{ height: viewport.mainContainerHeight }}
          className="animate-pulse rounded-3xl bg-background"
        />
      }
    >
      {isRouteCallBack && (
        <Routes>
          <Route path={ROUTES.CALLBACK} element={<CallbackFront />} />
        </Routes>
      )}

      <Routes>
        {isAdmin && (
          <Route path={ROUTES.ADMINISTRATION} element={<Administration />}>
            <Route path={ROUTES.ADMINISTRATION} element={<BlogOverview />} />
            <Route path={ROUTES.ADMINISTRATION_BLOG} element={<BlogOverview />} />
            <Route path={ROUTES.ADMINISTRATION_FSMA} element={<FsmaNbrOverview />} />
            <Route path={ROUTES.ADMINISTRATION_SMS} element={<SmsOverview />} />
            <Route path={ROUTES.ADMINISTRATION_KBO} element={<KboPublicSearchBalance />} />
            <Route
              path={ROUTES.ADMINISTRATION_UNUSED_OCTOPUS_DOSSIERS}
              element={<UnusedOctopusDossiers />}
            />
            <Route path={ROUTES.ADMINISTRATION_HOME_SCORE} element={<HomeScores />} />
          </Route>
        )}
        {(isSupervisor || isAdmin) && currentTeam?.allowRepairManagement && (
          <Route path={ROUTES.REPAIR_DEBTOR_MANAGEMENT} element={<DebtorOverviewWrapper />} />
        )}
        {isSupervisor && (
          <Route path={ROUTES.TEAM_SETTINGS} element={<TeamSettings />}>
            {!!currentTeam?.allowRepairManagement && (
              <Route path={ROUTES.TEAM_SETTINGS_REPAIR} element={<Repair />} />
            )}
            {!!currentTeam?.allowSyndicManagement && (
              <>
                <Route path={ROUTES.TEAM_SETTINGS_ASSETS} element={<AssetTemplates />} />

                {isSupervisor && (
                  <>
                    <Route path={ROUTES.TEAM_SETTINGS_MEETINGS} element={<MeetingSettings />} />
                    <Route
                      path={`${ROUTES.TEAM_SETTINGS_MEETINGS}/${ROUTES.TEAM_SETTINGS_MEETINGS_MEETINGTYPE_DETAIL}`}
                      element={<MeetingTypeDetail />}
                    />
                    <Route
                      path={ROUTES.TEAM_SETTINGS_PERFORMANCES}
                      element={<PerformanceSettings />}
                    />
                  </>
                )}
              </>
            )}
            {(!!currentTeam?.allowSyndicManagement || !!currentTeam?.allowRepairManagement) && (
              <Route
                path={ROUTES.TEAM_SETTINGS_FINANCE}
                element={
                  <Suspense fallback={<InlineSpinner />}>
                    <FinanceSettings />
                  </Suspense>
                }
              />
            )}
            {!!currentTeam?.maxQrTokens && (
              <Route
                path={ROUTES.TEAM_SETTINGS_BUILDING_PORTAL}
                element={<BuildingPortalSettings />}
              />
            )}
            <Route
              path={ROUTES.TEAM_SETTINGS_CASE}
              element={
                <FileContextProvider type={FileContextParentType.CaseTemplate}>
                  <CaseSettings />
                </FileContextProvider>
              }
            />
            <Route path={ROUTES.TEAM_SETTINGS_COMMUNICATION} element={<CommunicationOverview />} />
            <Route path={ROUTES.TEAM_SETTINGS_GENERAL} element={<CommonTeamSettings />} />
            <Route path={ROUTES.TEAM_SETTINGS_INTEGRATIONS} element={<IntegrationSettings />} />
            <Route path={ROUTES.TEAM_SETTINGS_LABELS} element={<TagsOverview />} />
            <Route path={ROUTES.TEAM_SETTINGS_TASKTYPES} element={<TaskTypesOverview />} />
            <Route path={ROUTES.TEAM_SETTINGS_TEAMMEMBERS} element={<TeamUsers />} />
          </Route>
        )}
        {currentTeam?.allowSyndicManagement && (
          <>
            <Route path={ROUTES.HOA_DEBTOR_MANAGEMENT} element={<HoaDebtorOverview />} />
            <Route path={ROUTES.INVOICE_MANAGEMENT} element={<InvoiceOverview />} />
            <Route path={ROUTES.ACCOUNTING_STATEMENTS} element={<StatementsOverview />} />
            <Route path={ROUTES.ACCOUNTING_ENVELOPES} element={<EnvelopeOverview />} />
          </>
        )}
        <Route path={ROUTES.HOME} element={<Dashboard />} />
        <Route
          path={ROUTES.COMPANIES_OVERVIEW}
          element={
            <Suspense fallback={<OverViewSkeleton />}>
              <CompaniesOverview />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.BUILDINGS_OVERVIEW}
          element={
            <Suspense fallback={<OverViewSkeleton />}>
              <BuildingsOverview />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.CASE_OVERVIEW}
          element={
            <Suspense fallback={<OverViewSkeleton />}>
              <CaseOverview />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.TASKS_OVERVIEW}
          element={
            <Suspense fallback={<OverViewSkeleton />}>
              <TasksOverview />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.CONTACT_DASHBOARD}
          element={
            <Suspense fallback={<OverViewSkeleton />}>
              <ContactsOverview />
            </Suspense>
          }
        />

        <Route path={ROUTES.CONTACT_DETAIL} element={<Contact />}>
          <Route path={ROUTES.CONTACT_DETAIL} element={<ContactDetails />} />
          <Route path={ROUTES.DETAILS_MAILBOX} element={<ContactEmailsOverview />} />
        </Route>

        <Route path={ROUTES.COMPANY_DETAIL} element={<Company />}>
          <Route path={ROUTES.COMPANY_DETAIL} element={<CompanyDetails />} />
          <Route path={ROUTES.DETAILS_MAILBOX} element={<CompanyEmailsOverview />} />
        </Route>

        <Route path={ROUTES.SUPPORT_OVERVIEW} element={<Support />} />
        <Route path={ROUTES.USER_SETTINGS} element={<UserSettings />}>
          <Route path={ROUTES.USER_SETTINGS_GENERAL} element={<CommonSettings />} />
          <Route path={ROUTES.USER_SETTINGS_CALENDARS} element={<CalendarsOverview isPersonal />} />
          <Route path={ROUTES.USER_SETTINGS_EMAILACCOUNTS} element={<EmailAccounts />} />
        </Route>

        <Route path={ROUTES.MAILBOX} element={<MailboxCard />} />
        <Route path={ROUTES.CALENDAR} element={<Calendar />} />
        <Route path={ROUTES.CONTRACT_DETAIL} element={<ContractDetail />} />
        <Route path={ROUTES.INSIGHTS} element={<Insights />} />
        <Route path={ROUTES.BUILDING_DETAIL}>
          <Route
            index
            element={
              <BuildingContextProvider>
                <BuildingDetail />
              </BuildingContextProvider>
            }
          />
          <Route
            path={ROUTES.BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL}
            element={
              <HomeownerAssociationContextProvider>
                <HomeownerAssociationDetail />
              </HomeownerAssociationContextProvider>
            }
          >
            <Route index element={<HomeownerAssociationSettings />} />
            <Route
              path={ROUTES.BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_MEETINGS}
              element={<HomeownerAssociationMeetings />}
            />
            <Route
              path={ROUTES.BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_FINANCE}
              element={<HomeownerAssociationFinance />}
            />
            <Route path={ROUTES.BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_COSTS} />
            <Route
              path={ROUTES.BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_BUDGET}
              element={<Budgets />}
            />
            <Route
              path={ROUTES.BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_PROVISIONS}
              element={<ProvisionRequests />}
            />
            <Route
              path={ROUTES.BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_DOCUMENTS}
              element={<HomeownerAssociationDocuments />}
            />
            <Route
              path={ROUTES.BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_COMMUNICATION}
              element={<HomeownerAssociationEmailsOverview />}
            />
            <Route
              path={ROUTES.BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_SYNDICMANAGEMENT}
              element={<HomeownerAssociationSyndicManagement />}
            />
            <Route
              path={ROUTES.BUILDING_DETAIL_HOMEOWNER_ASSOCIATION_DETAIL_METERREADINGS}
              element={<MeterReadings />}
            />
          </Route>
        </Route>
        <Route
          path={ROUTES.HOMEOWNER_ASSOCIATION_DETAIL}
          element={
            <HomeownerAssociationContextProvider>
              <HomeownerAssociationDetail />
            </HomeownerAssociationContextProvider>
          }
        >
          <Route index element={<HomeownerAssociationSettings />} />
          <Route
            path={ROUTES.HOMEOWNER_ASSOCIATION_DETAIL_MEETINGS}
            element={<HomeownerAssociationMeetings />}
          />
          <Route
            path={ROUTES.HOMEOWNER_ASSOCIATION_DETAIL_FINANCE}
            element={<HomeownerAssociationFinance />}
          />
          <Route path={ROUTES.HOMEOWNER_ASSOCIATION_DETAIL_COSTS} />
          <Route path={ROUTES.HOMEOWNER_ASSOCIATION_DETAIL_BUDGET} element={<Budgets />} />
          <Route
            path={ROUTES.HOMEOWNER_ASSOCIATION_DETAIL_PROVISIONS}
            element={<ProvisionRequests />}
          />
          <Route
            path={ROUTES.HOMEOWNER_ASSOCIATION_DETAIL_DOCUMENTS}
            element={<HomeownerAssociationDocuments />}
          />
          <Route
            path={ROUTES.HOMEOWNER_ASSOCIATION_DETAIL_COMMUNICATION}
            element={<HomeownerAssociationEmailsOverview />}
          />
          <Route
            path={ROUTES.HOMEOWNER_ASSOCIATION_DETAIL_SYNDICMANAGEMENT}
            element={<HomeownerAssociationSyndicManagement />}
          />
          <Route
            path={ROUTES.HOMEOWNER_ASSOCIATION_DETAIL_METERREADINGS}
            element={<MeterReadings />}
          />
        </Route>
        <Route
          path={ROUTES.BUILDING_UNIT_DETAIL}
          element={
            <BuildingContextProvider>
              <BuildingUnitDetails />
            </BuildingContextProvider>
          }
        />
        <Route path={ROUTES.HOMEOWNER_ASSOCIATION_MEETING_DETAIL} element={<MeetingDetail />} />
        <Route path={ROUTES.MEETING_DETAIL} element={<MeetingDetailRerouter />} />
        <Route path={ROUTES.MEETING_IN_PROGRESS} element={<MeetingInProgress />} />
        <Route path={ROUTES.MEETING_REGISTRATION} element={<MeetingRegistration />} />
        <Route path={ROUTES.MEETINGS_OVERVIEW} element={<MeetingPlanning />} />
        <Route
          path={ROUTES.CASE_DETAIL}
          element={
            <FileContextProvider type={FileContextParentType.Case}>
              <Case />
            </FileContextProvider>
          }
        >
          <Route path={ROUTES.CASE_DETAIL} element={<CaseDetail />} />
          <Route path={ROUTES.CASE_MAILBOX} element={<CaseEmailsOverview />} />
        </Route>
        <Route path={ROUTES.FINANCE} element={<Finance />} />
        <Route
          path={ROUTES.SPREADSHEET_DETAIL}
          element={
            <FileContextProvider type={FileContextParentType.Spreadsheet}>
              <SpreadsheetDetail />
            </FileContextProvider>
          }
        />
        <Route
          path={ROUTES.TEAM_SETTINGS_EMAILACCOUNT_DETAIL}
          element={<EmailAccounts showTeamMailboxes />}
        />
        <Route path={ROUTES.POLICY_OVERVIEW} element={<PoliciesOverview />} />
        <Route
          path={ROUTES.POLICY_DETAIL}
          element={
            <FileContextProvider type={FileContextParentType.Policy}>
              <Policy />
            </FileContextProvider>
          }
        />
        <Route
          path={ROUTES.BATCH_COMMUNICATION_OVERVIEW}
          element={<BatchCommunicationOverview />}
        />
        <Route
          path={ROUTES.BATCH_COMMUNICATION_COMBINED_OVERVIEW}
          element={<BatchCommunicationOverview />}
        />
        <Route path={ROUTES.BATCH_COMMUNICATION_DETAIL} element={<BatchDetail />} />
        <Route path={ROUTES.INVOICE_VALIDATE} element={<InvoiceValidate />} />
        <Route path={ROUTES.INVOICE_UPLOAD} element={<InvoiceValidate />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
