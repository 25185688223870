import { QueryClient } from '@tanstack/react-query';

export const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10000,
    },
  },
});

export const queryIds = {
  accountingSystem: {
    GET_JOURNALS_AUTOCOMPLETE: 'getJournalsAutocomplete',
  },
  batchCommunication: {
    GET_BATCH: 'getBatch',
    GET_BATCH_MESSAGE_FILES: 'getBatchMessageFiles',
    GET_BATCH_MESSAGES: 'getBatchMessages',
    GET_BATCHES: 'getBatches',
  },
  blogs: {
    GET_BLOGS: 'getBlogs',
    GET_RECENT_BLOGS: 'getRecentBlogs',
  },
  buildings: {
    GET_BUILDING: 'getBuilding',
    GET_BUILDING_CASE_LINKS: 'getBuildingCaseLinks',
    GET_BUILDING_IMPORTS: 'getBuildingImports',
    GET_BUILDING_PORTALSETTINGS: 'getBuildingPortalSettings',
    GET_BUILDING_RELATION_IMPORTS: 'getBuildingRelationImports',
    GET_BUILDING_SUMMARY: 'getBuildingSummary',
    GET_BUILDING_TAGS: 'getBuildingTags',
    GET_BUILDING_UNITS: 'getBuildingUnits',
    GET_BUILDING_UNITS_BY_HOA_ID: 'getBuildingUnits',
    GET_BUILDINGS: 'getBuildings',
    GET_BUILDINGS_AUTOCOMPLETE: 'getBuildingsAutocomplete',
    GET_BUILDINGS_AUTOCOMPLETE_WITH_CASES: 'getBuildingAutocompleteWithCases',
    GET_BUILDINGS_FOR_HOMEOWNER_ASSOCIATION: 'getBuildingsForHomeownerAssociation',
    GET_COMPANY_WEB: 'getCompanyWeb',
    GET_EXTERNAL_BUILDINGS: 'getExternalBuildings',
    GET_SIMPLE_BUILDING_RELATIONS: 'getSimpleBuildingRelations',
    GET_SIMPLE_BUILDING_RELATIONS_FOR_HOMEOWNER_ASSOCIATION:
      'getSimpleBuildingRelationsForHomeownerAssociation',
  },
  cases: {
    GET_CASE: 'getCase',
    GET_CASE_CLASSIFICATIONS: 'getCaseClassifications',
    GET_CASE_CLASSIFICATIONS_AUTOCOMPLETE: 'getCaseClassificationsAutocomplete',
    GET_CASE_COUNT: 'getCaseCount',
    GET_CASE_ENTITIES: 'getCaseEntities',
    GET_CASE_ENTITY_ACCESS: 'getCaseEntityAccess',
    GET_CASE_FILES: 'getCaseFiles',
    GET_CASE_RELATION: 'getCaseRelation',
    GET_CASE_STATUSES_AUTOCOMPLETE: 'getCaseStatusesAutocomplete',
    GET_CASE_TEMPLATE: 'getCaseTemplate',
    GET_CASE_TEMPLATES: 'getCaseTemplates',
    GET_CASES: 'getCases',
    GET_CASES_AUTOCOMPLETE: 'getCasesAutocomplete',
    GET_CASES_IN_GROUP: 'getCasesInGroup',
    GET_NEXT_CASE: 'getNextCase',
    GET_PREVIOUS_CASE: 'getPreviousCase',
    GET_SELECTOR_CASES: 'getSelectorCases',
    GET_TEAMS_WITH_ACCESS_TO_MY_CASES: 'getTeamsWithAccessToMyCases',
    repair: {
      GET_IMAGES_REQUIRED: 'getImagesRequired',
      GET_REPAIR_MANDATE: 'getRepairMandate',
    },
    SEARCH_CASES: 'searchCases',
  },
  caseStatuses: {
    GET_CASE_STATUSES: 'getCaseStatuses',
  },
  centralCompanies: {
    GET_CENTRAL_COMPANIES_AUTOCOMPLETE: 'getCentralCompaniesAutocomplete',
    GET_CENTRAL_COMPANY: 'getCentralCompanies',
    GET_CENTRAL_COMPANY_DETAIL: 'getCentralCompanyDetail',
    GET_CENTRAL_COMPANY_VAT_AUTOCOMPLETE: 'getCentralCompanyVatAutocomplete',
  },
  companies: {
    GET_COMPANIES: 'getCompanies',
    GET_COMPANIES_AUTOCOMPLETE: 'getCompaniesAutocomplete',
    GET_COMPANY: 'getCompany',
    GET_COMPANY_BUILDING_INFO: 'getCompanyBuildingInfo',
    GET_COMPANY_IMPORTS: 'getCompanyImports',
    GET_COMPANY_TAGS: 'getCompanyTags',
    GET_COMPANY_TEAMS: 'getCompanyTeams',
    GET_COMPANY_WEB: 'getCompanyWeb',
    GET_CONTRACTORS_AUTOCOMPLETE: 'getContractorsAutocomplete',
    GET_CONTRACTORS_AVAILABLE_FOR_REPAIR: 'getContractorsAvailableForRepair',
    GET_EXTERNAL_COMPANIES: 'getExternalCompanies',
    GET_WITHHOLDING_OBLIGATIONS: 'getWithholdingObligations',
  },
  contacts: {
    GET_CONTACT: 'getContact',
    GET_CONTACT_BUILDING_INFO: 'getContactBuildingInfo',
    GET_CONTACT_TAGS: 'getContactTags',
    GET_CONTACTS: 'getContacts',
    GET_CONTACTS_AUTOCOMPLETE: 'getContactsAutocomplete',
    SEARCH_EMAIL_CONTACTS: 'contactsEmailSearch',
  },
  contractors: {
    GET_CONTRACTOR_FEES: 'getContractorFees',
    GET_CONTRACTOR_SKILLS: 'getContractorSkills',
  },
  contractorSkillDefinitions: {
    GET_CONTRACTOR_SKILL_DEFINITIONS: 'getContractorSkillDefinitions',
  },
  contracts: {
    GET_CONTRACT: 'getContract',
    GET_CONTRACTS: 'getContracts',
    GET_CONTRACTS_AUTOCOMPLETE: 'getContractsAutocomplete ',
  },
  crm: {
    GET_OPTIONS: 'getOptions',
  },
  defaultAccounts: {
    GET_DEFAULT_ACCOUNTS: 'getDefaultAccounts',
  },
  documentTemplates: {
    IMPORT_WORD: 'importWord',
    LOAD_WORD: 'loadWord',
  },
  enums: {
    GET_ADDRESS_FUNCTIONS: 'getAddressFunctions',
    GET_BUILDING_RELATION_ROLES: 'getBuildingRelationRoles',
    GET_CASE_CHANNELS: 'getCaseChannels',
    GET_CASE_INVOLVEMENT_TYPES: 'getCaseInvolvementTypes',
    GET_CASE_ROLES: 'getCaseRoles',
    GET_COLUMNS: 'getColumns',
    GET_CONTRACTOR_ABSENCES: 'getContractorAbsences',
    GET_CONTRACTOR_FEE_TYPES: 'getContractorFeeTypes',
    GET_CORPORATE_FORMS: 'getCorporateForms',
    GET_COUNTRIES: 'getCountries',
    GET_CURRENCIES: 'getCurrencies',
    GET_DISCHARGE_COLLECTION_MODES: 'getDischargeCollectionModes',
    GET_EXCESS_TYPES: 'getExcessTypes',
    GET_EXPORT_TYPES: 'getExportTypes',
    GET_FILTERS: 'getFilters',
    GET_GENDERS: 'getGenders',
    GET_LANGUAGES: 'getLanguages',
    GET_MESSAGE_TEMPLATE_CONTEXTS: 'getMessageTemplateContexts',
    GET_MESSAGE_TEMPLATE_TAGS: 'getMessageTemplateTags',
    GET_PAYMENT_FREQUENCIES: 'getPaymentFrequencies',
    GET_PHONE_CALL_OUTCOMES: 'getPhoneCallOutcomes',
    GET_POLICY_STATUS: 'getPolicyStatus',
    GET_PRIORITIES: 'getPriorities',
    GET_PROVINCES: 'getProvinces',
    GET_RECURRENCE_PATTERN_FREQUENCY_CHOICES: 'getRecurrencePatternFrequencyChoices',
    GET_RECURRENCE_PATTERN_MONTHLY_CHOICES: 'getRecurrencePatternMonthlyChoices',
    GET_RECURRENCE_PATTERN_YEARLY_CHOICES: 'getRecurrencePatternYearlyChoices',
    GET_RECURRENCE_RANGE_END_CHOICES: 'getRecurrenceRangeEndChoices',
    GET_RENEWAL_COLLECTION_MODES: 'getRenewalCollectionModes',
    GET_SEGMENT_TYPES: 'getSegmentTypes',
    GET_SEGMENTS: 'getSegments',
    GET_STATUS_AUTOCOMPLETE: 'getStatusAutocomplete',
    GET_UNITIES: 'getUnities',
    GET_VAT_CODES: 'getVATCodes',
    GET_WORKDAYS: 'getWorkdays',
  },
  exports: {
    GET_EXPORT: 'getExport',
    GET_EXPORT_TEMPLATE: 'getExportTemplate',
    GET_EXPORT_TEMPLATES: 'getExportTemplates',
    GET_EXPORTS: 'getExports',
  },
  file: {
    GET_FILE_INFO: 'getFileInfo',
  },
  fileManager: {
    GET_FILE_URI: 'getFileUri',
    READ_ALL_FILES: 'readAllFiles',
  },
  finance: {
    GET_CASE_GROUP_BALANCE: 'getCaseGroupBalance',
    GET_DEBTOR_SETTINGS: 'getDebtorSettings',
    GET_DOCUMENT_LINK: 'getDocumentLink',
    GET_HOMEOWNER_ASSOCIATION_BOOKINGRULES: 'getHomeownerAssociationBookingRules',
    GET_KEYPOINT_INVOICING_ACCOUNTS: 'getKeypointInvoicingAccounts',
    GET_MANUAL_CORRECTIONS: 'getManualCorrections',
    GET_PAYMENT_SETTINGS: 'getPaymentSettings',
    GET_PURCHASE_INVOICE_SETTINGS: 'getPurchaseInvoiceSettings',
    GET_SENDER_RELATIONS: 'getSenderRelations',
    GET_SPREADSHEET: 'getSpreadsheet',
    GET_SPREADSHEET_FILES: 'getSpreadsheetFiles',
    GET_SPREADSHEETS_BY_CASE_GROUP: 'getSpreadsheetsByCaseGroup',
    inbound: {
      hoa: {
        GET_ACCEPTED_INVOICES: 'getAcceptedInvoices',
        GET_INVOICE_DOCUMENT: 'getInvoiceDocument',
        GET_INVOICE_TASKS: 'getInvoiceTasks',
        GET_INVOICE_TO_VALIDATE: 'getInvoiceToValidate',
        GET_INVOICES_TO_VALIDATE: 'getInvoicesToValidate',
        GET_NEXT_SPREADSHEET_TO_VALIDATE: 'getNextSpreadsheetToValidate',
        GET_PREVIOUS_SPREADSHEET_TO_VALIDATE: 'getPreviousSpreadsheetToValidate',
      },
    },
    outbound: {
      repair: {
        GET_OPEN_PAYMENT_REQUESTS: 'getOpenPaymentRequests',
        GET_REPAIR_SALES_INVOICE_FILE: 'getRepairSalesInvoiceFile',
        GET_REPAIR_SALES_INVOICES: 'getRepairSalesInvoices',
      },
    },
  },
  fsma: {
    GET_FSMA_COMPANIES_AUTOCOMPLETE: 'getFSMACompaniesAutocomplete',
    GET_FSMA_NUMBERS: 'getFSMANumbers',
  },
  geocode: {
    GET_POSTAL_CODES: 'getPostalCodes',
    GET_POSTAL_CODES_BY_RADIUS: 'getPostalCodesByRadius',
    GOOGLE: 'googleGeocoding',
  },
  homeownerAssociation: {
    GET_HOMEOWNER_ASSOCIATION: 'getHomeownerAssociation',
    GET_HOMEOWNER_ASSOCIATION_ACCOUNTS: 'getHomeownerAssociationAccounts',
    GET_HOMEOWNER_ASSOCIATION_BOOKYEAR_PERIODS_AUTOCOMPLETE:
      'getHomeownerAssociationBookyearPeriodsAutocomplete',
    GET_HOMEOWNER_ASSOCIATION_BOOKYEARS: 'getHomeownerAssociationBookyears',
    GET_HOMEOWNER_ASSOCIATION_BOOKYEARS_AUTOCOMPLETE:
      'getHomeownerAssociationBookyearsAutocomplete',
    GET_HOMEOWNER_ASSOCIATION_CREATE_PROVISION_REQUESTS_PREVIEW:
      'getHomeownerAssociationCreateProvisionRequestsPreview',
    GET_HOMEOWNER_ASSOCIATION_DIVISION_KEY_IMPORTS: 'getHomeownerAssociationDivisionKeyImports',
    GET_HOMEOWNER_ASSOCIATION_DIVISION_KEYS: 'getHomeownerAssociationDivisionKeys',
    GET_HOMEOWNER_ASSOCIATION_DIVISION_KEYS_AUTOCOMPLETE:
      'getHomeownerAssociationDivisionKeysAutocomplete',
    GET_HOMEOWNER_ASSOCIATION_FINANCIAL_SETTINGS: 'getHomeownerAssociationFinancialSettings',
    GET_HOMEOWNER_ASSOCIATION_GENERAL_LEDGER_ACCOUNT_IMPORTS:
      'getHomeownerAssociationGeneralLedgerAccountImports',
    GET_HOMEOWNER_ASSOCIATION_PROVISION_REQUESTS: 'getHomeownerAssociationProvisionRequests',
    GET_HOMEOWNER_ASSOCIATION_USER_RESPONSIBILITIES: 'getHomeownerAssociationUserResponsibilities',
    GET_HOMEOWNER_ASSOCIATION_USERS_WITH_RESPONSIBILITIES:
      'getHomeownerAssociationUsersWithResponsibilities',
    GET_HOMEOWNER_ASSOCIATION_USERS_WITH_RESPONSIBILITIES_AUTOCOMPLETE:
      'getHomeownerAssociationUsersWithResponsibilitiesAutocomplete',
    GET_HOMEOWNER_ASSOCIATIONS: 'getHomeownerAssociations',
    GET_HOMEOWNER_ASSOCIATIONS_AUTOCOMPLETE: 'getHomeownerAssociationsAutocomplete',
  },
  lucene: {
    GET_POSTALCODE_CITY: 'getPostalCodeCity',
  },
  mailbox: {
    GET_EMAIL_ACCOUNTS: 'getEmailAccounts',
    GET_EMAIL_ACCOUNTS_AUTOCOMPLETE: 'getEmailAccountsAutocomplete',
    GET_EMAIL_ADDRESSES_AUTOCOMPLETE: 'getEmailAddresses',
    GET_MAILBOX_FOLDERS_AUTOCOMPLETE: 'getMailboxFoldersAutocomplete',
    GET_MAILBOX_LABELS_AUTOCOMPLETE: 'getMailboxFoldersAutocomplete',
    LOCAL_EMAIL_BODY: 'localEmailBody',
  },
  meetings: {
    GET_MEETING: 'getMeeting',
    GET_MEETING_INVITATION_PREVIEW: 'getMeetingInvitationPreview',
    GET_MEETING_INVITATIONS: 'getMeetingInvitations',
    GET_MEETING_INVITATIONS_PREVIEW: 'getMeetingInvitationsPreview',
    GET_MEETING_PROCURATION_HOLDERS: 'GetMeetingProcurationHolders',
    GET_MEETING_SEND_REPORTS_PREVIEW: 'getMeetingSendReportsPreview',
    GET_MEETING_TASKS: 'getMeetingTasks',
    GET_MEETING_TYPE: 'getMeetingType',
    GET_MEETING_TYPES: 'getMeetingTypes',
    GET_MEETING_TYPES_AUTOCOMPLETE: 'getMeetingTypesAutocomplete',
    GET_MEETINGS_FOR_HOMEOWNER_ASSOCIATION: 'getMeetingsForHomeownerAssociation',
    GET_POSSIBLE_RELATIONS_MEETING: 'getPossibleRelationsForMeeting',
    settings: {
      GET_MEETING_PERIOD: 'getMeetingPeriod',
      GET_MEETING_TYPE_SETTINGS_FOR_HOMEOWNER_ASSOCIATION:
        'getMeetingTypeSettingsForHomeownerAssociation',
    },
  },
  messageTemplates: {
    GET_AUTOMATIC_TEMPLATE: 'getAutomaticMessageTemplate',
    GET_AUTOMATIC_TEMPLATES: 'getAutomaticMessageTemplates',
    GET_MANUAL_MESSAGE_TEMPLATE: 'getManualMessageTemplate',
    GET_MANUAL_MESSAGE_TEMPLATES: 'getManualMessageTemplates',
    GET_TEMPLATE_IDENTIFIERS: 'getMessageTemplateIdentifiers',
    GET_TEMPLATE_RECEIVERS: 'getMessageTemplateReceivers',
    GET_TEMPLATE_TRIGGERS: 'getMessageTemplateTriggers',
    RESOLVE_TEMPLATE: 'resolveTemplate',
  },
  policies: {
    GET_POLICIES: 'getPolicies',
    GET_POLICIES_AUTOCOMPLETE: 'getPoliciesAutocomplete',
    GET_POLICIES_LINKED_TO_ADDRESS_OR_BUILDING: 'getPoliciesLinkedToAddressOrBuilding',
    GET_POLICY: 'getPolicy',
    GET_POLICY_IMPORTS: 'getPolicyImports',
  },
  provisionSettings: {
    GET_PROVISION_SETTINGS: 'getProvisionSettings',
  },
  requestors: {
    GET_REQUESTORS_AUTOCOMPLETE: 'getRequestorsAutocomplete',
  },
  SEARCH_EMAIL_CONTACTS: 'contactsEmailSearch',
  tasks: {
    GET_CASE_TASKS: 'getCaseTasks',
    GET_TASK: 'getTask',
    GET_TASK_OVERVIEW: 'getTaskOverview',
    GET_TASK_TYPES: 'getTaskTypes',
  },
  team: {
    AUTOMATIC_MESSAGE_TEMPLATES: 'automaticMessageTemplates',
    GET_CURRENT_TEAM: 'getCurrentTeam',
    GET_CURRENT_TEAM_USER: 'getCurrentTeamUser',
    GET_EMAIL_ACCOUNTS: 'emailAccounts',
    GET_LINKED_CALENDARS: 'linkedCalendars',
    GET_QRTOKENS: 'getQRTokens',
    GET_TEAM_USERS: 'getTeamUsers',
    GET_TEAM_USERS_AUTOCOMPLETE: 'getTeamUsersAutocomplete',
    MESSAGE_TEMPLATES: 'messageTemplates',
    USER_TEAM_SETTINGS: 'userTeamSettings',
  },
  user: {
    GET_USER_ROLE: 'getUserRole',
  },
  userResponsibilities: {
    GET_USER_RESPONSIBILITIES: 'getUserResponsibilities',
  },
};

export const localStorageKeys = {
  acceptedInboundHoaInvoices: {
    COLUMNS: 'acceptedInboundHoaInvoices-overview-columns-v2.16.1',
    FILTERS: 'acceptedInboundHoaInvoices-overview-filters-v2.10.0',
    SHOW_FILTERS: 'acceptedInboundHoaInvoices-overview-show-filters',
  },
  acceptedInboundHoaInvoicesPerHoa: {
    COLUMNS: 'acceptedInboundHoaInvoicesPerHoa-overview-columns-v2.20.0',
    FILTERS: 'acceptedInboundHoaInvoicesPerHoa-overview-filters',
    SHOW_FILTERS: 'acceptedInboundHoaInvoicesPerHoa-overview-show-filters',
  },
  accounting: {
    bookings: {
      COLUMNS: 'accounting-bookings-overview-columns-v2.9.0',
      FILTERS: 'accounting-bookings-overview-filters-v2.9.0',
      SHOW_FILTERS: 'accounting-bookings-overview-show-filters-v2.9.0',
    },
    envelopes: {
      COLUMNS: 'accounting-envelopes-overview-columns',
      FILTERS: 'accounting-envelopes-overview-filters',
      SHOW_FILTERS: 'accounting-envelopes-overview-show-filters',
    },
    reconciliations: {
      accountOverview: {
        COLUMNS: 'accounting-reconciliations-account-overview-columns',
        FILTERS: 'accounting-reconciliations-account-overview-filters',
        SHOW_FILTERS: 'accounting-reconciliations-account-overview-show-filters',
      },
      archive: {
        COLUMNS: 'accounting-reconciliations-archive-columns-v2.19.0',
        FILTERS: 'accounting-reconciliations-archive-filters-v2.19.0',
        SHOW_FILTERS: 'accounting-reconciliations-archive-filters-v2.19.0',
      },
      clientOverview: {
        COLUMNS: 'accounting-reconciliations-client-overview-columns',
        FILTERS: 'accounting-reconciliations-client-overview-filters',
        SHOW_FILTERS: 'accounting-reconciliations-client-overview-show-filters',
      },
      supplierOverview: {
        COLUMNS: 'accounting-reconciliations-supplier-overview-columns',
        FILTERS: 'accounting-reconciliations-supplier-overview-filters',
        SHOW_FILTERS: 'accounting-reconciliations-supplier-overview-show-filters',
      },
    },
  },
  agendaPoints: {
    COLUMNS: 'agendaPoints-overview-columns',
    FILTERS: 'agendaPoints-overview-filters',
  },
  assets: {
    COLUMNS: 'building-assets-overview-columns',
  },
  assetTemplates: {
    COLUMNS: 'asset-templates-overview-columns',
  },
  automaticMessageTemplates: {
    COLUMNS: 'automaticMessageTemplates-overview-columns-2.1.0',
    FILTERS: 'automaticMessageTemplates-overview-filters-2.1.0',
  },
  balances: {
    closeBookYear: {
      COLUMNS: 'close-book-year-simulation-balances-columns',
    },
    hoaFinance: {
      COLUMNS: 'hoa-finance-book-year-balances-columns',
      FILTERS: 'hoa-finance-book-year-balances-filters',
      SHOW_FILTERS: 'hoa-finance-book-year-balances-show-filters',
    },
  },
  batch: {
    COLUMNS: 'batchCommunication-detail-columns',
    FILTERS: 'batchCommunication-detail-filters',
    SHOW_FILTERS: 'batchCommunication-detail-show-filters',
  },
  batchCommunicationConfig: {
    COLUMNS: 'batchCommunication-overview-columns-2.18.0',
    COMBINED_COLUMNS: 'combined-batchCommunication-overview-columns-2.18.0',
    COMBINED_FILTERS: 'combined-batchCommunication-overview-filters-2.18.0',
    COMBINED_SHOW_FILTERS: 'combined-batchCommunication-overview-show-filters-2.18.0',
    FILTERS: 'batchCommunication-overview-filters-2.18.0',
    SHOW_FILTERS: 'batchCommunication-overview-show-filters-2.18.0',
  },
  buildings: {
    COLUMNS: 'building-overview-columns-v2.3.0',
    FILTERS: 'building-overview-filters-v2.4.0',
    SHOW_FILTERS: 'building-overview-show-filters-v2.3.0',
  },
  buildingUnits: {
    COLUMNS: 'building-units-overview-columns-v2.1.0',
    FILTERS: 'building-units-filters-v2.4.0',
    SHOW_FILTERS: 'building-units-show-filters',
  },
  caseRelations: {
    COLUMNS: 'case-relations-overview-columns',
    FILTERS: 'case-relations-overview-filters',
    SHOW_FILTERS: 'case-relations-overview-show-filters',
  },
  cases: {
    COLUMNS: 'case-overview-columns-v2.12.0',
    FILTERS: 'case-overview-filters-v2.12.0',
    SHOW_FILTERS: 'case-overview-show-filters',
  },
  caseSettings: {
    classifications: {
      COLUMNS: 'case-classifications-overview-columns-v2.17.1',
    },
    manualTemplates: {
      COLUMNS: 'manual-case-template-overview-columns',
      FILTERS: 'manual-case-template-overview-filters',
      SHOW_FILTERS: 'manual-case-template-overview-show-filters',
    },
    recurringTemplates: {
      COLUMNS: 'recurring-case-template-overview-columns',
      FILTERS: 'recurring-case-template-overview-filters',
      SHOW_FILTERS: 'recurring-case-template-overview-show-filters',
    },
    templateForm: {
      appointments: {
        COLUMNS: 'case-template-appointments-overview-columns',
      },
      notes: {
        COLUMNS: 'case-template-notes-overview-columns',
      },
      tasks: {
        COLUMNS: 'case-template-tasks-overview-columns',
      },
    },
  },
  companies: {
    COLUMNS: 'company-overview-columns',
    FILTERS: 'company-overview-filters',
    SHOW_FILTERS: 'company-overview-show-filters',
  },
  contacts: {
    COLUMNS: 'contact-overview-columns',
    FILTERS: 'contact-overview-filters',
    SHOW_FILTERS: 'contact-overview-show-filters',
  },
  creditNotes: {
    COLUMNS: 'creditNotes-overview-columns',
    FILTERS: 'creditNotes-overview-filters',
    SHOW_FILTERS: 'creditNotes-overview-show-filters',
  },
  defaultAccounts: {
    FILTERS: 'defaultAccounts-overview-filters',
    SHOW_FILTERS: 'defaultAccounts-overview-show-filters',
  },
  divisionKeys: {
    FILTERS: 'division-keys-filters',
  },
  exports: {
    FILTERS: 'task-overview-filters',
  },
  homeownerAssociationAccountingSettings: {
    COLUMNS: 'homeownerAssociationAccountingSettings-overview-columns',
  },
  homeownerAssociationAccounts: {
    COLUMNS: 'homeownerAssociationAccounts-overview-columns',
    SHOW_FILTERS: 'homeownerAssociationAccounts-overview-show-filters',
  },
  homeownerAssociationBoardMembers: {
    COLUMNS: 'board-members-overview-columns',
    SHOW_FILTERS: 'board-members-overview-show-filters',
  },
  homeownerAssociationBookingRules: {
    COLUMNS: 'homeownerAssociationBookingRules-overview-columns',
    FILTERS: 'homeownerAssociationBookingRules-overview-filters',
    SHOW_FILTERS: 'homeownerAssociationBookingRules-overview-show-filters',
  },
  homeownerAssociationContracts: {
    COLUMNS: 'homeownerAssociation-contracts-overview-columns-v1',
  },
  homeownerAssociationUserResponsibilities: {
    COLUMNS: 'homeownerAssociationUserResponsibilities-overview-columns',
    FILTERS: 'homeownerAssociationUserResponsibilities-overview-filters',
  },
  inboundHoaInvoicesDefault: {
    COLUMNS: 'inboundHoaInvoicesDefault-overview-columns',
    FILTERS: 'inboundHoaInvoicesDefault-overview-filters',
    SHOW_FILTERS: 'inboundHoaInvoicesDefault-overview-show-filters',
  },
  inScanInvoicesSettings: {
    COLUMNS: 'inScanInvoices-overview-columns-2.9.0',
    FILTERS: 'inScanInvoices-overview-filters-2.9.0',
    SHOW_FILTERS: 'inScanInvoices-overview-show-filters-2.9.0',
  },
  mailbox: {
    SELECTED_EMAIL_LINK_ID: 'selectedEmailLinkId',
  },
  manualMessageTemplates: {
    COLUMNS: 'manualMessageTemplates-overview-columns-2.1.0',
    FILTERS: 'manualMessageTemplates-overview-filters-2.1.0',
  },
  meetingInvites: {
    COLUMNS: 'meetings-invites-overview-columns',
    SHOW_FILTERS: 'meetings-invites-overview-show-filters',
  },
  meetingPendingProcurations: {
    COLUMNS: 'meetings-pending-procurations-overview-columns',
  },
  meetingPlanning: {
    COLUMNS: 'meetings-planning-overview-columns',
    FILTERS: 'meetings-planning-overview-filters',
    SHOW_FILTERS: 'meetings-planning-overview-show-filters',
  },
  meetingProcurationHolders: {
    COLUMNS: 'meetings-procuration-holders-overview-columns',
  },
  meetingProcurations: {
    COLUMNS: 'meetings-procurations-overview-columns',
  },
  meetings: {
    COLUMNS: 'meetings-overview-columns',
    FILTERS: 'meetings-overview-filters',
    SHOW_FILTERS: 'meetings-overview-show-filters',
  },
  meetingTasks: {
    COLUMNS: 'meeting-tasks-overview-columns',
    FILTERS: 'meeting-tasks-overview-filters',
    SHOW_FILTERS: 'meeting-tasks-overview-show-filters',
  },
  meetingTypes: {
    COLUMNS: 'meetingTypes-overview-columns',
  },
  meterReadings: {
    COLUMNS: 'meterReadings-columns',
  },
  meterReadingSettings: {
    COLUMNS: 'meterReadingSettings-columns',
  },
  openCaseInNewTab: 'openCaseInNewTab',
  payments: {
    COLUMNS: 'payments-overview-columns',
    FILTERS: 'payments-overview-filters',
    SHOW_FILTERS: 'payments-overview-show-filters',
  },
  performances: {
    COLUMNS: 'performances-overview-columns',
    FILTERS: 'performances-overview-filters',
    SHOW_FILTERS: 'performances-overview-show-filters',
  },
  performanceTypes: {
    COLUMNS: 'performanceTypes-overview-columns',
  },
  policies: {
    COLUMNS: 'policy-overview-columns',
    FILTERS: 'policy-overview-filters',
    SHOW_FILTERS: 'policy-overview-show-filters',
  },
  provisions: {
    COLUMNS: 'provisions-overview',
  },
  provisionsForSettleBookYear: {
    COLUMNS: 'provisions-overview-settle-book-year-columns',
  },
  standardAgendaPoints: {
    COLUMNS: 'standardAgendaPoints-overview-columns',
    FILTERS: 'standardAgendaPoints-overview-filters',
  },
  standardTasks: {
    COLUMNS: 'standardTasks-overview-columns',
    FILTERS: 'standardTasks-overview-filters',
  },
  tags: {
    COLUMNS: 'tag-overview-columns',
    FILTERS: 'tag-overview-filters',
    SHOW_FILTERS: 'tag-overview-show-filters',
  },
  tasks: {
    COLUMNS: 'task-overview-columns-2.5.0',
    FILTERS: 'task-overview-filters-v4',
    SHOW_FILTERS: 'task-overview-show-filters',
  },
  teamId: 'teamId',
  unlinkedPayments: {
    COLUMNS: 'unlinkedPayments-overview-columns',
    FILTERS: 'unlinkedPayments-overview-filters',
    SHOW_FILTERS: 'unlinkedPayments-overview-show-filters',
  },
  userResponsibilities: {
    COLUMNS: 'userResponsibilities-overview-columns',
    FILTERS: 'userResponsibilities-overview-filters',
  },
};
export const mutationKeys = {
  COMPANY: 'company',
  COMPANY_ADDRESS: 'companyAddress',
};

export const search = {
  DELAY_TIME: 500,
  START_SEARCH_QUERY_LENGTH: 3,
};

export const snackbar = {
  HIDE_DURATION: 6000,
};

export const sorting = {
  orderBy: {
    CASE_GROUP_REFERENCE: 'CaseGroupReference',
    CREATED: 'Created',
    DESCRIPTION: 'Description',
    INCIDENT_DATE: 'IncidentDate',
    LAST_MODIFIED: 'LastModified',
    REFERENCE: 'Reference',
  },
  orderDirection: {
    ASC: 'Ascending',
    DESC: 'Descending',
  },
};

export const muiStatus = {
  DANGER: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const mailbox = {
  ATTACHMENT_MAX_SIZE: 10000000,
  GMAIL_PROVIDER: 'Google',
};

export const customCellWidth = {
  Auto: 'auto',
  DateTime: {
    M: 180,
  },
  Icon: {
    L: 80,
    M: 60,
    S: 50,
  },
  L: 500,
  M: 300,
  Nil: 1, // Cannot use 0
  S: 100,
  SM: 150,
  SS: 80,
  XS: 50,
};

export const layout = {
  layers: {
    SNACKBAR: 99999999,
    STICKY_ELEMENTS: 100,
    TOOLTIP: 99999999999,
  },
  OFFSET_Y: 120,
};

export const topbar = {
  menu: { ITEMS_TO_DISPLAY: 5 },
};

export const iconButton = {
  badge: {
    INSET: 5,
  },
};

export const grantTypes = {
  AUTHORIZATION_CODE: 'authorization_code',
  CLIENT_CREDENTIALS: 'client_credentials',
};

export const reminders = {
  first: { endpoint: 'first-reminder', key: 'firstReminder', trigger: 'ReminderOne' },
  second: { endpoint: 'second-reminder', key: 'secondReminder', trigger: 'ReminderTwo' },
  third: { endpoint: 'third-reminder', key: 'thirdReminder', trigger: 'ReminderThree' },
};

export const recentCasesCount = 5;

export const excludedPaths = ['meeting-registration', 'meeting-in-progress'];

export const reportType = {
  GENERAL: 'general',
  INDIVIDUAL: 'individual',
};

export const tinyMCEToolbarSections = {
  ALIGN: 'alignleft aligncenter alignright alignjustify',
  BLOCKS: 'blocks',
  CLEAR: 'clear',
  FONT: 'fontfamily fontsize backcolor forecolor',
  LANGUAGE: 'print language spellcheckdialog',
  LINK: 'link image',
  LIST: 'bullist numlist outdent indent',
  STYLES: 'styles',
  TABLE: 'table',
  TABLE_PROPS:
    'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
  TAGS: 'mergetags',
  TEXT_DECORATION: 'bold italic underline',
  UNDO_REDO: 'undo redo',
  UNDO_REDO_CLEAR: 'undo redo clear',
};
