import { Contractor, ContractorFilter, Pin } from "./AddContractorForm";

 const filterContractors = (
  contractors: Contractor[],
  pins: Pin[],
  filterData: ContractorFilter
) => {
  const {
    checkTheLanguageOfTheContractor,
    hideAbsentContractors,
    hideContractorsOverCapacity,
    tagIds,
    skillIds,
    takeContractorRegionsIntoConsideration,
  } = filterData;

  if (!contractors?.length) return { filteredContractors: [], filteredPins: [] };

  const filteredContractors = contractors.filter((contractor) => {
    if (hideAbsentContractors && contractor.isAbsent) return false;
    if (hideContractorsOverCapacity && contractor.isOverCapacity) return false;
    if (checkTheLanguageOfTheContractor && !contractor.hasSameLanguageAsClients) return false;
    if (takeContractorRegionsIntoConsideration && !contractor.coversCasePostalCode) return false;
    if (tagIds.length && !contractor.contractorTags.some((tag) => tagIds.includes(tag.tagId))) return false;
    if (skillIds.length && !contractor.skills.some((skill) => skillIds.includes(skill.skillDefinitionId))) return false;
    return true;
  });

  const filteredPins = pins.filter((pin) =>
    filteredContractors.some((contractor) => contractor.id === pin.id)
  );

  return { filteredContractors, filteredPins };
};

export default filterContractors;