import {
  Alert,
  Autocomplete,
  AutocompleteOption,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  ListItemContent,
  ListItemDecorator,
  Textarea,
} from '@mui/joy';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { GoogleMap, InfoWindowF, MarkerF, useLoadScript } from '@react-google-maps/api';
import { lowerFirst, uniq } from 'lodash';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import bronze from '../../../../shared/img/contractors/bronze.svg';
import bronzeStar from '../../../../shared/img/contractors/bronze_star.svg';
import gold from '../../../../shared/img/contractors/gold.svg';
import goldStar from '../../../../shared/img/contractors/gold_star.svg';
import silver from '../../../../shared/img/contractors/silver.svg';
import silverStar from '../../../../shared/img/contractors/silver_star.svg';
import notApplicable from '../../../../shared/img/contractors/not_applicable.svg';
import notApplicableStar from '../../../../shared/img/contractors/not_applicable_star.svg';

import ROUTES from '@/containers/App/Router/Routes';

import './maps.css';

import { RouterOutputs, trpc } from '@/config/trpc';
import { CaseContext } from '../../CaseContext';
import { CommonProps } from '~/common/types';
import filterContractors from './utils';
import { HomeScoreCategory } from '~/common/enums';
import { replaceUrlParams } from '~/common/utils/utils';
import { useAutocompleteOptions } from '@/shared/hooks/autocomplete';
import { UseToggle } from '@/shared/hooks/UseToggle';

import {
  AssignIcon,
  CancelIcon,
  CompanyIcon,
  HomeScoreCategoryIcon,
  InlineSpinner,
  LinkIcon,
  SaveIcon,
  TeamIcon,
  WarningIcon,
} from '@/shared/icons/Icons';
import ContractorDetails from './components/ContractorDetails';
import HomeReportDetails from './components/HomeReportDetails';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import KpcTooltip from '@/shared/components/Tooltips/KpcTooltip';
import SelectContractorSkillDefinition from '@/shared/components/select/SelectContractorSkillDefinition';

type Props = {
  toggle: UseToggle;
  onSuccess: () => void;
};

const defaultIconMap: Record<HomeScoreCategory, string> = {
  [HomeScoreCategory.NotApplicable]: notApplicable,
  [HomeScoreCategory.Gold]: gold,
  [HomeScoreCategory.Silver]: silver,
  [HomeScoreCategory.Bronze]: bronze,
};

const boostedIconMap: Record<HomeScoreCategory, string> = {
  [HomeScoreCategory.NotApplicable]: notApplicableStar,
  [HomeScoreCategory.Gold]: goldStar,
  [HomeScoreCategory.Silver]: silverStar,
  [HomeScoreCategory.Bronze]: bronzeStar,
};

type DefaultMarkerKey = keyof typeof defaultIconMap;
type BoostedMarkerKey = keyof typeof boostedIconMap;

export type Contractor =
  RouterOutputs['company']['contractor']['getAssignRepairData']['contractors'][0];
export type Pin = RouterOutputs['company']['contractor']['getAssignRepairData']['pins'][0];

const defaultFilterData = {
  checkTheLanguageOfTheContractor: true,
  hideAbsentContractors: true,
  hideContractorsOverCapacity: true,
  skillIds: [],
  tagIds: [],
  takeContractorRegionsIntoConsideration: true,
};

export type ContractorFilter = Omit<typeof defaultFilterData, 'skillIds' | 'tagIds'> & {
  skillIds: string[];
  tagIds: string[];
};

const defaultFormValues = {
  assignmentAdditionalInfo: '',
  assignmentDescription: '',
  executeOnTimeAndExpenseBasis: false,
  teamId: null,
};

type FormValues = Omit<typeof defaultFormValues, 'teamId'> & {
  teamId: string | null;
};

const defaultMapData = {
  center: {
    lat: 50.8551188,
    lng: 4.340398,
  },
  mapContainerStyle: { height: '400px', width: '100%' },
  zoom: 8.5,
};

const checkBoxes = [
  'checkTheLanguageOfTheContractor',
  'hideAbsentContractors',
  'hideContractorsOverCapacity',
  'takeContractorRegionsIntoConsideration',
];

export type ContractorProps = CommonProps<Contractor, Pin>;

const AddContractorForm: FC<Props> = ({ toggle, onSuccess }) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { currentCase } = useContext(CaseContext);
  const [filterData, setFilterData] = useState<ContractorFilter>({
    ...defaultFilterData,
    skillIds: currentCase?.contractorSkillDefinition?.id
      ? [currentCase.contractorSkillDefinition?.id]
      : [],
  });
  const [formValues, setFormValues] = useState<FormValues>(defaultFormValues);
  const [selectedPin, setSelectedPin] = useState<string | null>(null);

  const [selectedContractor, setSelectedContractor] = useState<ContractorProps | null>(null);

  const labelOptionsRes = trpc.company.tags.useQuery();
  const { data } = trpc.company.contractor.getAssignRepairData.useQuery(
    {
      casePostalCode: currentCase?.address?.postalCode,
      clientLanguages: uniq(
        currentCase?.relations?.filter((r) => r.isClient).map((r) => r.language ?? '') ?? [],
      ),
      contractorSkillDefinitionId: currentCase?.contractorSkillDefinition?.id,
      excludedId: currentCase?.contractorId,
      userLanguage: i18n.language,
    },
    {
      enabled: !!currentCase,
    },
  );

  const { contractors, pins } = data || { contractors: [], pins: [] };

  const { mutate: assignContractor, isLoading: isAssigningContractor } =
    trpc.case.repair.contractor.assign.useMutation();

  const { filteredPins, filteredContractors } = useMemo(
    () => filterContractors(contractors, pins, filterData),
    [contractors, filterData, pins],
  );

  const handleFilterChange = useCallback(
    (key: string, value: string | string[] | boolean | undefined) => {
      setFilterData({
        ...filterData,
        [key]: value,
      });
    },
    [filterData],
  );

  const handleFormValuesChange = useCallback(
    (key: string, value: string | boolean | undefined) => {
      setFormValues({
        ...formValues,
        [key]: value,
      });
    },
    [formValues],
  );

  const labelAutoCompleteOptions = useAutocompleteOptions({
    ...labelOptionsRes,
    callback: (option) => handleFilterChange('tagIds', option?.map((h) => h.value) || null),
    disableClearable: true,
    multiple: true,
    selected: filterData.tagIds,
  });

  const { isLoaded: gMapsLoaded, loadError: gMapsLoadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_G_MAPS_API_KEY,
  });

  const submit = () => {
    if (!selectedContractor) return;
    assignContractor(
      {
        caseId: currentCase?.id,
        contractorId: selectedContractor?.id,
        ...formValues,
        teamId: formValues?.teamId ?? selectedContractor.linkedTeams[0]?.value,
      },
      {
        onError: () => {
          enqueueSnackbar(t('errors:oops'), { variant: 'error' });
        },
        onSuccess: () => {
          onSuccess();
          enqueueSnackbar(t('typeSuccessfullyAdded', { type: t('contractor.title') }));
        },
      },
    );
  };

  const [zoomLevel, setZoomLevel] = useState(defaultMapData.zoom);
  const [visiblePins, setVisiblePins] = useState<Pin[]>(filteredPins);
  const [closedWindows, setClosedWindows] = useState<string[]>([]);

  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const onMapLoad = (mapInst: google.maps.Map) => {
    setMapInstance(mapInst);
  };

  const updateVisiblePins = useCallback(() => {
    if (!mapInstance) return;

    const bounds = mapInstance.getBounds();
    const nearbyPins = filteredPins.filter((pin) =>
      bounds?.contains(new google.maps.LatLng(pin.position.lat, pin.position.lng)),
    );
    setVisiblePins(nearbyPins);
  }, [filteredPins, mapInstance]);

  const onZoomChanged = useCallback(() => {
    if (!mapInstance) return;
    const zoom = mapInstance.getZoom();
    setZoomLevel(zoom ?? 8.5);
    if (zoom && zoom < 10) {
      setClosedWindows([]);
    }
    updateVisiblePins();
  }, [mapInstance, updateVisiblePins]);

  useEffect(() => {
    updateVisiblePins();
  }, [filteredPins, updateVisiblePins]);

  return (
    <KpcModal toggle={toggle} title={t('assignContractor')} className="min-h-60 w-5/6">
      <div className="grid grid-cols-2 gap-2">
        <FormControl>
          <FormLabel>{t('skill')}</FormLabel>
          <SelectContractorSkillDefinition
            callback={(v) => handleFilterChange('skillIds', v.map((s) => s.value) ?? null)}
            multiple
            selected={filterData.skillIds}
          />
        </FormControl>

        <FormControl>
          <FormLabel>{t('labels')}</FormLabel>
          <Autocomplete {...labelAutoCompleteOptions} />
        </FormControl>
      </div>
      <div className="my-4 grid grid-cols-4 gap-4">
        {checkBoxes.map((checkBox) => (
          <Checkbox
            key={checkBox}
            label={t(checkBox)}
            checked={filterData[checkBox as keyof typeof filterData] as boolean}
            onChange={(e) => handleFilterChange(checkBox, e.target.checked)}
          />
        ))}
      </div>

      <div className="mb-2 grid grid-cols-3 gap-2">
        {gMapsLoaded && !gMapsLoadError ? (
          <div className="col-span-2 rounded">
            <GoogleMap
              {...defaultMapData}
              onLoad={onMapLoad}
              onDragEnd={updateVisiblePins}
              onZoomChanged={onZoomChanged}
            >
              {visiblePins.map((p) => (
                <MarkerF
                  key={p.key}
                  position={p.position}
                  onClick={() => {
                    setClosedWindows((prev) => prev.filter((pr) => pr !== p.key));
                    setSelectedPin(p.key);
                  }}
                  icon={{
                    scaledSize: new window.google.maps.Size(40, 40),
                    url:
                      (p.isBoosted
                        ? boostedIconMap[p?.homeScoreCategory as BoostedMarkerKey]
                        : defaultIconMap[p?.homeScoreCategory as DefaultMarkerKey]) ??
                      notApplicable,
                  }}
                >
                  {(selectedPin === p.key || selectedContractor?.id === p.id || zoomLevel >= 11) &&
                    !closedWindows.includes(p.key) && (
                      <InfoWindowF
                        key={`info-window-${p.key}`}
                        position={p.position}
                        onCloseClick={() => {
                          setClosedWindows((prev) => [...prev, p.key]);
                          if (p.key === selectedPin) {
                            setSelectedPin(null);
                          }
                        }}
                      >
                        <div className="flex flex-col items-center">
                          <p
                            className={classNames('mb-2', {
                              'text-blue-400': selectedContractor?.id === p.id,
                            })}
                          >{`${p.name} (${p.addressFunction})`}</p>
                          {selectedContractor?.id !== p.id && (
                            <KpcTooltip title={t('assignContractor')} className="absolute top-2">
                              <IconButton onClick={() => setSelectedContractor(p)} className="w-20">
                                <AssignIcon />
                              </IconButton>
                            </KpcTooltip>
                          )}
                        </div>
                      </InfoWindowF>
                    )}
                </MarkerF>
              ))}
              <MarkerF
                key="case-location"
                position={{
                  lat: currentCase?.latitude ?? defaultMapData.center.lat,
                  lng: currentCase?.longitude ?? defaultMapData.center.lng,
                }}
                icon={{
                  // equals is unneeded but leaving it out causes a ts error
                  anchor: { equals: () => false, x: 11.5, y: 21 },
                  fillColor: 'red',
                  fillOpacity: 1.0,
                  path: 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z',
                  scale: 2,
                  strokeWeight: 0,
                }}
              />
            </GoogleMap>
          </div>
        ) : (
          <div className="col-span-2 h-[400px] animate-pulse rounded bg-gray-200" />
        )}
        <div className="flex w-full flex-col space-y-4">
          {selectedContractor && !selectedContractor.hasCaseSkill && (
            <Alert size="sm" startDecorator={<WarningIcon />} color="warning">
              {t('contractor.assign.warning')}
            </Alert>
          )}

          <div className="flex flex-col space-y-4">
            <FormControl required>
              <FormLabel>{t('contractor.title')}</FormLabel>
              <Autocomplete
                options={filteredContractors}
                startDecorator={
                  selectedContractor ? (
                    <HomeScoreCategoryIcon
                      category={selectedContractor.homeScoreCategory}
                      isBoosted={selectedContractor.isBoosted}
                      className="h-7"
                    />
                  ) : (
                    <CompanyIcon />
                  )
                }
                onChange={(_, option) => setSelectedContractor(option)}
                getOptionLabel={(opt) => opt?.name}
                value={selectedContractor}
                renderOption={(props, opt) => (
                  <AutocompleteOption {...props}>
                    <ListItemDecorator>
                      <HomeScoreCategoryIcon
                        category={opt.homeScoreCategory}
                        isBoosted={opt.isBoosted}
                        className="h-7"
                      />
                    </ListItemDecorator>

                    <ListItemContent>{opt.name}</ListItemContent>
                  </AutocompleteOption>
                )}
              />
            </FormControl>

            <div className="flex items-center justify-between">
              <Checkbox
                label={t('onTimeAndExpenseBasis')}
                checked={formValues.executeOnTimeAndExpenseBasis}
                onChange={(e) =>
                  handleFormValuesChange('executeOnTimeAndExpenseBasis', e.target.checked)
                }
              />
              {selectedContractor && (
                <Button
                  startDecorator={<LinkIcon />}
                  variant="plain"
                  onClick={() => {
                    const url = replaceUrlParams(ROUTES.COMPANY_DETAIL, {
                      companyId: selectedContractor.id,
                    });

                    window.open(url, '_blank');
                  }}
                >
                  {t('openTypeInNewTab', { type: lowerFirst(t('contractor.title')) })}
                </Button>
              )}
            </div>

            {selectedContractor && selectedContractor.linkedTeams.length > 1 && (
              <FormControl required>
                <FormLabel>{t('team')}</FormLabel>
                <Autocomplete
                  startDecorator={<TeamIcon />}
                  options={selectedContractor.linkedTeams}
                  onChange={(_, option) => handleFormValuesChange('teamId', option?.value)}
                />
              </FormControl>
            )}
          </div>

          {selectedContractor &&
            (!!formValues.teamId || selectedContractor.linkedTeams.length === 1) && (
              <HomeReportDetails
                contractorTeamId={formValues.teamId ?? selectedContractor.linkedTeams[0]?.value}
              />
            )}
        </div>
      </div>

      <FormControl>
        <FormLabel>{t('description')}</FormLabel>
        <Input
          value={formValues.assignmentDescription}
          onChange={(e) => handleFormValuesChange('assignmentDescription', e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>{t('additionalInformation')}</FormLabel>
        <Textarea
          value={formValues.assignmentAdditionalInfo}
          onChange={(e) => handleFormValuesChange('assignmentAdditionalInfo', e.target.value)}
        />
      </FormControl>

      {selectedContractor && (
        <ContractorDetails contractor={selectedContractor} skillIds={filterData.skillIds} />
      )}

      <div className="my-4 flex w-full justify-end space-x-4">
        <Button
          variant="outlined"
          color="neutral"
          startDecorator={<CancelIcon />}
          onClick={() => {
            setSelectedContractor(null);
            toggle.hide();
          }}
        >
          {t('cancel')}
        </Button>
        <KpcTooltip title={selectedContractor?.hasCaseSkill ? '' : t('contractor.assign.warning')}>
          <Button
            type="submit"
            onClick={submit}
            color={selectedContractor?.hasCaseSkill ? 'primary' : 'warning'}
            startDecorator={isAssigningContractor ? <InlineSpinner /> : <SaveIcon />}
            disabled={
              isAssigningContractor ||
              !selectedContractor ||
              (selectedContractor.linkedTeams.length > 1 && formValues.teamId === null)
            }
          >
            {t('save')}
          </Button>
        </KpcTooltip>
      </div>
    </KpcModal>
  );
};

export default AddContractorForm;
