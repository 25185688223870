import i18next from 'i18next';
import { lowerFirst } from 'lodash';

import { EnumOptions } from './autocomplete.types';

/* eslint-disable import/prefer-default-export */
export const generateOptionsFromEnum = <TValue extends string | number>({
  includeValue,
  srcEnum,
  translationKey,
}: EnumOptions<TValue>) => {
  const { t } = i18next;

  return Object.values(srcEnum)
    .filter((value) => value !== 'Unspecified' && (!includeValue || includeValue(value)))
    .map((value) => {
      const subKey = lowerFirst(value.toString());

      return {
        label: translationKey ? t(`${translationKey}.${subKey}`) : t(subKey),
        value: value as Exclude<TValue, 'Unspecified'>,
      };
    });
};
