import { Autocomplete, AutocompleteOption, ListItemContent, ListItemDecorator } from '@mui/joy';
import classnames from 'classnames';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import {
  AutocompleteOption as AutocompleteOptionType,
  useAutocompleteOptions,
} from '@/shared/hooks/autocomplete';
import { languageMap } from '~/common/constants';
import { trpc } from '@/config/trpc';

type Props = {
  disabled?: boolean;
  selected?: string[] | string | null;
  multiple?: boolean;
  onBlur?: () => void;
  callback: (
    option: AutocompleteOptionType<string, any> | AutocompleteOptionType<string, any>[] | null,
  ) => void;
};

const SelectContractorSkillDefinition: FC<Props> = ({
  disabled = false,
  selected,
  callback,
  multiple = false,
  ...rest
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const languageCode = languageMap[language];

  const optionsRes = trpc.contractorSkill.autocomplete.useQuery({ language: languageCode });

  const autocompleteOptions = useAutocompleteOptions({
    ...optionsRes,
    callback,
    disabled,
    multiple,
    selected,
  });

  return (
    <Autocomplete
      {...autocompleteOptions}
      {...rest}
      loading={optionsRes.isLoading}
      getOptionLabel={(opt) => opt?.combinedLabel ?? opt.label}
      getOptionDisabled={(opt) => !opt.active}
      renderOption={(props, opt) => (
        <AutocompleteOption {...props}>
          {opt.depth > 1 && (
            <ListItemDecorator style={{ marginLeft: `${opt.depth * 5}px` }}>
              <FontAwesomeIcon icon={['far', 'arrow-turn-down-right']} />
            </ListItemDecorator>
          )}
          <ListItemContent className={classnames(opt.depth === 1 && 'font-medium')}>
            {opt.label}
          </ListItemContent>
        </AutocompleteOption>
      )}
    />
  );
};

export default SelectContractorSkillDefinition;
