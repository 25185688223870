import {
  Alert,
  Autocomplete,
  AutocompleteOption,
  Avatar,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from '@mui/joy';
import { useContext, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { FormikValues } from './AddRepairCaseStepper';

import { actions } from '@/reducers/ui';
import { AppContext } from '@/shared/context/context';
import CaseStatus from '@/shared/enums/CaseStatus';
import { getAvatarInitials } from '@/shared/utils/helpers';
import { trpc } from '@/config/trpc';
import useToggle from '@/shared/hooks/UseToggle';

import { BuildingIcon, InfoIcon, InlineSpinner } from '@/shared/icons/Icons';
import AddEditBuildingModal from '@/containers/Buildings/AddEditBuilding/AddEditBuildingModal';
import CaseChipDisplay from '../../CaseChipDisplay';
import HeaderStep from './HeaderStep';
import VirtualListBoxAdapter from '@/shared/components/2.0/adapters/VirtualListBoxAdapter';

const AddressStep = () => {
  const { ui, setUi } = useContext(AppContext);
  const { values, setFieldValue, errors } = useFormikContext<FormikValues>();

  const { t } = useTranslation();

  const addBuildingToggle = useToggle();

  const { data: buildings } = trpc.building.list.useQuery();
  const { data: building } = trpc.building.byId.useQuery(values.buildingId, {
    enabled: !!values.buildingId,
  });
  const { data: buildingCases } = trpc.building.cases.useQuery(values.buildingId, {
    enabled: !!values.buildingId,
  });
  const { data: countries, isLoading: countriesIsLoading } = trpc.building.address.useQuery();

  const { data: postalCodes, isLoading: postalCodesIsLoading } =
    trpc.building.postalCodes.useQuery();

  const [search, setSearch] = useState('');
  const handleSearch = debounce((value: string) => {
    setSearch(value);
  }, 1000);

  const { data: casesByAddress, isFetching: isFetchingCasesByAddress } = trpc.case.list.useQuery(
    {
      params: {
        search,
        statuses: [CaseStatus.Open, CaseStatus.Pending, CaseStatus.Cancelled, CaseStatus.Pending],
      },
    },
    {
      enabled: search.length > 3,
    },
  );

  return (
    <>
      <HeaderStep
        title={t('repairCaseForm.address.title')}
        description={t('repairCaseForm.address.description')}
        tooltipMessage={t('repairCaseForm.address.tooltip')}
      />

      <FormControl className="w-1/3">
        <Checkbox
          checked={ui.case.toggles.showAddress}
          label={t('addAddressWithoutBuilding')}
          onChange={() => {
            setUi({ type: actions.CASE_TOGGLE_SHOW_ADDRESS });
            setFieldValue('buildingId', null);
            setFieldValue('address', {
              addressLine: null,
              city: null,
              country: 'BE',
              postalCode: null,
            });
          }}
        />
      </FormControl>

      {!ui.case.toggles.showAddress && (
        <div>
          <FormControl error={!!errors.address}>
            <FormLabel required>{t('building_other')}</FormLabel>
            <div className="flex space-x-4">
              <Autocomplete
                value={buildings?.find((b) => b.id === values.buildingId) || null}
                className="grow"
                startDecorator={<BuildingIcon />}
                placeholder={t('typeHere')}
                getOptionKey={(opt) => opt.id || uuidv4()}
                getOptionLabel={(opt) => opt.name || '-'}
                options={buildings || []}
                onChange={(_e, buildingOption) => {
                  if (buildingOption) {
                    setFieldValue('buildingId', buildingOption.id);
                    setFieldValue('address', {
                      addressLine: buildingOption.address.addressLine,
                      city: buildingOption.address.city,
                      country: buildingOption.address.country,
                      postalCode: buildingOption.address.postalCode,
                    });
                  }
                }}
              />
              <IconButton onClick={() => addBuildingToggle.show()}>
                <BuildingIcon />
              </IconButton>
            </div>
          </FormControl>
        </div>
      )}

      {!!building && !ui.case.toggles.showAddress && (
        <Card className="flex flex-row items-center">
          <Avatar>{getAvatarInitials(building.name)}</Avatar>
          <div className="flex flex-col">
            <Typography level="title-lg">{t('address')}</Typography>
            <Typography>{building.name || ''} </Typography>
            <Typography>{building.address.addressLine || ''}</Typography>
            <Typography startDecorator={building.address.country || ''}>{`${
              building.address.postalCode || ''
            } ${building.address.city || ''}`}</Typography>
          </div>
        </Card>
      )}

      {!!buildingCases?.length && !ui.case.toggles.showAddress && (
        <>
          <Typography endDecorator={<Chip>{buildingCases.length}</Chip>} level="body-sm">
            {t('buildingCases')}
          </Typography>
          <div className="grid max-h-96 flex-col gap-2 overflow-auto">
            <div
              className={classNames('grid', 'max-h-96', 'flex-col', 'gap-2', 'overflow-auto', {
                'grid-cols-1': buildingCases.length === 1,
                'grid-cols-2': buildingCases.length > 3,
                'grid-cols-3': buildingCases.length > 20,
              })}
            >
              {buildingCases.map((c) => (
                <CaseChipDisplay key={c.caseId} caseEntity={{ ...c, id: c.caseId }} />
              ))}
            </div>
          </div>
        </>
      )}

      {!!building && !buildingCases?.length && (
        <Alert startDecorator={<InfoIcon />}>{t('noCasesLinked')}</Alert>
      )}

      {ui.case.toggles.showAddress && (
        <>
          <div className="flex space-x-4">
            <FormControl className="w-1/2">
              <FormLabel required>{t('addressLine')}</FormLabel>
              <Input
                placeholder={t('typeHere')}
                value={values.address.addressLine}
                onChange={(e) => {
                  setFieldValue('address.addressLine', e.target.value);
                  handleSearch(e.target.value);
                }}
              />
            </FormControl>
            <FormControl className="w-1/2">
              <FormLabel required>
                {t('postalCode')} / {t('city')}
              </FormLabel>

              {values.address.country === 'BE' ? (
                <Autocomplete
                  placeholder={t('typeHere')}
                  disabled={postalCodesIsLoading}
                  startDecorator={postalCodesIsLoading && <InlineSpinner />}
                  value={postalCodes?.find(
                    (p) =>
                      p.postalCode.toString() === values.address.postalCode &&
                      p.location === values.address.city,
                  )}
                  slots={{ listbox: VirtualListBoxAdapter }}
                  options={postalCodes || []}
                  getOptionKey={(opt) => opt.id || uuidv4()}
                  renderOption={(props, option) => [props, option] as React.ReactNode}
                  renderGroup={(params) => params as unknown as React.ReactNode}
                  onChange={(_e, option) => {
                    if (option) {
                      setFieldValue('address.postalCode', option.postalCode.toString());
                      setFieldValue('address.city', option.location);
                    }
                  }}
                />
              ) : (
                <div className="flex space-x-4">
                  <Input
                    type="number"
                    onChange={(e) => setFieldValue('address.postalCode', e.target.value)}
                  />
                  <Input
                    className="grow"
                    onChange={(e) => setFieldValue('address.city', e.target.value)}
                  />
                </div>
              )}
            </FormControl>
          </div>

          <FormControl>
            <FormLabel required>{t('country.label')}</FormLabel>
            <Autocomplete
              placeholder={t('country.placeholder')}
              startDecorator={countriesIsLoading && <InlineSpinner />}
              value={countries?.find((c) => c.code === values.address.country) || null}
              options={countries || []}
              getOptionKey={(opt) => opt.code || uuidv4()}
              getOptionLabel={(opt) => opt.name || '-'}
              onChange={(_e, country) => {
                if (country) {
                  setFieldValue('address.country', country.code);
                  setFieldValue('address.postalCode', '');
                  setFieldValue('address.city', '');
                }
              }}
              renderOption={(props, option) => {
                if (option && option.code && option.name) {
                  return (
                    <AutocompleteOption {...props}>
                      <ListItemDecorator>
                        <img
                          loading="lazy"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt={option.name.toLowerCase()}
                        />
                      </ListItemDecorator>
                      <ListItemContent className="pl-2">{option.name}</ListItemContent>
                    </AutocompleteOption>
                  );
                }
                return <Alert>{t('noCountriesFound')}</Alert>;
              }}
            />
          </FormControl>
        </>
      )}

      {!!casesByAddress?.items?.length && ui.case.toggles.showAddress && (
        <>
          <Typography endDecorator={<Chip>{casesByAddress.items.length}</Chip>} level="body-sm">
            {t('buildingCases')}
          </Typography>
          <div className="grid max-h-96 gap-2 overflow-auto">
            <div
              className={classNames('grid', 'max-h-96', 'gap-2', 'overflow-auto', {
                'grid-cols-1': casesByAddress.items.length === 1,
                'grid-cols-2': casesByAddress.items.length > 3,
                'grid-cols-3': casesByAddress.items.length > 20,
              })}
            >
              {casesByAddress.items.map((caseResult) => (
                <CaseChipDisplay key={caseResult.id} caseEntity={caseResult} />
              ))}
            </div>
          </div>
        </>
      )}

      {isFetchingCasesByAddress && (
        <div>
          <Typography startDecorator={<InlineSpinner />}>{t('isLoading')}</Typography>
        </div>
      )}

      {!isFetchingCasesByAddress && search.length > 3 && !casesByAddress?.items.length && (
        <Alert startDecorator={<InfoIcon />}>{t('noCasesLinked')}</Alert>
      )}

      <AddEditBuildingModal toggle={addBuildingToggle} />
    </>
  );
};

export default AddressStep;
