import {
  ComposeEmailFormikValues,
  FormikEmailAttachment,
  SenderOrReceiver,
} from '@/types/email/EmailFormikValues';
import { EmailAccount, EmailMessage } from '~/common/types/mailbox';
import { CrmOptionType } from '~/common/enums';
import { Nullable } from '~/common/types';

const isReplyPossible = (
  senderAddress: string,
  emailData: Nullable<EmailMessage>,
  grantId?: string,
) => {
  if (!emailData || !senderAddress || !grantId) {
    return false;
  }
  const to = emailData.to ?? [];
  const cc = emailData.cc ?? [];
  const from = emailData.from ?? [];

  return (
    [
      ...to.map((contact: { email: string }) => contact.email),
      ...cc.map((contact: { email: string }) => contact.email),
      ...from.map((contact: { email: string }) => contact.email),
    ].some((emailAddress) => emailAddress === senderAddress) &&
    emailData.grantId !== null &&
    emailData.grantId === grantId
  );
};

const getLinkedContacts = (
  to?: SenderOrReceiver[],
  cc?: SenderOrReceiver[],
  bcc?: SenderOrReceiver[],
) => {
  const selectContactIds = (emailRelations: SenderOrReceiver[] = []) => {
    if (!emailRelations) {
      return [];
    }
    return emailRelations
      .filter((c) => c.contactId != null || (c.id != null && c.type === CrmOptionType.Contact))
      .map((c) => (c.contactId ?? c.id) as string);
  };

  return [...selectContactIds(to), ...selectContactIds(cc), ...selectContactIds(bcc)].filter(
    (contactId, index, self) => index === self.findIndex((contact) => contact === contactId),
  );
};

const getLinkedCompanies = (
  to?: SenderOrReceiver[],
  cc?: SenderOrReceiver[],
  bcc?: SenderOrReceiver[],
) => {
  const selectContactIds = (emailRelations: SenderOrReceiver[] = []) => {
    if (!emailRelations) {
      return [];
    }
    return emailRelations
      .filter((c) => c.companyId != null || (c.id != null && c.type === CrmOptionType.Company))
      .map((c) => (c.companyId ?? c.id) as string);
  };

  return [...selectContactIds(to), ...selectContactIds(cc), ...selectContactIds(bcc)].filter(
    (companyId, index, self) => index === self.findIndex((company) => company === companyId),
  );
};

export const mapRelation = (relation: {
  emailAddresses: string[];
  companyId?: Nullable<string>;
  contactId?: Nullable<string>;
  displayName: string;
}) =>
  relation.emailAddresses.map((email: string) => ({
    companyId: relation.companyId,
    contactId: relation.contactId,
    email,
    name: relation.displayName,
  }));

const getAttachmentFileIds = (attachments: FormikEmailAttachment[]) =>
  attachments
    ?.filter(
      (attachment) =>
        (attachment.isEntityFile || attachment.isForwardAttachment) &&
        (attachment.id || attachment.file.fileId) &&
        !!(attachment.file.url || attachment.file.fileUri || attachment.file.fileSystemEntityId),
    )
    .map((attachment) => attachment.id ?? attachment.file.fileId);

const getNylasAttachments = (attachments: FormikEmailAttachment[]) =>
  attachments
    ?.filter((attachment) => attachment.id && attachment.messageId && !attachment.file.url)
    .map((attachment) => ({
      contentType: attachment.file.contentType as string,
      fileName: attachment.file.fileName as string,
      id: attachment.id as string,
      messageId: attachment.messageId as string,
    }));

const getRegularAttachments = (attachments: FormikEmailAttachment[]) =>
  attachments
    ?.filter((attachment) => !attachment.isForwardAttachment && !attachment.isEntityFile)
    .map((attachment) => attachment.file);

const formatSenderOrReceiver = (senderOrReceiver: SenderOrReceiver) => ({
  email: senderOrReceiver.email,
  name: senderOrReceiver.name,
});

export const formatEmailFormData = (
  formData: ComposeEmailFormikValues,
  emailAccount: EmailAccount,
  replyEmail: Nullable<EmailMessage>,
) => {
  const getEmailSender = () => [
    {
      email: emailAccount?.emailAddress as string,
      name: emailAccount?.emailAddress?.split('@')[0] as string,
    },
  ];

  const { to, cc, bcc } = formData;

  const formattedData = {
    ...formData,
    attachmentFileIds: getAttachmentFileIds(formData.attachments),
    attachments: getRegularAttachments(formData.attachments),
    bcc: bcc?.map(formatSenderOrReceiver),
    body: formData.body ?? '',
    cc: cc?.map(formatSenderOrReceiver),
    emailAccountLinkId: emailAccount?.linkId,
    from: getEmailSender(),
    nylasAttachments: getNylasAttachments(formData.attachments),
    recipientCompanyIds: getLinkedCompanies(formData.to, formData.cc, formData.bcc),
    recipientContactIds: getLinkedContacts(formData.to, formData.cc, formData.bcc),
    replyToMessageId: isReplyPossible(emailAccount.emailAddress, replyEmail, emailAccount.grantId)
      ? replyEmail?.id
      : null,
    subject: formData.subject ?? '',
    to: to?.map(formatSenderOrReceiver),
  };

  return formattedData;
};
