import {
  Autocomplete,
  AutocompleteOption,
  FormControl,
  FormLabel,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from '@mui/joy';
import { FormikProps, getIn } from 'formik';
import { useContext, useMemo, useRef, useState } from 'react';
import { lowerFirst } from 'lodash';
import { useTranslation } from 'react-i18next';

import { BoardMemberRoles, CaseType, EntityType, MessageTemplateContext } from '~/common/enums';
import {
  CaseEmailFormikValues,
  isSenderOrReceiver,
  SenderOrReceiver,
} from '@/types/email/EmailFormikValues';
import { File } from '@/types/documents';
import { MessageTemplate } from '~/common/types/team/messageTemplates';
import { MessageTemplateFilter } from '@/queries/team/useMessageTemplates';
import { Nullable } from '~/common/types';
import { UseToggle } from '@/shared/hooks/UseToggle';

import { formatEmailFormData, mapRelation } from '@/shared/utils/Email/emailHelpers';
import { AppContext } from '@/shared/context/context';
import { CaseContext } from '../CaseContext';
import { trpc } from '@/config/trpc';
import { useGetCaseFiles } from '@/queries';
import { useMailboxContext } from '@/containers/Mailbox/store';
import useSendEmailFromCase from '@/mutations/email/useSendEmailFromCase';

import { BuildingRoleSelectorOption } from '@/shared/enums/BuildingRoleSelectorOption';
import { IndentationArrowIcon } from '@/shared/icons/Icons';
import SendEmailModal from '../../../shared/components/2.0/modals/SendEmailModal';

const syndicRoleOptions = [...Object.values(BuildingRoleSelectorOption)];
const roleOptions = [
  BuildingRoleSelectorOption.AllContactsAndCompanies,
  BuildingRoleSelectorOption.Owner,
  BuildingRoleSelectorOption.Tenant,
  BuildingRoleSelectorOption.MemberOfRME,
  BuildingRoleSelectorOption.Accountant,
];
const rmeMembers = [...Object.values(BoardMemberRoles)];

type Props = {
  buildingId?: string;
  initialFiles?: File[];
  initialSubject: Nullable<string>;
  toggle: UseToggle<unknown>;
};

type SelectorProps = {
  selectedRoles: string[];
  handleChange: (selectedRoles: string[]) => void;
};
const RoleSelector: React.FC<SelectorProps> = ({ selectedRoles, handleChange }) => {
  const { currentTeam } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <FormControl className="w-1/3">
      <FormLabel>{t('buildingContactRoles')}</FormLabel>
      <Autocomplete
        name="buildingRole"
        size="sm"
        onChange={(_, option) => handleChange(option)}
        options={currentTeam?.allowSyndicManagement ? syndicRoleOptions : roleOptions}
        placeholder={t('buildingContactRoles')}
        value={selectedRoles}
        isOptionEqualToValue={(option, value) =>
          option === value ||
          (value === BuildingRoleSelectorOption.BoardOfCoOwnership &&
            rmeMembers.includes(option)) ||
          value === BuildingRoleSelectorOption.AllContactsAndCompanies
        }
        getOptionLabel={(option) =>
          t(`${rmeMembers.includes(option) ? 'boardMemberRole.' : ''}${lowerFirst(option)}`)
        }
        renderOption={(props, option) => (
          <AutocompleteOption key={option} {...props} className="p-2">
            {rmeMembers?.includes(option) && currentTeam?.allowSyndicManagement && (
              <ListItemDecorator>
                <IndentationArrowIcon className="px-4" size="lg" />
              </ListItemDecorator>
            )}
            <ListItemContent>
              <Typography sx={!rmeMembers.includes(option) ? { fontWeight: '500' } : undefined}>
                {t(`${rmeMembers.includes(option) ? 'boardMemberRole.' : ''}${lowerFirst(option)}`)}
              </Typography>
            </ListItemContent>
          </AutocompleteOption>
        )}
        disableCloseOnSelect
        multiple
      />
    </FormControl>
  );
};

const SendCaseEmail: React.FC<Props> = ({
  buildingId,
  initialFiles = [],
  initialSubject,
  toggle,
}) => {
  const formRef = useRef<FormikProps<CaseEmailFormikValues>>(null);
  const {
    clearCompose,
    state: { emailAccount, replyEmail },
  } = useMailboxContext();

  const { currentCase } = useContext(CaseContext);
  const { caseFiles } = useGetCaseFiles(currentCase?.id);
  const { data: building } = trpc.building.byId.useQuery(buildingId as string, {
    enabled: !!buildingId,
  });
  const { data: boardMembers } = trpc.hoa.boardMembers.boardMemberEmailContacts.useQuery(
    building?.homeownerAssociationId as string,
    { enabled: !!building?.homeownerAssociationId },
  );
  const { data: buildingContacts } = trpc.building.emailContacts.useQuery(building?.id as string, {
    enabled: !!building?.id,
  });
  const { sendMessage } = useSendEmailFromCase();

  const [selectedBuildingRoles, setSelectedBuildingRoles] = useState<string[]>([]);

  const caseRelations = useMemo(
    () => currentCase.relations.flatMap(mapRelation),
    [currentCase.relations],
  );

  const { cc, bcc } = useMemo(
    () => ({
      bcc: replyEmail ? [...replyEmail.bcc] : undefined,
      cc: replyEmail ? [...replyEmail.cc] : undefined,
    }),
    [replyEmail],
  );

  const submit = (
    values: CaseEmailFormikValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) => {
    if (!emailAccount) return;

    const request = formatEmailFormData(values, emailAccount, replyEmail);
    sendMessage(
      {
        ...request,
        caseId: currentCase.id,
        emailAccountLinkId: emailAccount.linkId,
      },
      {
        onSettled: () => setSubmitting(false),
        onSuccess: () => {
          clearCompose();
          toggle.hide();
        },
      },
    );
  };

  const deduplicate = (list: SenderOrReceiver[]) =>
    list.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (contact) => contact.contactId === item.contactId && contact.companyId === item.companyId,
        ),
    );

  const removeMatchingRoleAndOtherInput = (
    list: SenderOrReceiver[],
    filteredBuildingContacts: SenderOrReceiver[],
    otherInput: SenderOrReceiver[],
    selectedRoles: string[],
  ) => [
    ...list.filter((member) => !member.roles?.some((role) => selectedRoles.includes(role))),
    ...filteredBuildingContacts.filter(
      (buildingContact) =>
        !otherInput.some(
          (boardMember) =>
            boardMember.companyId === buildingContact.companyId &&
            boardMember.contactId === buildingContact.contactId,
        ),
    ),
  ];

  const clearContactsWithRoles = (list: SenderOrReceiver[], selectedRoles: string[]) =>
    list.filter(
      (member) => !member.roles || member.roles?.some((role) => selectedRoles.includes(role)),
    );

  const filterSelectedContacts = (selectedRoles: string[]) => {
    const allMembers = [...(buildingContacts ?? []), ...(boardMembers ?? [])];

    if (selectedRoles.includes(BuildingRoleSelectorOption.AllContactsAndCompanies)) return [];
    return clearContactsWithRoles(allMembers, selectedRoles);
  };

  const changeSelectedRoles = (selectedRoles: string[]) => {
    let bccList: SenderOrReceiver[] = [];
    let ccList: SenderOrReceiver[] = [];
    let toList: SenderOrReceiver[] = [];

    const bccValues = clearContactsWithRoles(formRef.current?.values.bcc ?? [], selectedRoles);
    const ccValues = clearContactsWithRoles(formRef.current?.values.cc ?? [], selectedRoles);
    const toValues = clearContactsWithRoles(formRef.current?.values.to ?? [], selectedRoles);

    const filteredBuildingContacts = filterSelectedContacts(selectedRoles);

    if (selectedRoles.includes(BuildingRoleSelectorOption.AllContactsAndCompanies)) {
      bccList = [...bccValues, ...(buildingContacts ?? [])];
      setSelectedBuildingRoles([BuildingRoleSelectorOption.AllContactsAndCompanies]);
    } else if (
      selectedRoles.includes(BuildingRoleSelectorOption.BoardOfCoOwnership) &&
      boardMembers?.length
    ) {
      ccList = [...ccValues, ...boardMembers];
      bccList = removeMatchingRoleAndOtherInput(
        bccValues,
        filteredBuildingContacts,
        ccList,
        selectedRoles,
      );

      setSelectedBuildingRoles(selectedRoles.filter((role) => !rmeMembers.includes(role)));
    } else {
      bccList = [...bccValues, ...deduplicate(filteredBuildingContacts)];
      ccList = removeMatchingRoleAndOtherInput(
        ccValues,
        filteredBuildingContacts,
        bccList,
        selectedRoles,
      );
      toList = [...toValues];
      setSelectedBuildingRoles(selectedRoles);
    }

    formRef.current?.setFieldValue('bcc', deduplicate(bccList));
    formRef.current?.setFieldValue('cc', deduplicate(ccList));
    formRef.current?.setFieldValue('to', deduplicate(toList));
  };

  const handleDragAndDrop = (id: string, from: string, to: string) => {
    const sourceList = getIn(formRef.current?.values, from) ?? [];
    const destinationList = getIn(formRef.current?.values, to) ?? [];

    if (
      !Array.isArray(sourceList) ||
      !Array.isArray(destinationList) ||
      !sourceList.every((source) => isSenderOrReceiver(source)) ||
      !destinationList.every((source) => isSenderOrReceiver(source))
    )
      return;

    const matchesId = (item: SenderOrReceiver) =>
      item.contactId === id ||
      item.companyId === id ||
      (!item.companyId && !item.contactId && item.email === id);

    const contact = sourceList.find(matchesId);
    if (!contact) return;

    const updatedSourceList = sourceList.filter((item) => !matchesId(item));

    formRef.current?.setFieldValue(to, [...destinationList, contact]);
    formRef.current?.setFieldValue(from, updatedSourceList);
  };

  const filterRepairCases = (mt: MessageTemplate) => {
    if (mt.predicates && !!mt.predicates.length) {
      const hadContractPredicate = mt.predicates.some(
        (predicate) =>
          predicate.predicateIdentifier === 'Contract' &&
          predicate.predicateValues.includes(currentCase?.contractDescription as string),
      );
      return (
        mt.context === MessageTemplateContext.CaseRepair &&
        (mt.predicates.length === 0 || hadContractPredicate)
      );
    }

    return mt.context === MessageTemplateContext.CaseRepair;
  };

  const templateFilter = (mt: MessageTemplate) => {
    let filter = null;
    switch (currentCase?.caseType) {
      case CaseType.Case:
        filter = mt.context === MessageTemplateContext.Case;
        break;
      case CaseType.Repair:
        filter = filterRepairCases(mt);
        break;
      case CaseType.Complaint:
        filter = mt.context === MessageTemplateContext.CaseComplaint;
        break;
      case CaseType.InsuranceClaim:
        filter = mt.context === MessageTemplateContext.CaseInsurance;
        break;
      default:
        return null;
    }

    return filter;
  };

  const onClose = () => {
    setSelectedBuildingRoles([]);
  };

  return (
    <SendEmailModal
      initialSubject={initialSubject}
      roleSelector={
        buildingId ? (
          <RoleSelector selectedRoles={selectedBuildingRoles} handleChange={changeSelectedRoles} />
        ) : undefined
      }
      entityType={EntityType.Case}
      entityId={currentCase.id}
      messageTemplateFilter={templateFilter as MessageTemplateFilter}
      onSubmit={submit}
      initialFiles={initialFiles}
      suggestions={caseRelations}
      showCaseOptions
      toggle={toggle}
      caseFiles={caseFiles}
      formRef={formRef}
      bcc={bcc}
      cc={cc}
      handleDragAndDrop={handleDragAndDrop}
      onClose={onClose}
    />
  );
};

export default SendCaseEmail;
