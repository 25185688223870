import { FormGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import CaseType from '../../../../../shared/enums/CaseType';
import CaseTypePropTypes from '../../../../../shared/prop-types/CaseTypePropTypes';

import FormikAddressForm from '../../../../../shared/formik/FormikAddressForm';
import FormikBuildingSelector from '../../../../../shared/formik/FormikBuildingSelector';
import FormikTextField from '../../../../../shared/formik/FormikTextField';

const EditCaseBuildingFormBody = ({ buildingId, caseType }) => {
  const { t } = useTranslation(['common', 'errors']);
  const { setFieldValue } = useFormikContext();

  const setAddressFieldVaules = useCallback(
    (address) => {
      if (address == null) {
        setFieldValue('addressLine', '');
        setFieldValue('postalCode', '');
        setFieldValue('city', '');
        setFieldValue('country', null);

        return;
      }

      const { addressLine, postalCode, city, country } = address;
      setFieldValue('addressLine', addressLine ?? '');
      setFieldValue('postalCode', postalCode ?? '');
      setFieldValue('city', city ?? '');
      setFieldValue('country', country ?? null);
    },
    [setFieldValue],
  );

  const handleBuildingChanged = useCallback(
    (building) => {
      if (building === null) {
        setFieldValue('buildingId', null);
        setAddressFieldVaules(null);
        return;
      }

      setFieldValue('buildingId', building.id);
      setAddressFieldVaules(building.address);
    },
    [setAddressFieldVaules, setFieldValue],
  );

  return (
    <>
      <FormGroup className="mb-3">
        <FormikBuildingSelector
          selectSingle
          id="buildingSelector"
          name="buildingSelector"
          label={t('building_one')}
          callback={handleBuildingChanged}
          buildingIdsToSelect={[...(buildingId ? [buildingId] : [])]}
          hideAddAddress
        />
      </FormGroup>

      <FormikAddressForm />

      {caseType === CaseType.Repair && (
        <FormGroup className="mb-3">
          <FormikTextField
            id="worksiteRegistrationNbr"
            name="worksiteRegistrationNbr"
            variant="standard"
            fullWidth
            label={t('worksiteRegistrationNbr')}
            type="text"
          />
        </FormGroup>
      )}
    </>
  );
};

EditCaseBuildingFormBody.propTypes = {
  buildingId: PropTypes.string,
  caseType: CaseTypePropTypes.isRequired,
};

EditCaseBuildingFormBody.defaultProps = {
  buildingId: null,
};

export default EditCaseBuildingFormBody;
