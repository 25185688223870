import { getIn, useFormikContext } from 'formik';

import {
  CommonContactSelectorProps,
  EmailOption,
  HandleDragAndDrop,
} from '@/types/email/EmailMessage';
import { ComposeEmailFormikValues } from '@/types/email/EmailFormikValues';
import SelectEmailContact from '../select/SelectEmailContact';
import SelectEmailContactDraggable from '../select/SelectEmailContactDraggable';

type Props = CommonContactSelectorProps & {
  handleDragAndDrop?: HandleDragAndDrop;
  multiple: true;
};

const FormikEmailContactSelector: React.FC<Props> = ({
  name,
  handleDragAndDrop,
  multiple = true,
  ...rest
}) => {
  const { setFieldValue, handleBlur, errors, touched, values } =
    useFormikContext<ComposeEmailFormikValues>();

  const isTouched = getIn(touched, name);
  const error = isTouched ? getIn(errors, name) : null;
  const value = getIn(values, name);

  const commonProps = {
    error: !!error,
    helperText: error,
    multiple,
    name,
    onBlur: handleBlur,
    ...rest,
    onChange: (val: EmailOption | EmailOption[]) => {
      setFieldValue(name, val);
    },
    value,
  };
  return handleDragAndDrop ? (
    <SelectEmailContactDraggable {...commonProps} handleDragAndDrop={handleDragAndDrop} />
  ) : (
    <SelectEmailContact {...commonProps} />
  );
};

export default FormikEmailContactSelector;
