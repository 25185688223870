import { useTranslation } from 'react-i18next';

import { Building } from '@/types/trpc/building';
import { UseToggle } from '@/shared/hooks/UseToggle';

import useAddEditBuilding from './useAddEditBuilding';

import AddEditBuildingForm from './AddEditBuildingForm';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

type Props<T> = {
  building?: Building;
  toggle: UseToggle<T>;
};

const AddEditBuildingModal = <T,>({ building, toggle }: Props<T>) => {
  const { t } = useTranslation();
  const { handleSubmit, isMutating } = useAddEditBuilding({ toggle });

  return (
    <KpcModal className="w-2/3" title={t('addType', { type: t('building_one') })} toggle={toggle}>
      <AddEditBuildingForm
        initialBuilding={building}
        onSubmit={handleSubmit}
        onCancel={toggle.hide}
        isSubmitting={isMutating}
      />
    </KpcModal>
  );
};

export default AddEditBuildingModal;
