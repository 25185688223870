import { Alert, Autocomplete, FormControl, FormLabel, IconButton, Tooltip } from '@mui/joy';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { CaseType, EntityType } from '~/common/enums';

import { RouterOutputs, trpc } from '@/config/trpc';
import { useCaseFormConfig, valuesToCaseRequest } from './utils';
import useToggle, { UseToggle } from '@/shared/hooks/UseToggle';
import { AppContext } from '@/shared/context/context';
import { BuildingRoleSelectorOption } from '@/shared/enums/BuildingRoleSelectorOption';
import { getOpenCaseInNewTabValue } from '@/shared/utils/helpers';
import { replaceUrlParams } from '~/common/utils/utils';
import ROUTES from '@/containers/App/Router/Routes';
import useCreateCase from '@/mutations/case/useCreateCase';
import useGetCaseTemplate from '@/queries/cases/useGetCaseTemplate';

import { AutocompleteOption, Nullable } from '~/common/types';
import { InfoIcon, InlineSpinner, OpenIcon } from '@/shared/icons/Icons';
import AddCaseActions from './AddCaseActions';
import { CaseClassificationOption } from '@/types/trpc/team';
import CommonFormBody from './CommonFormBody';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';

type CaseTemplateOption = RouterOutputs['team']['case']['template']['autocomplete'][0];

export type CreateCaseValues = {
  administratorOptions: AutocompleteOption[];
  admins: string[];
  clientCompanyIds: string[];
  clientContactIds: string[];
  clients: AutocompleteOption[];
  requestor: AutocompleteOption | null;
  buildingId: string;
  description: string;
  channel?: string;
  priority?: string;
  classification?: Nullable<CaseClassificationOption>;
  address?: {
    addressLine?: string;
    city?: string;
    countryCode?: string;
    postalCode?: string;
  };
  buildingOption?: AutocompleteOption;
};

type Props = {
  toggle: UseToggle<unknown>;
  callback?: (res: AxiosResponse) => void;
  initialData?: CreateCaseValues;
};

const AddCase: React.FC<Props> = ({ callback, toggle, initialData }) => {
  const { currentTeamUser, currentTeam } = useContext(AppContext);

  const { data: settings } =
    trpc.messaging.pushNotificationSettings.getBuildingPortalPushNotificationSettings.useQuery();

  const [selectedTemplate, setSelectedTemplate] = useState<CaseTemplateOption | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toggles = {
    openCaseInNewTab: useToggle(getOpenCaseInNewTabValue()),
    pushNotification: useToggle(settings?.caseAdded),
    redirect: useToggle(!getOpenCaseInNewTabValue()),
    showOnPortal: useToggle(true),
    task: useToggle(),
  };

  const { validationSchema } = useCaseFormConfig();

  const { data: caseTemplates, isLoading: isLoadingCaseTemplates } =
    trpc.team.case.template.autocomplete.useQuery({
      isManual: true,
    });

  const { currentCaseTemplate, currentCaseTemplateIsFetching } = useGetCaseTemplate(
    selectedTemplate?.value,
  );

  const { createCase, isCreatingCase } = useCreateCase({
    callback: (res) => {
      if (callback) {
        callback(res);
      }
      if (toggles.openCaseInNewTab.value) {
        const url = replaceUrlParams(ROUTES.CASE_DETAIL, {
          caseId: res,
        });

        window.open(url, '_blank');
      }
      if (toggles.redirect.value) {
        navigate(`/case/${res}`);
      }

      toggle.hide();
    },
  });

  const handleSubmit = (values: any) => {
    const baseRequest = valuesToCaseRequest(values);
    const createRequest = {
      ...baseRequest,
      caseTemplateId: selectedTemplate?.value,
      rolesWithAccess:
        toggles.showOnPortal.value && currentTeam?.allowSyndicManagement
          ? Object.keys(BuildingRoleSelectorOption).filter(
              (opt) =>
                opt !== BuildingRoleSelectorOption.AllContactsAndCompanies &&
                opt !== BuildingRoleSelectorOption.None,
            )
          : null,
      sendPushNotification: toggles.pushNotification.value,
      showOnBuildingPortal: toggles.showOnPortal.value,
    };
    createCase(createRequest);
  };

  return (
    <KpcModal
      className="max-h-[80vh] w-4/5 overflow-auto 2xl:w-2/3"
      title={t('createCase')}
      toggle={toggle}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          administratorOptions: [
            {
              data: {
                description: `${currentTeamUser?.email} | ${currentTeamUser?.role}`,
              },
              label: currentTeamUser?.name,
              type: EntityType.User,
              value: currentTeamUser?.id,
            },
          ],
          buildingOption: null,
          classification: null,
          clients: [],
          description: null,
          requestor: null,
          ...initialData,
        }}
        validationSchema={validationSchema}
      >
        <Form className="flex flex-col space-y-4">
          <FormControl>
            <FormLabel>{t('masterCaseTemplate')}</FormLabel>
            <Autocomplete
              loading={isLoadingCaseTemplates}
              endDecorator={currentCaseTemplateIsFetching && <InlineSpinner />}
              placeholder={t('search.placeholder')}
              options={caseTemplates ?? []}
              isOptionEqualToValue={(option, selectedOption) =>
                option.value === selectedOption.value
              }
              onChange={(_e, option) => {
                if (option) {
                  setSelectedTemplate(option);
                } else {
                  setSelectedTemplate(null);
                }
              }}
            />
          </FormControl>
          <CommonFormBody
            taskToggle={toggles.task}
            caseType={CaseType.Case}
            currentCaseTemplate={currentCaseTemplate}
          />

          {currentCaseTemplate && (
            <Alert
              size="sm"
              color="primary"
              startDecorator={<InfoIcon />}
              endDecorator={
                <Tooltip title="Navigate to CaseTemplates">
                  <IconButton
                    variant="plain"
                    onClick={() => {
                      navigate('/team/settings/case');
                      toggle.hide();
                    }}
                  >
                    <OpenIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <div>
                <p>
                  {`${t('caseCreationMasterTemplateRemark.description')} ${t(
                    'caseCreationMasterTemplateRemark.appointment',
                    {
                      count: currentCaseTemplate.appointments.length,
                    },
                  )}, ${t('caseCreationMasterTemplateRemark.document', {
                    count: currentCaseTemplate.caseTemplateFiles.length,
                  })}, ${t('caseCreationMasterTemplateRemark.note', {
                    count: currentCaseTemplate.notes.length,
                  })}, ${t('caseCreationMasterTemplateRemark.task', {
                    count: currentCaseTemplate.tasks.length,
                  })}.`.trim()}
                </p>
              </div>
            </Alert>
          )}

          <AddCaseActions
            toggles={{
              modal: toggle,
              ...toggles,
            }}
            isMutating={isCreatingCase}
          />
        </Form>
      </Formik>
    </KpcModal>
  );
};

export default AddCase;
